import { Injectable, Injector } from '@angular/core';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { DialogFormComponent, DialogFormOptions } from '@common/components/dialog-form/dialog-form.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DialogCloseResult, DialogService as KendoDialogService } from '@progress/kendo-angular-dialog';
import { firstValueFrom } from 'rxjs';
import { ConfirmDialogComponent } from '../components/confirm-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DialogService {

    constructor(
        private modalService: NgbModal,
        private kendoDialogService: KendoDialogService,
        private injector: Injector) {
    }

    yesNo(title: string, message: string): Promise<boolean> {
        return this.openConfirmDialog(title, message, false, 'No', 'Yes');
    }

    openConfirmDialog(title: string, message: string, hideCancel?: boolean, cancelText?: string, confirmText?: string): Promise<boolean> {
        const dialogRef = this.kendoDialogService.open({
            content: ConfirmDialogComponent,
            minWidth: 420
        });

        const confirmDialog = dialogRef.content.instance;
        confirmDialog.title = title;
        confirmDialog.message = message;
        confirmDialog.hideCancel = hideCancel || confirmDialog.hideCancel;
        confirmDialog.cancelText = cancelText || confirmDialog.cancelText;
        confirmDialog.confirmText = confirmText || confirmDialog.confirmText;

        return firstValueFrom(dialogRef.result).then((result: any) => result instanceof DialogCloseResult ? false : result as boolean);
    }

    open(content: any, options: NgbModalOptions, createdCallback?: (NgbModalRef) => void) {
        const modal = this.modalService.open(content, options);
        if (createdCallback) createdCallback(modal);

        return modal.result
            .catch(reason => {
                if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) return;

                throw reason;
            });
    }

    public changes(title: string, message: string): Promise<any> {
        const dialogRef = this.modalService.open(ChangesDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result
            .catch(reason => {
                if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) return;

                throw reason;
            });
    }

    public warning(title: string, message: string): Promise<any> {
        const dialogRef = this.modalService.open(WarningDialogComponent);
        dialogRef.componentInstance.title = title;
        dialogRef.componentInstance.message = message;

        return dialogRef.result
            .catch(reason => {
                if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) return;

                throw reason;
            });
    }

    public form(options: DialogFormOptions, modalOptions?: NgbModalOptions) {
        const dialogRef = this.modalService.open(DialogFormComponent, modalOptions);
        dialogRef.componentInstance.initialize(options, this.injector);

        return dialogRef.result
            .catch(reason => {
                if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) return;

                throw reason;
            });
    }
}
