import { Component, EventEmitter, Output } from '@angular/core';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import _ from 'lodash';

@Component({
    selector: 'app-form',
    template: '<ng-content></ng-content>'
})
export class AppFormComponent {
    @Output() submit = new EventEmitter();
    @Output() change = new EventEmitter();

    private appControls: AppControlComponent[] = [];

    registerAppControl(appControl: AppControlComponent) {
        this.appControls.push(appControl);
    }

    unregisterAppControl(appControl: AppControlComponent) {
        _.remove(this.appControls, appControl);
    }

    onSubmit(appControl: AppControlComponent, event: KeyboardEvent) {
        this.submit.emit({ form: this, appControl, event });
    }

    onChange(appControl: AppControlComponent, value: any) {
        this.change.emit({ form: this, appControl, value });
    }
}
