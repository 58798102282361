<ngb-accordion activeIds="worker-info">
    <ngb-panel id="worker-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Crew & Crew's Effect Information</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-3" *ngIf="model.notificationTypeId === 'D' && !shortNotification">
                    <app-control label='Did the ship effect any crew change?' type="yesno"
                        [isDisabled]="!canEdit || !editMode" [(ngModel)]="model.crewChange" [entity]="model" property="crewChange"></app-control>
                </div>
            </div>
            <ngb-accordion activeIds="worker-list">
                <ngb-panel id="worker-list">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>IMO FAL 5 - Crew List</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="formOptions" [grid]="workersGrid"></app-entity-form>
                        <div class="row" *ngIf="canSeeFilterAndExport">
                            <div class="col-3">
                                <app-control label='ID/Passport Number' [maxlength]="20"
                                    [isDisabled]="false" [(ngModel)]="filter.documentNumber" [entity]="filter" property="documentNumber"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Given Name' [maxlength]="100"
                                    [isDisabled]="false" [(ngModel)]="filter.givenName" [entity]="filter" property="givenName"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Family Name' [maxlength]="100"
                                    [isDisabled]="false" [(ngModel)]="filter.familyName" [entity]="filter" property="familyName"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Nationality' type="codelist" codelist='CountryNationality'
                                    [isDisabled]="false" [(ngModel)]="filter.nationalityId" [entity]="filter" property="nationalityId"></app-control>
                            </div>
                        </div>
                        <div class="row mt-3 mb-3" *ngIf="canSeeFilterAndExport">
                            <div class="col">
                                <div class="float-left ml-1" *ngIf="canExport()">
                                    <button class="btn btn-sm btn-success" (click)="export()">
                                <fa-icon class="ml-1" icon="file-excel"></fa-icon>&nbsp;<span class="btn-text" translate>Export</span>
                                    </button>
                                </div>
                                <div class="float-right">
                                    <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                                        <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text" translate>Clear</span>
                                    </button>
                                    <button class="btn btn-sm btn-primary" (click)="search()">
                                        <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text" translate>Search</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <app-grid #workersGrid [data]="editMode ? model.workers : query"
                                    [selectable]="{enabled: true, checkboxOnly: true }" [selection]="selection">
                                    <kendo-grid-checkbox-column *ngIf="!editMode" [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
                                    <kendo-grid-column title="{{'Sq.' | translate}}" field="sequenceNumber" [width]="50"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Given Name' | translate}}" field="givenName" [width]="100"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Family Name' | translate}}" field="familyName" [width]="100"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Rank or Rating' | translate}}" field="rank" [width]="100"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Nationality' | translate}}" field="nationality.name" [width]="100">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{row.nationalityId | codelist:'CountryNationality' | async}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Date of Birth' | translate}}" field="birthDate" format="d" [width]="90"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Birth Place' | translate}}" field="birthPlace" [width]="100"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Gender' | translate}}" field="gender.name" [width]="55">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{row.genderId | codelist:'Gender' | async}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Nature of ID' | translate}}" field="documentType.name" [width]="100">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{row.documentTypeId | codelist:'DocumentType' | async}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Number of ID' | translate}}" field="documentNumber" [width]="100"></kendo-grid-column>
                                        <kendo-grid-column title="{{'Issuing By' | translate}}" field="documentIssuingCountry.name" [width]="100">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{row.documentIssuingCountryId | codelist:'Country' | async}}
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'ID Expiry Date' | translate}}" field="documentExpirationDate" format="d" [width]="90"></kendo-grid-column>
                                </app-grid>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <hr class="my-3"/>
            <div class="mb-3"></div>
            <ngb-accordion *ngIf="!shortNotification" activeIds="effect-list">
                <ngb-panel id="effect-list">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>IMO FAL 4 - Crew's Effect List</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="workerEffectOptions" [grid]="effectsGrid"></app-entity-form>
                        <hr class="my-3"/>
                        <app-grid #effectsGrid [data]="model.workerEffects">
                            <kendo-grid-column title="{{'Crew Member' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.vesselNotificationWorker.givenName}} {{row.vesselNotificationWorker.familyName}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Effect' | translate}}" field="effect"></kendo-grid-column>
                            <kendo-grid-column title="{{'Description' | translate}}" field="description"></kendo-grid-column>
                            <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity" format="n2"></kendo-grid-column>
                            <kendo-grid-column title="{{'UoM' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.unitOfMeasureId | codelist:'UnitOfMeasure' | async}}
                                </ng-template>
                            </kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
