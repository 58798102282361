<div class="fixed-background" [ngStyle]="{'background-image': (chosenImage) ? 'url(' + chosenImage + ')' : '' }">
    <div class="login-wrap">
        <div class="login-html">
            <p style="text-align: center;"> <img src="/assets/img/logo_big.png"></p>
            <input id="tab-1" type="radio" name="tab" class="sign-in" checked><label for="tab-1" class="tab">Sign In</label>
            <div class="login-form">
                <div class="sign-in-htm">
                    <form *ngIf="!(showChangePassword || token)">
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage"></div>
                        <div class="group">
                            <label for="user" class="label">Username</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="user"></fa-icon>
                                </span>
                                <input type="text" id="username" name="username"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="username" class="input"
                                    placeholder="Username" required autofocus />
                            </div>
                        </div>
                        <div class="group">
                            <label for="pass" class="label">Password</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" id="password" name="password"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="password" class="input"
                                    placeholder="Password" required />
                            </div>
                        </div>
                        <div class="group">
                            <input id="check" type="checkbox" class="check" checked>
                            <label for="check"><span class="icon"></span> Keep me Signed in</label>
                        </div>
                        <div class="group">
                            <input type="submit" class="button" value="Sign In" (click)="signIn()"
                                [disabled]="isBusy || !username || !password" translate>
                        </div>
                        <div class="hr"></div>
                        <div class="foot-lnk">
                            <a [ngClass]="{ 'forgotDisabled': !username }" (click)="forgotPassword()">Forgot Password?</a>
                        </div>
                    </form>
                    <form *ngIf="showChangePassword || token">
                        <div class="alert alert-danger" role="alert" *ngIf="errorMessage" [textContent]="errorMessage">
                        </div>
                        <div class="group">
                            <label for="user" class="label" translate>New password</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" name="newPassword" [ngClass]="{'isInvalid': !!errorMessage}"
                                    [(ngModel)]="newPassword" class="input" [placeholder]="'New password' | translate"
                                    required autofocus>
                            </div>
                        </div>
                        <div class="group">
                            <label for="pass" class="label" translate>Repeat new password</label>
                            <div class="input-group-prepend">
                                <span class="input-group-text">
                                    <fa-icon icon="key"></fa-icon>
                                </span>
                                <input type="password" name="repeatNewPassword"
                                    [ngClass]="{'isInvalid': !!errorMessage}" [(ngModel)]="repeatNewPassword"
                                    class="input" placeholder="Repeat new password" required>
                            </div>
                        </div>
                        <div class="group" *ngIf="showChangePassword">
                            <input type="submit" class="button" value="Change password" (click)="changePassword()"
                                [disabled]="isBusy || !newPassword || !repeatNewPassword" translate>
                        </div>
                        <div class="group" *ngIf="token">
                            <input type="submit" class="button" value="Save new password" (click)="resetPassword()"
                                [disabled]="isBusy || !newPassword || !repeatNewPassword" translate>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
