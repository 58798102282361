<app-loader [isBusy]="!model || isBusy">
    <div class="row separator"></div>
    <ng-template>
        <app-action-bar [items]="actionBar"></app-action-bar>
        <app-content-header [title]="title" [breadcrumb]="breadcrumb"></app-content-header>
        <div class="row mr-1" *ngIf="mode !== 'create'">
            <div class="col ml-2 simple-container box-shadow">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto"> Conveyance </strong>
                    <div class="col-md-auto" [appPill]="{ status: model.statusId, module: 'vesselConveyance', muted: true }">
                        {{model.statusId | codelist:'ConveyanceStatus' | async}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong> {{getIdentifier()}}</strong>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">Agent: </strong>
                        {{model.organizationId | codelist:'Organization' | async}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col">
                        <strong>TM</strong>
                        <app-rej-icon [item]="model" organizationName="transportMalta" moduleType="Conveyance"></app-rej-icon>
                    </div>
                    <div class="col">
                        <strong>IM</strong>
                        <app-rej-icon [item]="model" organizationName="immigration" moduleType="Conveyance"></app-rej-icon>
                    </div>
                    <div class="col">
                        <strong>CU</strong>
                        <app-rej-icon [item]="model" organizationName="customs" moduleType="Conveyance"></app-rej-icon>
                    </div>
                    <div class="col">
                        <strong>HA</strong>
                        <app-rej-icon [item]="model" organizationName="health" moduleType="Conveyance"></app-rej-icon>
                    </div>
                </div>
            </div>
            <div class="col ml-2 simple-container box-shadow">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto">Conveyance details</strong>
                </div>
                <span>
                    <div class="row mt-2">
                        <div class="col-md-auto">
                            <strong class="bluify">Conveyance type: </strong>
                            {{model.typeId | codelist:'ConveyanceType' | async}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-auto">
                            <strong class="bluify">Location: </strong>{{model.locationId}}
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-auto">
                            <strong class="bluify">Date and time of service: </strong>
                            {{model.edtOfService | date:'short'}}
                        </div>
                    </div>
                </span>
            </div>
            <div class="col ml-2 simple-container box-shadow" *ngIf="model.vesselNotification.vesselVisitId">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto"> Vessel visit </strong>
                    <div class="col-md-auto" [appPill]="{ status: model.vesselNotification?.vesselVisit.statusId, module: 'vesselVisit', muted: true }">
                        {{model.vesselNotification?.vesselVisit.statusId | codelist:'VesselVisitStatus' | async}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong class="bluify"> Vessel Visit: </strong>
                        <strong>
                            <a [routerLink]="['/vessel-visit/view', model.vesselNotification.vesselVisitId]" target="_blank">
                                {{model.vesselNotification?.vesselVisit.yearNumber}}
                            </a>
                        </strong>
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col">
                        {{model.vesselNotification.vesselVisit.portId | codelist:'Location' | async}} / {{model.vesselNotification.vesselVisit.berthId | codelist:'Berth' | async}}
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong class="bluify">Agent: </strong>
                        {{model.vesselNotification.vesselVisit.agentId | codelist:'Organization' | async}}
                    </div>
                </div>
            </div>
            <div class="col ml-2 simple-container box-shadow inactive" *ngIf="!model.vesselNotification?.vesselVisitId">
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto">Vessel visit</strong>
                </div>
            </div>
            <div class="col ml-2 simple-container box-shadow">
                <div class="badge box-shadow tight" ngbTooltip="{{model.vessel.countryId | codelist:'Country' | async}}">
                    <span>{{model.vessel.countryId}}</span>
                    <app-flag-icon [code]="model.vessel.countryId"></app-flag-icon>
                </div>
                <div class="row mt-1">
                    <strong class="orangify capitalize col-md-auto">Vessel information</strong>
                    <strong class="col-md-auto">
                        <a [href]="['https://www.marinetraffic.com/en/ais/details/ships/imo:' + model.vessel.iMONumber]" target="_blank">
                            ({{model.vessel.iMONumber}})
                        </a>
                    </strong>
                </div>
                <div class="row mt-2">
                    <div class="col-md-auto">
                        <strong>
                            <a [routerLink]="['/vessels/view', model.vesselId]" target="_blank">
                                {{model.vesselId | codelist:'Vessel' | async}}
                            </a>
                        </strong>
                        <fa-icon *ngIf="model.vessel?.isBlacklisted" [icon]="['fas', 'flag']" class="blackIcon"></fa-icon>
                    </div>
                    <div *ngIf="isDetained() || isAlerted()" ngbTooltip="{{isDetained() ? 'Detained Vessel' : 'Alert Vessel'}}"
                        placement="right" style="font-size: 16px;">
                        <fa-icon icon="exclamation" class="redIcon"></fa-icon>
                    </div>
                    <div class="col-md-auto">
                        ({{model.vessel.typeId | codelist:'VesselType' | async}})
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">MMSI: </strong>{{model.vessel.mmsi}}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">LOA: </strong>
                        {{!!model.vessel.length ? model.vessel.length + ' m' : '/'}}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">Gross Tonnage: </strong>{{model.vessel.grossTonnage}}
                    </div>
                </div>
                <div class="row mt-1">
                    <div class="col-md-auto">
                        <strong class="bluify">Net Tonnage: </strong>{{!!model.vessel.netTonnage ? model.vessel.netTonnage : '/'}}
                    </div>
                </div>
            </div>
        </div>
        <div class="card mt-3">
            <div class="card-body">
                <ngb-accordion activeIds="visit-detail">
                    <ngb-panel id="visit-detail">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Vessel Notification details</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col-6">
                                    <app-control label='Vessel Notification' type="codelist" codelist="DraftVesselNotification"
                                        [fetchOnOpen]="true" [fetch]="fetchNotifications" [(ngModel)]="model.vesselNotificationId" [entity]="model" property="vesselNotificationId"
                                        (ngModelChange)="onVesselNotificationChange($event)"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vessel">
                                    <app-control label='Vessel' [isDisabled]="true" [(ngModel)]="model.vessel.name" [entity]="model.vessel" property="name"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vessel">
                                    <app-control label='IMO Number' [isDisabled]="true" [(ngModel)]="model.vessel.iMONumber" [entity]="model.vessel" property="iMONumber"></app-control>
                                </div>
                                <div class="col-6" *ngIf="!!model.vessel">
                                    <app-control label='Flag' type="codelist" codelist="Country"
                                        [isDisabled]="true" [(ngModel)]="model.vessel.countryId" [entity]="model.vessel" property="countryId"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mt-4"></div>
                <ngb-accordion activeIds="conveyance-detail">
                    <ngb-panel id="conveyance-detail">
                        <ng-template ngbPanelHeader let-panel>
                            <h5 translate>Conveyance information</h5>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Conveyance Type' type="codelist" codelist="ConveyanceType"
                                        [(ngModel)]="model.typeId" [entity]="model" property="typeId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Date and Time of Service' type="datetime" [time]="true"
                                        [(ngModel)]="model.edtOfService" [entity]="model" property="edtOfService"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Service Launch Company' type="codelist" codelist="LaunchOperator"
                                        [(ngModel)]="model.launchOperatorId" [entity]="model" property="launchOperatorId" (ngModelChange)="onLaunchOperatorChange($event)"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Additional Services' type="codelist" codelist="ConveyanceType" [multi]="true"
                                        (ngModelChange)="onAdditionalTypesChange($event)" [(ngModel)]="additionalTypes" [entity]="" property="additionalTypes"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control #serviceBoatsControl label='Service Launch/Vessel' type="codelist" codelist="ServiceBoat"
                                        [(ngModel)]="model.serviceBoatId" [entity]="model" property="serviceBoatId" [filter]="serviceBoatsFilter.bind(this)"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Agent' type="codelist" codelist="Organization" [initialValue]="user.organizationId"
                                        [isDisabled]="!user.isSystemUser || viewMode" [(ngModel)]="model.organizationId" [entity]="model" property="organizationId"></app-control>
                                </div>
                                <div class="col">
                                    <app-control label='Berth' type="codelist" codelist="Berth"
                                        [fetchOnOpen]="true" [fetch]="fetchBerths" [(ngModel)]="model.locationId" [entity]="model" property="locationId"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-control label='Additional Remarks' type="textarea" [(ngModel)]="model.additionalRemarks" [entity]="model" property="additionalRemarks"></app-control>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col" *ngIf="model.transportMaltaRemarks">
                                    <app-control label='Transport Malta Remarks' type="textarea"
                                        [isDisabled]="true" [(ngModel)]="model.transportMaltaRemarks" [entity]="model" property="transportMaltaRemarks"></app-control>
                                </div>
                                <div class="col" *ngIf="model.unconfirmRemarks">
                                    <app-control label='Transport Malta Unconfirm Remarks' type="textarea"
                                        [isDisabled]="true" [(ngModel)]="model.unconfirmRemarks" [entity]="model" property="unconfirmRemarks"></app-control>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
                <div class="mb-3"></div>
                <ngb-accordion activeIds="attachment-info">
                    <ngb-panel id="attachment-info">
                        <ng-template ngbPanelHeader let-panel>
                            <app-accordion-header [panel]="panel">
                                <h5 translate>Attachments</h5>
                            </app-accordion-header>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-entity-form [options]="formOptions" [grid]="attachmentGrid"></app-entity-form>
                            <hr class="my-3" />
                            <div class="mb-3">
                                <h3 translate>Attachments</h3>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <app-grid #attachmentGrid [data]="model.attachments">
                                        <kendo-grid-column title="{{'Name' | translate}}">
                                            <ng-template let-row kendoGridCellTemplate>
                                                <span>{{ row.attachment?.name || row.name }}</span>
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Created Date' | translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{row.createdDate | date:'short'}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Created By' | translate}}">
                                            <ng-template kendoGridCellTemplate let-row>
                                                {{row.createdById | codelist:'User' | async}}
                                            </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column title="{{'Remarks' | translate}}" field="remarks"></kendo-grid-column>
                                        <kendo-grid-column class="overflow-unset" [hidden]="editMode" [width]="100">
                                            <ng-template kendoGridCellTemplate let-row>
                                                <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
                                            </ng-template>
                                        </kendo-grid-column>
                                    </app-grid>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </ng-template>
</app-loader>