<ngb-accordion activeIds="status-info">
    <ngb-panel id="status-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Status changes</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <hr class="my-3"/>
            <h3 translate>Status changes</h3>
            <app-grid [data]="model.statusChanges">
                <kendo-grid-column title="{{'Status' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.statusId | codelist:'VesselNotificationStatus' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Organization' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.organizationId | codelist:'Organization' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="remarks" title="{{'Remarks' | translate}}"></kendo-grid-column>
                <kendo-grid-column field="createdDate" title="{{'Date' | translate}}" format="g"></kendo-grid-column>
                <kendo-grid-column title="{{'Created by' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.createdById | codelist:'User' | async}}
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="ssn-message">
    <ngb-panel id="ssn-message">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>SSN messages</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <hr class="my-3"/>
            <h3 translate>SSN messages</h3>
            <app-grid [data]="model.ssnMessages">
                <kendo-grid-column title="{{'SSN RefId' | translate}}" field="ssnRefId"></kendo-grid-column>
                <kendo-grid-column title="{{'SSN Status' | translate}}" field="ssnStatusCode"></kendo-grid-column>
                <kendo-grid-column title="{{'SSN Status Message' | translate}}" field="ssnStatusMessage"></kendo-grid-column>
                <kendo-grid-column title="{{'Date' | translate}}" field="createdDate" format="g"></kendo-grid-column>
                <kendo-grid-column field="createdById" title="{{'Created by' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.createdById | codelist:'User' | async}}
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>