import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { BaseVesselNotificationViewComponent } from '@common/classes/base-vessel-notification-view';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { ShippingManifestPermissions, VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { ChatNotesListComponent } from 'app/chat-notes/chat-notes-list/chat-notes-list.component';
import { DetainedVesselAttachmentListModalComponent } from 'app/vessel/detained-vessel/detained-vessel-modal/detained-vessel-modal.component';
import _ from 'lodash';
import { ChangeAgencyModalComponent } from '../change-agency-modal/change-agency-modal.component';
import { CreateDepartureModalComponent } from '../create-departure-modal/create-departure-modal.component';
import { EntityState } from '@cime/breeze-client';

@Component({
    selector: 'app-vessel-notification-view',
    templateUrl: './vessel-notification-view.component.html',
    styleUrls: ['./vessel-notification-view.component.scss'],
    providers: [BreezeViewService]
})
export class VesselNotificationViewComponent extends BaseVesselNotificationViewComponent {
    entityName = 'VesselNotification';
    editPermission = VesselNotificationPermissions.Action.edit;
    createPermission = VesselNotificationPermissions.Action.create;

    breadcrumb = [
        {
            icon: 'file-alt',
            title: 'Vessel Notifications',
            route: `${this.parentRoute}/list`
        },
        {
            icon: 'file-alt',
            title: 'Vessel Notification',
        }
    ];
    override isShort = false;

    constructor(breezeViewService: BreezeViewService,
        dialogService: DialogService,
        location: Location) {
        super(breezeViewService, dialogService, location);

        this.editActionBarGroup.items.forEach(item => {
            const isDisabled = _.isFunction(item.isDisabled) ? item.isDisabled : () => false;
            item.isDisabled = () => isDisabled() || this.disableDetained();
        });

        this.actionBar.push({
            label: 'Create',
            items: [
                {
                    label: 'Create Departure',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canCreateDeparture(),
                    isDisabled: () => this.disableDetained(true),
                    onClick: () => this.createDeparture()
                },
                {
                    label: 'Create Vessel Permit',
                    icon: 'file-alt',
                    isVisible: () => this.canCreateVesselPermit(),
                    isDisabled: () => (this.model.vesselVisit ? ['CA', 'DE', 'ZX'].includes(this.model.vesselVisit.statusId) : ['X', 'Z'].includes(this.model.statusId)),
                    onClick: () => this.createVesselPermit()
                },
                {
                    label: 'Create Conveyance',
                    icon: 'file-alt',
                    isVisible: () => this.canCreateConveyance(),
                    onClick: () => this.createConveyance()
                },
                {
                    label: 'Create Shifting',
                    icon: 'play',
                    isVisible: () => this.canCreateVesselShift(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.createVesselShift()
                },
                {
                    label: 'Add. Agent Rights',
                    icon: 'exchange-alt',
                    isVisible: () => this.canChangeAgency(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.changeAgency()
                },
            ]
        },
        {
            label: 'Upload',
            items: [
                {
                    label: 'Excel Update',
                    icon: 'book',
                    isVisible: () => this.canExcelUpdate(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.excelUpdate()
                },
                {
                    label: 'Manifest Upload',
                    icon: 'book',
                    isVisible: () => this.canUploadManifest(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.uploadManifest(),
                },
                {
                    label: 'Edifact upload',
                    icon: 'book',
                    isVisible: () => this.canUploadEdifact(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.uploadEdifact(),
                }
            ]
        },
        {
            label: 'ATA',
            items: [
                {
                    label: 'Insert ATA',
                    icon: 'sign-in-alt',
                    isVisible: () => this.canArrive(),
                    isDisabled: () => this.disableDetained(),
                    onClick: () => this.complete('ata')
                },
            ]
        },
        {
            label: 'ATD',
            items: [
                {
                    label: 'Insert ATD',
                    icon: 'sign-out-alt',
                    isVisible: () => this.canDepart(),
                    isDisabled: () => this.disableDetained(false, this.user.isPort()),
                    onClick: () => this.complete('atd')
                },
            ]
        },
        {
            label: 'Manifest',
            items: [
                {
                    label: 'Confirm Cargo',
                    icon: 'check',
                    isVisible: () => this.canConfirmCargo(),
                    onClick: () => this.confirmCargo()
                },
                {
                    label: 'Undo Confirm Cargo',
                    icon: 'times',
                    isVisible: () => this.canUnconfirmCargo(),
                    onClick: () => this.unconfirmCargo()
                },
                {
                    label: 'No Eurostat Cargo',
                    icon: 'times',
                    isVisible: () => this.canNoCargo(),
                    onClick: () => this.noCargo()
                }
            ]
        },
        {
            label: 'Vessel',
            items: [
                {
                    label: 'Alert Vessel',
                    icon: 'ban',
                    isVisible: () => this.canAlertVessel(),
                    onClick: () => this.executeVesselAction('Alert')
                },
                {
                    label: 'Detain Vessel',
                    icon: 'lock',
                    isVisible: () => this.canDetainVessel(),
                    onClick: () => this.executeVesselAction('Detain')
                },
                {
                    label: 'Release Vessel',
                    icon: 'lock-open',
                    isVisible: () => this.canRealeaseVessel(),
                    onClick: () => this.executeVesselAction('Release')
                },
                {
                    label: () => `Overhang ${this.model?.overhangingStarted ? 'To' : 'From'}`,
                    icon: 'anchor',
                    isVisible: () => this.canOverhangVessel(),
                    isDisabled: () => this.model.overhangingEnded,
                    onClick: () => this.overhangVessel()
                },
                // {
                //     label: () => `Lay-Up ${this.model?.laidUpStarted? 'To' : 'From'}`,
                //     icon: 'hourglass',
                //     isVisible: () => this.canLayUpVessel(),
                //     isDisabled: () => this.model.laidUpEnded,
                //     onClick: () => this.layUpVessel(),
                // }
            ]
        });
    }

    override getIdentifier() {
        return this.model.yearNumber + (this.isAlerted() ? ' (Alert)' : this.isDetained() ? ' (Detained)' : '');
    }

    // Dialog logic in child class
    confirm() {
        this.saveChanges({ redirectToList: true, silent: true });
    }

    override async initialize() {
        if (!this.cloneId && !this.cloneForDepartureId) {
            await super.initialize();
            if (this.model && this.user.isAgent() && (!this.isPrimaryAgent() ||
                (this.model.notificationTypeId === 'D' && this.changeAgencies.filter(x => x.moduleTypeId === 'DP').some(x => x.organizationToId !== this.user.organizationId)))) {
                const isAdditionalAgent = this.model.additionalAgents.some(x => x.agentId === this.user.organizationId);
                const actions = ['Edit', 'Save', 'Undo changes', 'Cancel changes', 'Decline Nomination',
                    'Create Departure', 'Create Vessel Permit', 'Create Conveyance', 'Create Shifting', 'Add. Agent Rights',
                    'Confirm Cargo', 'Undo Confirm Cargo', 'Manifest Upload'];
                this.actionBar.forEach(x => x.items.forEach(item => {
                    const res = actions.includes(`${item.label}`) &&
                        (isAdditionalAgent ? true : this.changeAgencies.some(x => this.matchAgencyType(x, item) && x.organizationToId === this.user.organizationId));
                    const itemRes = _.isFunction(item.isVisible) ? item.isVisible() : item.isVisible;
                    item.isVisible = itemRes && res;
                }));
            }
            return;
        }

        const cloneData = this.cloneData ? JSON.parse(this.cloneData) : null;
        const cloneMethodName = this.cloneId
            ? 'CloneVesselNotification'
            : 'CloneVesselNotificationForDeparture';
        const data = await this.breezeViewService.handleCommand(cloneMethodName, { id: this.cloneId || this.cloneForDepartureId, ...cloneData }, true)
            .catch(e => {
                this.toastrService.error(e?.error?.validationErrors?.[0]?.errorMessage);
                this.location.back();
            });
        if (!!this.cloneForDepartureId && !cloneData.voyage) {
            data.cargoGrossWeight = null;
            data.portOfCallEtd = null;
            // data.voyageIn = null;
            // data.voyageOut = null;
        }

        this.model = this.entityManager.createEntity(this.entityName, data);
        this.mapBreezeCloneProperties();
        this.model.entityAspect.loadNavigationProperty('vessel');

        // #30694 - GUI transit fix, not with LocalPort because it didn't work
        if (this.cloneForDepartureId && this.model.passengers)
            this.model.passengers.forEach(pax => pax.transit = !_.startsWith(pax.embarkationPortId, 'MT'));
    }

    override isEditDisabled() {
        return this.isDetained() && this.user.isAgent() &&
            this.model.statusId !== 'D';
    }

    override canEdit() {
        if (this.model.statusId === 'U')
            return this.user.hasPermission(this.editPermission) &&
                (this.isPrimaryAgent() || this.user.isSystemUser);

        if (((this.user.isAgent() && (this.isPrimaryAgent() ||
            this.model.additionalAgents.some(x => x.agentId === this.user.organizationId)))
            || this.user.isSecurity() || this.user.isHealth()) && this.model.statusId === 'W')
            return true;

        if (this.model.statusId === 'DP' && this.model.notificationTypeId === 'D') return true;

        if (this.model.statusId === 'AR' && this.model.notificationTypeId === 'A') return true;

        if (this.model.statusId === 'A') return true;

        if (!this.user.hasPermission(VesselNotificationPermissions.Action.editAllStatus) && this.model.statusId !== 'D')
            return false;

        if (['X', 'Z'].includes(this.model.statusId)) return false;

        const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
        return vnPermission ?
            _.chain((<BreezeEntity>vnPermission).entityType.dataProperties)
                .filter((p) => p.name.startsWith('edit'))
                .some(p => vnPermission[p.name])
                .value()
            : this.user.hasPermission(this.editPermission);
    }

    canDiscard() {
        return this.viewMode && this.model.statusId === 'D' &&
            this.user.hasPermission(VesselNotificationPermissions.Action.discard) &&
            (this.isPrimaryAgent() || this.user.isPort() || this.user.isSystemUser); // Only primary agent can discard
    }

    canClone() {
        return this.viewMode && this.model.notificationTypeId !== 'D' &&
            (!this.user.isAgent() || this.isPrimaryAgent()) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.clone);
    }

    send72h() {
        return this.executeCommand({ commandName: 'SendPreArrivalVesselNotification', data: { id: this.model.id }, refreshData: true, errorPopup: true });
    }

    canApprove() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.approve) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.approveAll)
            ) &&
            (this.user.isPort() || (this.user.isImmigration() && this.model.portOfCallId !== 'MTWTW') || this.user.isSystemUser ||
                (this.user.isCustoms() && this.model.notificationTypeId === 'D') || ((this.user.isHealth() || this.user.isSecurity()) && this.model.notificationTypeId === 'A'));
    }

    disableApproveReject() {
        return (this.user.isPort() && (this.model.transportMaltaApprovalDate || this.model.transportMaltaRejectionDate)) ||
            (this.user.isCustoms() && this.model.customsApprovalDate) ||
            (this.user.isSecurity() && this.model.iSPSApprovalDate) ||
            (this.user.isImmigration() && this.model.immigrationApprovalDate) ||
            (this.user.isHealth() && this.model.healthApprovalDate);
    }

    approve() {
        if (this.model.notificationTypeId === 'D') {
            if (this.user.isPort() && !this.model.vesselVisit.arrivalVesselNotification.transportMaltaApprovalDate ||
                this.user.isImmigration() && !this.model.vesselVisit.arrivalVesselNotification.immigrationApprovalDate) {
                return this.dialogService.warning('Cannot approve', `The arrival notification (${this.model.vesselVisit.arrivalVesselNotification.yearNumber}) must be approved first.`);
            }

            const conveyances = (this.model.conveyances.concat(this.model.vesselVisit.arrivalVesselNotification.conveyances)).filter(x => x.statusId === 'W');
            if (this.user?.isPort() && conveyances.some(x => !x.transportMaltaApprovalDate) ||
                this.user?.isImmigration() && conveyances.some(x => !x.immigrationApprovalDate) ||
                this.user?.isCustoms() && conveyances.some(x => !x.customsApprovalDate)) {
                return this.dialogService.warning('Cannot approve', `The departure notification (${this.model.vesselVisit.departureVesselNotification.yearNumber}) has outstanding conveyances that need approval.`);
            }
        }

        this.executeAction('Approve');
    }

    canConfirmCargo() {
        return this.viewMode &&
            this.user?.hasPermission(ShippingManifestPermissions.Action.confirm)
            && (this.user?.isSystemUser || this.user?.isPort()
                || this.model.additionalAgents.filter(x => x.agentRoleId === 'CA').some(x => x.agentId === this.user?.organizationId))
            && (this.model.dataConfirmations.filter(x => x.unconfirmedById === this.user?.id).some(x => !x.confirmedCargo)
                || !_.some(this.model.dataConfirmations.filter(x => x.confirmedById === this.user?.id)))
            && this.model.eurostatDataStatusId === 'CMP'
            && ((['UPL', 'INP'].includes(this.model.manifestStatusId) && this.model.agentId === this.user?.organizationId)
                || (this.model.manifestStatusId === 'INP' && this.model.manifests.length > 0))
            ;
    }

    async confirmCargo() {
        const res = await this.dialogService.yesNo(
            this.translateService.instant('Confirm Cargo Data'),
            this.translateService.instant('Please confirm you have submitted complete and accurate cargo information for this vessel.')
        );
        if (!res) return;
        return this.executeCommand({ commandName: 'ConfirmCargoData', data: { id: this.model.id }, errorPopup: true, refreshData: true });
    }

    canUnconfirmCargo() {
        return this.viewMode &&
            this.user?.hasPermission(ShippingManifestPermissions.Action.unconfirm)
            && (this.user?.isSystemUser || this.user?.isPort()
                || this.model.additionalAgents.filter(x => x.agentRoleId === 'CA').some(x => x.agentId === this.user?.organizationId))
            && (this.model.dataConfirmations.filter(x => x.confirmedById === this.user?.id).some(x => x.confirmedCargo))
            ;
    }

    unconfirmCargo() {
        return this.executeCommand({ commandName: 'UnconfirmCargoData', data: { id: this.model.id }, errorPopup: true, refreshData: true });
    }

    canNoCargo() {
        return this.viewMode &&
            this.user?.hasPermission(ShippingManifestPermissions.Action.noCargo) &&
            this.model.manifestStatusId === 'UPL';
    }

    noCargo() {
        return this.executeCommand({ commandName: 'SetManifestStatus', data: { id: this.model.id, statusId: 'NOCA' }, errorPopup: true, refreshData: true });
    }

    async createDeparture() {
        const res = await this.dialogService.yesNo(
            this.translateService.instant('Create Departure Notification'),
            this.translateService.instant('Would you like to transfer the data from Arrival Notification to Departure Notification?')
        );
        if (!res) return;

        const data = await this.dialogService.open(CreateDepartureModalComponent, { size: 'lg' });
        if (!data) return;

        return this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { cloneForDeparture: this.model.id, cloneData: JSON.stringify(data) } });
    }

    canCreateDeparture() {
        const changeAgencyDeparture = this.getChangeAgency('DP');
        if (this.user.isAgent() && this.model.agentId !== this.user.organizationId && !changeAgencyDeparture) {
            const vnPermission = _.find(this.model.permissions, o => o.organizationId === this.user.organizationId);
            if (!vnPermission || !vnPermission.createDeparture) return false;
        }

        return this.viewMode &&
            this.model.vesselVisit?.departureVesselNotificationId === null &&
            this.model.notificationTypeId === 'A' &&
            ['A', 'W', 'AR'].includes(this.model.statusId) &&
            ((changeAgencyDeparture ? changeAgencyDeparture.organizationToId === this.user.organizationId : this.model.agentId === this.user.organizationId) || this.user.isSystemUser) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.createDeparture);
    }

    canCreateVesselPermit() {
        const changeAgencyPermit = this.getChangeAgency('VP');
        return this.user.hasPermission(VesselNotificationPermissions.Action.createVesselPermit) && this.viewMode &&
            (this.isPrimaryAgent() || changeAgencyPermit?.organizationToId === this.user.organizationId || this.user.isSystemUser);
    }

    canCreateConveyance() {
        const changeAgencyConveyance = this.getChangeAgency('CN');
        return ['D', 'A', 'W', 'AR'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.createConveyance) &&
            this.viewMode &&
            ((this.isPrimaryAgent() || changeAgencyConveyance?.organizationToId === this.user.organizationId) || this.user.isSystemUser);
    }

    async createConveyance() {
        return this.router.navigate(['conveyance/create/'], { queryParams: { vesselNotificationId: this.model.id } });
    }

    canCreateVesselShift() {
        const changeAgencyShift = this.getChangeAgency('VS');
        return this.viewMode &&
            this.model.notificationTypeId === 'A' &&
            ['D', 'A', 'W', 'AR'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.createVesselShift) &&
            ((changeAgencyShift ? changeAgencyShift.organizationToId === this.user.organizationId : this.isPrimaryAgent()) || this.user.isSystemUser);
    }

    createVesselShift() {
        if (this.model.vesselShifts.filter(vs => !['C', 'X'].includes(vs.statusId)).length > 0)
            this.toastrService.error(this.translateService.instant('Next shifting must be created from the latest shifting notification.'));
        else this.router.navigate(['vessel-shift/create/'], { queryParams: { vesselNotificationId: this.model.id } });
    }

    canChangeAgency() {
        return this.viewMode &&
            ['W', 'A', 'AR'].includes(this.model.statusId) &&
            this.model.notificationTypeId === 'A' &&
            ((this.user.isAgent() &&
                (this.changeAgencies.some(x => x.organizationToId === this.user.organizationId) ||
                    (this.isPrimaryAgent() && this.changeAgencies.length < 4))) ||
                this.user.isSystemUser);
    }

    async changeAgency() {
        const result = await this.dialogService.open(ChangeAgencyModalComponent, { size: 'lg' }, dialogRef => {
            dialogRef.componentInstance.agentId = this.user.isSystemUser ? this.model.agentId : this.user.organizationId;
            dialogRef.componentInstance.changeAgenciesList = this.changeAgencies?.filter(ac => ac.active && ac.organizationToId !== this.model.agentId);
            dialogRef.componentInstance.statusId = this.model.statusId;
        });
        if (!result) return;

        await this.executeCommand({
            commandName: 'ChangeAgency', data: {
                vesselNotificationId: this.model.id,
                organizationFromId: this.user.isSystemUser ? (this.changeAgencies?.length > 0 ? this.changeAgencies.at(-1).organizationToId : this.model.agentId) : this.user.organizationId,
                organizationToId: result.organizationToId,
                moduleTypeId: result.moduleTypeId
            }
        });
        return this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canReject() {
        return this.viewMode &&
            this.model.statusId === 'W' &&
            (
                this.user.hasPermission(VesselNotificationPermissions.Action.reject) ||
                this.user.hasPermission(VesselNotificationPermissions.Action.rejectAll)
            );
    }

    canExcelUpdate() {
        return this.viewMode &&
            (['D', 'W', 'A'].includes(this.model.statusId) ||
                (this.model.statusId === 'DP' && this.model.notificationTypeId === 'D' ||
                    this.model.statusId === 'AR' && this.model.notificationTypeId === 'A')) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.excelUpdate);
    }
    override async saveChanges(options?: any) {
        if (!options?.silent && !await this.openEMSAWarning(this.model.ssnMessages)) return;
        const changes = this.entityManager.getChanges();
        if (changes.some(x => x.entityType.shortName.includes('VesselCertificate'))) {
            const certChanges = changes.filter(x => x.entityType.shortName.includes('VesselCertificate'));
            let otherChanges = changes.filter(x => !x.entityType.shortName.includes('VesselCertificate'));
            otherChanges = otherChanges.filter(x => !certChanges.some((c: any) => c.attachment === x));
            const changesModified = otherChanges.filter(x => x.entityAspect.entityState === EntityState.Modified);
            const changesAdded = otherChanges.filter(x => x.entityAspect.entityState === EntityState.Added);
            const changesDeleted = otherChanges.filter(x => x.entityAspect.entityState === EntityState.Deleted);
            const data = changesModified.map(x => x.entityAspect.originalValues);
            changesModified.concat(changesAdded).concat(changesDeleted).forEach(x => x.entityAspect.setUnchanged());
            await super.saveChanges({ silent: true, redirectToViewMode: false }, this.model.vessel.entityAspect.entityManager, 'Vessel', this.model.vessel);
            changesModified.forEach((x, i) => x.entityAspect.originalValues = data.at(i));
            changesModified.forEach(x => x.entityAspect.setModified());
            changesAdded.forEach(x => x.entityAspect.setAdded());
            changesDeleted.forEach(x => x.entityAspect.setDeleted());
        }
        return super.saveChanges(options, this.entityManager, this.entityName, this.model);
    }

    override beforeSave(entities: BreezeEntity[]) {
        this.model.numberOfCrew = this.model.workers?.length || this.model.numberOfCrew;
        this.model.numberOfPax = this.model.passengers?.length || this.model.numberOfPax;
        super.beforeSave(entities);
    }

    disableDetained(arrivalDisable = false, isPort = false) {
        return (this.isDetained()) &&
            (arrivalDisable || this.model.notificationTypeId === 'D') &&
            (isPort || this.user.isAgent());
    }

    async executeVesselAction(commandName: string) {
        const res = await this.dialogService.open(DetainedVesselAttachmentListModalComponent, {
            size: 'xl'
        }, dialogRef => {
            dialogRef.componentInstance.mode = ['Detain', 'Alert'].includes(commandName) && !(this.isDetained() || this.isAlerted()) ? ViewMode.create : ViewMode.edit;
            dialogRef.componentInstance.commandName = commandName;
            dialogRef.componentInstance.vesselId = this.model.vessel.currentRevisionId;
        });
        if (!res) return;

        await this.fetch();
    }

    canOverhangVessel() {
        return this.viewMode &&
            ['A', 'AR', 'DP'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.overhang);
    }

    async overhangVessel() {
        const data = await this.dialogService.form(
            {
                title: `Overhang Vessel ${this.model.overhangingStarted ? 'To' : 'From'}`,
                properties: [
                    {
                        name: 'eventTime',
                        type: AppControlType.DateTime,
                        time: true,
                        label: `Overhang ${this.model.overhangingStarted ? 'To' : 'From'}`,
                        initialValue: new Date()
                    }
                ],
                canConfirm: (model) => model.eventTime
            }, { size: 'md' });
        if (!data) return;

        this.executeCommand({
            commandName: 'CreateVtsEventFromVN', data: {
                id: this.model.id,
                notificationTypeId: this.model.notificationTypeId,
                eventType: `${this.model.overhangingStarted ? 'OVE' : 'OVR'}`,
                eventTime: data.eventTime
            }, refreshData: true, errorPopup: true
        });
    }

    canLayUpVessel() {
        return this.viewMode &&
            ['A', 'AR', 'DP'].includes(this.model.statusId) &&
            this.user.hasPermission(VesselNotificationPermissions.Action.layUp);
    }

    async layUpVessel() {
        const data = await this.dialogService.form(
            {
                title: `Lay-Up Vessel ${this.model.laidUpStarted ? 'To' : 'From'}`,
                properties: [
                    {
                        name: 'eventTime',
                        type: AppControlType.DateTime,
                        time: true,
                        label: `Lay-Up ${this.model.laidUpStarted ? 'To' : 'From'}`,
                        initialValue: new Date()
                    }
                ],
                canConfirm: (model) => model.eventTime
            }, { size: 'md' });
        if (!data) return;

        this.executeCommand({
            commandName: 'CreateVtsEventFromVN', data: {
                id: this.model.id,
                notificationTypeId: this.model.notificationTypeId,
                eventType: `${this.model.laidUpStarted ? 'LUE' : 'LUP'}`,
                eventTime: data.eventTime
            }, refreshData: true, errorPopup: true
        });
    }

    matchAgencyType(changeAgencyItem, actionBarItem) {
        switch (_.isFunction(actionBarItem.label) ? actionBarItem.label() : actionBarItem.label) {
            case 'Create departure':
                return changeAgencyItem.moduleTypeId === 'DP';
            case 'Create Vessel Permit':
                return changeAgencyItem.moduleTypeId === 'VP';
            case 'Create Conveyance':
                return changeAgencyItem.moduleTypeId === 'CN';
            case 'Create Shifting':
                return changeAgencyItem.moduleTypeId === 'VS';
            default:
                return true;
        }
    }

    openChatNotesModal() {
        this.dialogService.open(ChatNotesListComponent, {
            windowClass: 'full-screen-modal',
            scrollable: true,
            backdrop: true
        }, dialogRef => {
            dialogRef.componentInstance.vesselNotificationId = +this.id;
            dialogRef.componentInstance.isModal = true;
        });
    }

    canUploadManifest(): boolean {
        return this.viewMode && this.model.manifestStatusId !== 'COM' &&
            this.model.additionalAgents.filter(x => x.agentRoleId === 'CA').some(x => x.agentId === this.user?.organizationId) &&
            this.user.hasPermission(ShippingManifestPermissions.Action.upload);
    }

    async uploadManifest() {
        const data = await this.dialogService.form(
            {
                title: 'Upload file',
                properties: [
                    {
                        name: 'file',
                        type: AppControlType.File,
                        label: 'File',
                        allowedExtensions: ['ccs', 'gol', 'mms', 'tcs', 'txt', 'xml', 'rip', 'hms', 'mma', 'sum']
                    }
                ],
                canConfirm: (model) => model.file,
                message: 'Accepted file extensions: .ccs, .gol, .mms, .tcs, .txt, .xml, .rip, .hms, .mma, .sum'
            },
            { size: 'md' });
        if (!data) return;

        const res = await this.executeCommand({
            commandName: 'UploadManifest', data: {
                vesselNotificationId: this.model.id,
                ...data.file
            }, refreshData: true, errorPopup: true, silent: false
        });
        if (res.results[0] > 0)
            await this.dialogService.warning('Warning', `${res.results[0]} records without valid Commodity Type! Classified as other unidentifiable goods.`);
    }

    canUploadEdifact() {
        return this.viewMode;
    }

    async uploadEdifact() {
        const data = await this.dialogService.form(
            {
                title: 'Upload file',
                properties: [
                    {
                        name: 'file',
                        type: AppControlType.File,
                        label: this.translateService.instant('File'),
                        allowedExtensions: ['.edi']
                    }
                ],
                canConfirm: (model) => model.file
            }, { size: 'md' }
        );

        if (!data) return;

        this.isBusy = true;
        try {
            const res = await this.executeCommand({
                commandName: 'UploadEdifact',
                data: {
                    ...data.file,
                    id: this.model.id,
                },
                refreshData: true,
                errorPopup: true
            });
            this.isBusy = false;
        }
        catch(err) {
            this.isBusy = false;
        }
    }
}
