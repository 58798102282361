import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EntityManager, EntityQuery } from '@cime/breeze-client';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { IActionBarGroup } from '@common/components/action-bar/action-bar.component';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { BreezeService } from '@common/services/breeze.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExportType } from './export-type';

@Component({ template: '' })
export abstract class AbstractBreezeListComponent implements OnInit, OnDestroy, AfterViewInit {
    abstract queryName: string;
    protected breezeService: BreezeService;
    protected entityManager: EntityManager;
    protected persistFilter = environment.settings.list.persistFilter;
    protected router: Router;
    protected toastrService: ToastrService;
    protected translateService: TranslateService;
    private userSubscription: Subscription;

    actionBar: IActionBarGroup[];
    createPermission: string;
    defaultViewMode = environment.settings.view.defaultViewMode;
    protected exportCommand;
    filter: any;
    gridView: GridDataResult;
    isBusy = false;
    parentRoute = window.location.pathname.split('/').slice(0, -1).join('/');
    public selection = [];
    state: State = {
        skip: 0,
        take: 10,
        sort: [{ field: 'id', dir: 'desc' }]
    };
    user: User;
    private _query: EntityQuery;

    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;

    get query() {
        return this._query;
    }

    get filterStorageKey() {
        return `filter:${this.router.url}`;
    }

    get nrOfActiveFilters(): number {
        const clone = _.clone(this.getFilter());
        delete clone.archived;
        delete clone.isShort;
        const params = [null, undefined];
        if (!params.includes(clone.activeTab)) {
            delete clone.activeTab;
            delete clone.typeId;
        }
        if (!params.includes(clone.historyView)) {
            delete clone.historyView;
            delete clone.portId;
        }
        if (!params.includes(clone.dashboardView)) {
            delete clone.dashboardView;
            delete clone.portId;
        }
        if (!params.includes(clone.manifestView)) {
            delete clone.manifestView;
            delete clone.statusIds;
            delete clone.activeEurostatNotificaitons;
        }
        return Object.values(clone).filter(Boolean)?.length || 0;
    }

    protected constructor(private breeze: BreezeViewService) {
        this.getQuery = this.getQuery.bind(this);
        this.search = _.debounce(this.search.bind(this), 50);
        this.clearFilter = _.debounce(this.clearFilter.bind(this), 50);

        this.entityManager = breeze.entityManager;
        this.translateService = breeze.translateService;
        this.toastrService = breeze.toastrService;
        this.router = breeze.router;
        this.breezeService = breeze.breezeService;
        this.userSubscription = breeze.userService.currentUserSubject.subscribe((user) => this.user = user);

        this.actionBar = [
            {
                label: 'Actions',
                items: [{
                    label: 'New',
                    icon: 'plus',
                    onClick: () => this.createNew(),
                    isVisible: () => this.canCreateNew()
                }],
            },
            {
                label: 'Search',
                items: [
                    {
                        label: 'Search',
                        icon: 'search',
                        isDisabled: () => !this.canSearch(),
                        onClick: () => this.search()
                    },
                    {
                        label: 'Clear',
                        icon: 'trash',
                        isDisabled: () => !this.canSearch(),
                        onClick: () => this.clearFilter()
                    }
                ]
            },
            {
                label: 'Export',
                items: [
                    {
                        label: 'Excel',
                        icon: 'file-excel',
                        isDisabled: () => !this.canClickExportExcel(),
                        isVisible: () => this.canExportExcel(),
                        onClick: () => this.export(ExportType.Excel)
                    },
                    {
                        label: 'Pdf',
                        icon: 'file-pdf',
                        isVisible: () => this.canExportPdf(),
                        onClick: () => this.export(ExportType.Pdf)
                    }
                ]
            }
        ];
    }

    ngOnInit() {
        this.filter = this.getFilter();
        this.initialize();
    }

    ngAfterViewInit(): void {
        // Refresh data in case an async operation is running and finishes after the grid data is initialized
        setTimeout(() => this.router.routerState.snapshot.root.queryParams.refresh && this.search(), 2000);
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    getFilter() {
        const persistedFilter = this.getPersistedFilter();
        const filter = this.getDefaultFilter(persistedFilter);
        delete persistedFilter.id;

        if (this.persistFilter && _.isObject(filter) && !(filter as BreezeEntity).entityAspect)
            return { ...filter, ...persistedFilter };

        return filter;
    }

    getDefaultFilter(data: {} = {}) {
        return data;
    };

    initialize() {
        this.search();
    }

    canSearch() {
        return true;
    }

    search() {
        this.selection = [];
        this._query = this.getQuery();
        // setTimeout(() => document.getElementById('content-header')?.focus(), 1500);
        return this._query;
    }

    getQuery(state?: State) {
        const filter = this.breezeService.convertToDto(this.filter || {});
        if (this.persistFilter) localStorage.setItem(this.filterStorageKey, JSON.stringify(filter));

        const query = EntityQuery.from(this.queryName)
            .withParameters({ $method: 'POST', $data: filter });

        return query;
    }

    dataStateChanged(state: DataStateChangeEvent): void {
        this.state = state;
        this.search();
    }

    clearFilter() {
        localStorage.removeItem(this.filterStorageKey);

        if (this.filter?.entityAspect) this.filter.entityAspect.rejectChanges();

        this.filter = this.getFilter();
        this.search();
    }

    canCreateNew() {
        if (this.createPermission) return this.user?.hasPermission(this.createPermission);
        return true;
    }

    createNew() {
        this.router.navigate([`${this.parentRoute}/create/`]);
    }

    protected getPersistedFilter() {
        if (this.persistFilter === false) return {};

        try {
            const filter = localStorage.getItem(this.filterStorageKey);
            return JSON.parse(filter) || {};
        } catch (error) {
            return {};
        }
    }

    public canExportExcel() {
        return !_.isEmpty(this.exportCommand);
    }

    public canClickExportExcel() {
        return !_.isEmpty(this.exportCommand);
    }

    public canExportPdf() {
        return !_.isEmpty(this.exportCommand);
    }

    protected beforeExport(data) { }

    async export(exportType: ExportType, customGrid = null) {
        const grid = customGrid || this.appGrid;
        const columnDefs = grid.getGrid().columns.toArray()
            .filter((x: any) => !_.isEmpty(x.field))
            .map((x: any) => ({ header: x.displayTitle, field: x.field }));
        const data: any = { columnDefs, exportType };

        const filter = this.breezeService.convertToDto(this.filter || {});
        if (this.persistFilter) localStorage.setItem(this.filterStorageKey, JSON.stringify(filter));
        data.query = filter;
        data.selectedIds = this.selection;

        if (this.appGrid?.sortable) data.sort = this.appGrid.getGrid().sort;

        this.beforeExport(data);
        const file = await this.breeze.handleCommand(this.exportCommand, data);
        return UploadActionButtonsComponent.download(file.fileName, file.content);
    }

    fetchResultHandler = (results: any[], labelHandler: (item: any) => string = (item) => item.name) => results.map(item => ({
        value: item.id,
        label: labelHandler(item)
    })).sort((a, b) => (a.label < b.label ? -1 : 1));

    isDarkMode() {
        return JSON.parse(localStorage.getItem('dark'));
    }
}
