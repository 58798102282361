<div class="jumbotron">
    <div class="row">
        <div class="col-6">
            <kendo-chart [title]="marsaxlokkTitle" style="height: 400px;" *ngIf="!!data?.marsaxlokkData">
                <kendo-chart-legend [title]="legendTitle" [labels]="legendLabels" align="start" position="left"></kendo-chart-legend>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
                        {{ category }} {{ value }}
                    </ng-template>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item type="donut" [data]="data.marsaxlokkData"
                        field="value" [color]="getColor" categoryField="status"
                        [labels]="{ visible: false, content: labelContent }"></kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
        <div class="col-6">
            <kendo-chart [title]="valettaTitle" style="height: 400px; background-color: green;" *ngIf="!!data?.valettaData">
                <kendo-chart-legend [title]="legendTitle" [labels]="legendLabels" align="start" position="left"></kendo-chart-legend>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value" let-category="category">
                        {{ category }} {{ value }}
                    </ng-template>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item type="donut" [data]="data.valettaData"
                        field="value" [color]="getColor" categoryField="status"
                        [labels]="{ visible: false, content: labelContent }"></kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <app-control label='Port of call' type="codelist" codelist="LocalPort"
                (ngModelChange)="onVesselVisitFilterChange($event)"
                [(ngModel)]="vesselVisitFilter.portId" [entity]="vesselVisitFilter" property="portId"></app-control>
        </div>
        <div class="col">
            <app-control label='Berth' type="codelist" codelist="Berth"
                (ngModelChange)="onVesselVisitFilterChange($event)"
                [(ngModel)]="vesselVisitFilter.berthId" [entity]="vesselVisitFilter" property="berthId"></app-control>
        </div>
        <div class="col">
            <app-control label='Year' type="number" format="#"
                (ngModelChange)="onVesselVisitFilterChange($event)"
                [(ngModel)]="vesselVisitFilter.year" [entity]="vesselVisitFilter" property="year"></app-control>
        </div>
        <div class="col">
            <app-control label='Vessel Type' type="codelist" codelist="VesselType"
                (ngModelChange)="onVesselVisitFilterChange($event)"
                [(ngModel)]="vesselVisitFilter.vesselTypeId" [entity]="vesselVisitFilter" property="vesselTypeId"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <kendo-chart [title]="vesselVisitTitle" style="height: 400px;" *ngIf="vesselVisitData.data">
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item
                        [line]="{color: getGridColor(), width: 1}"
                        [majorGridLines]="{color: getGridColor(), width: 1}">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item
                        [categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
                        [title]="{ text: 'Months' }"
                        [line]="{color: getGridColor(), width: 1}"
                        [majorGridLines]="{color: getGridColor(), width: 1}">
                    </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                        {{ value }}
                    </ng-template>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item type="line" [data]="vesselVisitData.data"></kendo-chart-series-item>
                    <kendo-chart-series-item type="line" [data]="vesselVisitData.filteredData"></kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
    </div>
    <div class="row mt-5">
        <div class="col">
            <app-control label='Port of call' type="codelist" codelist="LocalPort"
                (ngModelChange)="onVesselVisitCargoFilterChange($event)"
                [(ngModel)]="vesselVisitCargoFilter.portId" [entity]="vesselVisitCargoFilter" property="portId"></app-control>
        </div>
        <div class="col">
            <app-control label='Berth' type="codelist" codelist="Berth"
                (ngModelChange)="onVesselVisitCargoFilterChange($event)"
                [(ngModel)]="vesselVisitCargoFilter.berthId" [entity]="vesselVisitCargoFilter" property="berthId"></app-control>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <kendo-chart [title]="vesselVisitCargoTitle" style="height: 400px;" *ngIf="vesselVisitCargoData.data">
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item
                        [line]="{color: getGridColor(), width: 1}"
                        [majorGridLines]="{color: getGridColor(), width: 1}">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-category-axis>
                    <kendo-chart-category-axis-item
                        [categories]="['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']"
                        [title]="{ text: 'Months' }"
                        [line]="{color: getGridColor(), width: 1}"
                        [majorGridLines]="{color: getGridColor(), width: 1}">
                    </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-tooltip>
                    <ng-template kendoChartSeriesTooltipTemplate let-value="value">
                        {{ value }}
                    </ng-template>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item type="line" [data]="vesselVisitCargoData.data"></kendo-chart-series-item>
                    <kendo-chart-series-item type="line" [data]="vesselVisitCargoData.filteredData"></kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
    </div>
</div>
