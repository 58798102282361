import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AutoFilterBreezeListComponent } from '@common/classes/auto-filter-list';
import { VesselPermitPermissions } from '@common/classes/permissions';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { VesselPermitGridComponent } from '../vessel-permit-grid/vessel-permit-grid.component';

@Component({
    selector: 'app-vessel-permit-list',
    templateUrl: './vessel-permit-list.component.html',
    styleUrls: ['./vessel-permit-list.component.scss'],
    providers: [BreezeViewService],
    encapsulation: ViewEncapsulation.None
})
export class VesselPermitListComponent extends AutoFilterBreezeListComponent implements OnInit {
    queryName = 'VesselPermits';
    exportCommand = 'ExportVesselPermits';
    createPermission = VesselPermitPermissions.Action.create;
    selectedTabIndex = 0;

    @ViewChild(VesselPermitGridComponent) vesselPermitGrid: VesselPermitGridComponent;;

    breadcrumb = [
        {
            icon: 'file',
            title: 'Vessel Permits'
        }
    ];

    constructor(protected breezeViewService: BreezeViewService) {
        super(breezeViewService);

        this.actionBar.push({
            label: 'Archive',
            items: [
                {
                    label: 'Archive Permit',
                    icon: 'archive',
                    isVisible: () => this.user?.hasPermission(VesselPermitPermissions.Action.archive),
                    isDisabled: () => this.selection.length <= 0 || this.selectedTabIndex === 1,
                    onClick: () => this.archivePermit()
                }
            ],
        });
    }

    override ngOnInit() {
        this.filter = super.getFilter();
        this.filter.archived = this.selectedTabIndex === 1;
        this.initialize();
    }

    override getDefaultFilter() {
        return {
            archived: this.selectedTabIndex === 1
        };
    }

    onTabSelect(tab) {
        this.selectedTabIndex = tab.index;
        this.filter.archived = tab.index === 1;
        this.search();
    }

    async archivePermit() {
        await this.breezeViewService.handleCommand('ArchiveVesselPermits', { selectedIds: this.selection });
        this.search();
    }

    override canExportExcel() {
        return this.user?.hasPermission(VesselPermitPermissions.Action.export);
    }

    override export(exportType) {
        return super.export(exportType, this.vesselPermitGrid.appGrid);
    }

    override canExportPdf() {
        return false;
    }
}
