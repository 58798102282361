// tslint:disable-next-line:max-line-length
import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest, HttpResponse, HttpSentEvent, HttpUserEvent } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { UserService } from '@common/services/user.service';
import { environment } from '@environments/environment';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { LoadingBarState } from '@ngx-loading-bar/core/loading-bar.state';
import _ from 'lodash';
import { BehaviorSubject, NEVER, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    private activeRequests = 0;
    private refreshingToken = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private loadingBar: LoadingBarState;

    constructor(
        private loadingBarService: LoadingBarService,
        private userService: UserService,
        private errorHandler: ErrorHandler) {
        this.loadingBar = this.loadingBarService.useRef('http');
    }

    // tslint:disable-next-line:max-line-length
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        const refreshToken = this.userService.getRefreshToken();
        req = this.setTokenAndUrl(req, this.userService.getAccessToken());

        this.activeRequests++;
        this.loadingBar.start();

        return next.handle(req).pipe(
            tap((event: HttpEvent<any>) => {
                if (event.type === HttpEventType.Response) this.decreaseActiveRequests();
            }, (error) => {
                this.decreaseActiveRequests();

                return throwError(() => error);
            }),
            catchError(error => {
                if (this.shouldRefreshToken(error)) return this.refreshToken(req, next, refreshToken);

                if (this.shouldLogout(error)) { // In case the user logged out from another window
                    this.userService.logout();

                    return NEVER;
                }

                if (error.status >= 500) this.errorHandler.handleError(error);

                return throwError(() => error);
            }));
    }

    private shouldRefreshToken(error: any) {
        return error instanceof HttpErrorResponse &&
            !_.endsWith(error.url, 'RefreshAccessToken') &&
            error.headers.has('Token-Expired') &&
            error.status === 401;
    }

    private shouldLogout(error: any) {
        return error instanceof HttpErrorResponse &&
            !_.endsWith(error.url, 'RefreshAccessToken') &&
            !_.endsWith(error.url, 'Login') &&
            !error.headers.has('Token-Expired') &&
            error.status === 401;
    }

    private refreshToken(request: HttpRequest<any>, next: HttpHandler, refreshToken) {
        if (!this.refreshingToken && refreshToken === this.userService.getRefreshToken()) {
            this.refreshingToken = true;
            this.refreshTokenSubject.next(null);

            return this.userService.refreshAccessToken().pipe(
                switchMap((token: any) => {
                    this.refreshingToken = false;
                    this.refreshTokenSubject.next(token.accessToken);
                    return next.handle(this.setTokenAndUrl(request, token.accessToken));
                }),
                catchError(error => {
                    this.refreshingToken = false;
                    // logout only when the refresh token is invalid
                    if ([400, 401].includes(error.status)) this.userService.logout();

                    return throwError(() => error);
                }));

        }
        return this.refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap((token: any) => next.handle(this.setTokenAndUrl(request, token))));
    }

    private setTokenAndUrl(request: HttpRequest<any>, token: string) {
        const headers: any = {};
        if (token) headers.Authorization = `Bearer ${token}`;

        return request.clone({
            setHeaders: headers,
            url: request.url && request.url.indexOf('api://') === 0
                ? request.url.replace('api://', environment.apiUrl)
                : request.url
        });
    }

    private decreaseActiveRequests() {
        this.activeRequests--;

        if (this.activeRequests === 0) this.loadingBar.complete();
    }
}
