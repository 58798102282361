import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeclarationType } from '@common/classes/declaration-types';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-details',
    templateUrl: './vessel-notification-details.component.html',
    styleUrls: ['./vessel-notification-details.component.scss']
})
export class VesselNotificationDetailsComponent implements OnInit {
    @Input() model;
    @Input() isTabVisible: (index: number) => boolean;

    @Output() tabSelected = new EventEmitter();

    tabs = [
        { name: 'Voyage', form: 'General Declaration', type: DeclarationType.General, property: 'id', index: 0 },
        { name: 'Bunkers', property: 'bunkers', index: 1 },
        { name: 'DPG', form: 'Dangerous Goods Manifest', type: DeclarationType.DangerousGoods, property: 'dangerousGoods', index: 2 },
        { name: 'Cargo', form: 'Cargo Declaration', type: DeclarationType.Cargo, property: 'cargo', index: 3 },
        { name: 'Ship\'s stores', form: 'Ship\'s Stores Declaration', type: DeclarationType.ShipsStore, property: 'storeProducts', index: 4 },
        { name: 'Crew', /* form: 'Crew List, Crew Effect\'s Declaration', type: DeclarationType.CrewEffects, property: 'workers',*/ index: 5 },
        { name: 'Passengers', form: 'Passenger List', type: DeclarationType.PassengerList, property: 'passengers', index: 6 },
        { name: 'ISPS', form: 'Ship pre-arrival security information form', type: DeclarationType.Security, property: 'securityOfficerCompanyName', index: 7 },
        { name: 'Waste', form: 'Advance notification form for waste delivery', property: 'wastesArrival', type: DeclarationType.Waste, index: 8 },
        { name: 'Health', form: 'Model of Maritime Declaration of Health', type: DeclarationType.Health, property: 'mdhIssuedAtId', index: 9 },
        { name: 'Itinerary', property: 'cruiseItineraries', index: 10 },
        { name: 'Attachments', property: 'attachments', index: 11 },
        { name: 'General remarks', property: 'generalRemarks', index: 16 }
    ];

    constructor(private breezeViewService: BreezeViewService) { }

    ngOnInit(): void {
        this.tabs = this.tabs.filter(tab => this.isWasteDeparture(tab.index) ? false : this.isTabVisible(tab.index));
    }

    isFilled(property) {
        return this.model && this.isValueFilled(this.model[property]);
    }

    isValueFilled(value) {
        if (!value) return false;

        if (_.isArray(value)) return value.length > 0;

        return true;
    }

    onTabSelect(tab) {
        this.tabSelected.emit(tab.index);
    }

    viewForm(tab, type = null) {
        if (tab.name === 'Crew') {
            this.breezeViewService.handleCommand('generateVesselNotificationPdf', {
                id: this.model.id,
                declarationName: DeclarationType[type]
            }).then(content => UploadActionButtonsComponent.preview(`${DeclarationType[type]}.pdf`, content));
        } else {
            this.breezeViewService.handleCommand('generateVesselNotificationPdf', {
                id: this.model.id,
                declarationName: tab.type
            }).then(content => UploadActionButtonsComponent.preview(`${tab.type}.pdf`, content));
        }
    }

    isWasteDeparture(index) {
        return index === 8 && this.model.notificationTypeId === 'D';
    }
}
