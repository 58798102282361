import { ApplicationRef, Component, ElementRef, forwardRef, Host, Inject, Input, OnChanges, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataProperty, DataType, Entity, EntityAspect, EntityManager, EntityQuery, EntityType, NavigationProperty, ValidationError, } from '@cime/breeze-client';
import { ControlSize } from '@common/classes/control-size';
import { AppFormComponent } from '@common/components/app-form/app-form.component';
import { ViewMode } from '@common/models/view-mode';
import { CodelistService } from '@common/services/codelist.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';
import { ClearEvent, FileInfo, FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import _ from 'lodash';

export enum AppControlType {
    String = 'string',
    TextArea = 'textarea',
    Boolean = 'boolean',
    DateTime = 'datetime',
    Number = 'number',
    Array = 'array',
    Password = 'password',
    CodeList = 'codelist',
    File = 'file',
    YesNo = 'yesno',
    Select = 'select'
}
const defaultSelectLabel = (item) => `${item.customText || ((item.code || item.id) + ((item.name) ? (` - ${item.name}`) : ''))}`;

// TODO:  - split codelist component
//        - remote filtering

@Component({
    selector: 'app-control',
    templateUrl: 'app-control.component.html',
    styleUrls: ['app-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: AppControlComponent,
            multi: true
        }
    ],
})
export class AppControlComponent implements OnInit, OnDestroy, OnChanges, ControlValueAccessor {
    AppControlType = AppControlType;
    errors: ValidationError[] = [];

    private _date: Date;
    private collectionRowEntityType;

    filteredOptions = [];
    file; // used for single selection
    fileRestrictions: FileRestrictions = {
        maxFileSize: environment.settings.appControl.fileMaxSize,
        allowedExtensions: environment.settings.appControl.fileAllowedExtensions
    };
    showTooltip = environment.settings.validation.errors.showTooltip;
    showFeedback = environment.settings.validation.errors.showFeedback;

    isBusy = false;
    private _multiselectValue;

    private val;
    private _subscription;
    private _tooltip;
    // private _syntheticProperty;
    private childEntityName: string;
    private fkName: string;
    applyFilterDebounced = _.debounce(this.applyFilter, 200);

    @Input() options = [];
    @Input() type: AppControlType;
    @Input() label: string;
    @Input() ngModel: NgModel;
    @Input() modelPath?: string;
    @Input() path?: string;
    @Input() entity?: Entity;
    @Input() property?: string;
    @Input() codelist?: string;
    @Input() codelistTake: number = environment.settings.appControl.codelist.take;
    @Input() selectLabel = defaultSelectLabel;
    @Input() filter: (item, search) => boolean;
    @Input() fetch: (search, selectedValue) => Promise<any[]>;
    @Input() size: ControlSize = environment.settings.appControl.size;
    @Input() multi = environment.settings.appControl.multi;
    @Input() time = environment.settings.appControl.time;
    @Input() format;
    @Input() min;
    @Input() max;
    @Input() hasValue = false;
    @Input() margin = true;
    @Input() isDisabled;
    @Input() blur = _.noop;
    @Input() click;
    @Input() nullable = null;
    @Input() textField = 'label';
    @Input() valueField = 'value';
    @Input() popupSettings: PopupSettings = environment.settings.appControl.dropdown.popupSettings;
    @Input() rows: number;
    @Input() fetchOnOpen: boolean;
    @Input() pattern: string;
    @Input() maxlength?: number;
    @Input() tooltipText: string;
    @Input() tooltipClass: string;
    @Input() tooltipPlacement = 'bottom';
    @Input() initialValue: string;
    @Input() decimals: number;
    @Input() prepend: string;
    @Input() disableTooltip: boolean;
    @Input() yesNoValues = [
        { value: true, text: 'Yes' },
        { value: false, text: 'No' }
    ];

    @Input() set maxFileSize(value) {
        this.fileRestrictions.maxFileSize = value || this.fileRestrictions.maxFileSize;
    }
    @Input() set allowedExtensions(value) {
        this.fileRestrictions.allowedExtensions = value || this.fileRestrictions.allowedExtensions;
    }

    get maxFileSize() {
        return this.fileRestrictions.maxFileSize;
    }

    get allowedExtensions() {
        return this.fileRestrictions.allowedExtensions;
    }

    @ViewChild('tooltip', { static: false }) set tooltip(value) {
        this._tooltip = value;
        if (value) this.showValidationErrors();
    }

    @ViewChild('textarea', { static: false }) public textarea;

    private dataType;

    get tooltip() {
        return this._tooltip;
    }

    get isEntity() {
        return this.entity && this.entity.entityAspect instanceof EntityAspect;
    }

    get navigationProperty() {
        if (!this.isEntity) return this.property;

        if (!this.property || !this.entity) return this.property;

        if (this.entity.entityType.getProperty(`${this.property}Id`))return `${this.property}Id`;

        return this.property;
    }

    get breezePropertyToAppControlType() {
        if (!this.isEntity) return null;

        const property = this.getBreezeProperty();
        if (this.isCodeList) return AppControlType.CodeList;

        this.dataType = property.dataType;
        switch (property.dataType) {
            case DataType.Boolean:
                return this.nullable ? AppControlType.YesNo : AppControlType.Boolean;
            case DataType.DateTime:
                return AppControlType.DateTime;
            case DataType.Int16:
            case DataType.Int32:
            case DataType.Int64:
            case DataType.Decimal:
            case DataType.Single:
            case DataType.Double:
                return AppControlType.Number;
            case DataType.String:
                return AppControlType.String;
            case DataType.DateTimeOffset:
            case DataType.Time:
            case DataType.Undefined:
            case DataType.Binary:
            case DataType.Guid:
            case DataType.Byte:
            default:
                // tslint:disable-next-line:max-line-length
                throw new Error(`Unsupported [type = '${property.breezeType}'] on [modelPath = '${this.modelPath}'] and [property = '${this.property}']`);
        }
    }

    set value(value) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
        this.val = value;
        this.onChange(value);
        this.onTouch(value);

        if (this.form) this.form.onChange(this, value);
    }

    get value() {
        return this.val;
    }

    get dateValue() {
        if (_.isDate(this.val)) return this.val;

        if (_.isString(this.val)) {
            const date = new Date(this.val);

            if (!date || !this._date || date.getTime() !== this._date.getTime())
                this._date = date;

            return this._date;
        }

        return this.val;
    }

    set dateValue(value) {
        this._date = value;

        this.value = _.isDate(value) ? value.toISOString() : value;
    }

    get multiselectValue() {
        if (this.childEntityName && this.value) {
            const codes = this.value.map(x => x[this.fkName]);
            if (_.xor(codes, this._multiselectValue).length) this._multiselectValue = codes;
            return this._multiselectValue;
        }
        return this.value;
    }

    set multiselectValue(value) {
        if (this.childEntityName) {
            this._multiselectValue = value;
            _.each(value, id => {
                if (!id) return;
                const elementPresent = _.find(this.entity[this.property], x => x[this.fkName] === id);
                if (!elementPresent) {
                    const newEntity = this.entity.entityAspect.entityManager.createEntity(this.childEntityName);
                    newEntity[this.fkName] = id;
                    this.entity[this.property].push(newEntity);
                }
            });
            _.each(_.clone(this.entity[this.property]), x => {
                const elementPresent = _.find(value, id => x[this.fkName] === id);
                if (!elementPresent) x.entityAspect.setDeleted();
            });
            this.onChange(this.value);
            this.onTouch(this.value);
            this.form?.onChange(this, this.value);
        } else {
            this.value = value;
        }
    }

    onChange = _.noop;
    onTouch = _.noop;
    isMouseenter;

    constructor(
        private applicationRef: ApplicationRef,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        public elementRef: ElementRef,
        private codelistService: CodelistService,
        @Optional() @Host() @Inject(forwardRef(() => AppFormComponent)) private form?: AppFormComponent
    ) {
        // entityManager is a global instance
        // if app-component logic is changed so that navigation properties are set instead of synthetic
        // then an empty copy should be created (or the one from entity.entityAspect.entityManager should be used
    }

    setNumericFormat() {
        if (_.isString(this.format)) return;
        switch (this.dataType) {
            case DataType.Int16:
            case DataType.Int32:
            case DataType.Int64:
                this.format = 'n0';
                break;
            default:
                this.format = environment.settings.appControl.format;
                break;
        }
    }

    ngOnInit() {
        this.maybeApplyActive();

        if (this.isEntity) {
            this.type = this.type ? this.type : this.breezePropertyToAppControlType;
            this.nullable = this.getBreezePropertyNullability();
            this.fillValidationErrors();
        }

        if (this.type === AppControlType.Number) this.setNumericFormat();

        if (!this.type) this.type = AppControlType.String;

        // this._syntheticProperty = this.navigationProperty;

        if (this.isDisabled === undefined && (this.activatedRoute.snapshot.data &&
            this.activatedRoute.snapshot.data.mode === ViewMode.view)) {
            this.isDisabled = true;
        } else if (this.isDisabled === undefined) {
            // workaround to handle modal window
            let childRoot = _.first(this.activatedRoute.children);
            while (childRoot) {
                if (childRoot.snapshot.data.mode)
                    this.isDisabled = childRoot.snapshot.data.mode === ViewMode.view;
                childRoot = _.first(childRoot.children);
            }
        }

        if (this.form) this.form.registerAppControl(this);

        // Execute after content has been checked
        if (![null, undefined].includes(this.initialValue) && !this.value) setTimeout(() => this.value = this.initialValue);
    }

    maybeApplyActive(searchValue = null) {
        this.hasValue = ![null, undefined].includes(this.value) && this.value?.length !== 0 || !!searchValue;
    }

    ngOnDestroy() {
        if (this._subscription)
            this.entity.entityAspect.validationErrorsChanged.unsubscribe(this._subscription);

        if (this.form) this.form.unregisterAppControl(this);
    }

    ngOnChanges(changes) {
        if (changes?.ngModel && (changes.ngModel.firstChange || !changes.ngModel.previousValue || changes.ngModel.previousValue?.length === 0))
            this.writeValue(changes.ngModel.currentValue);

        if (_.isArray(this.value) && this.isEntity && (this.value as any).navigationProperty && this.codelist) {
            this.childEntityName = (this.value as any).navigationProperty.entityType.shortName;
            const idProperty = _.find((this.value as any).navigationProperty.entityType.navigationProperties, x => x.entityType.shortName === this.codelist);
            this.fkName = `${idProperty.name}Id`;
        }

        if (changes?.entity && this.isEntity) {
            if (this._subscription)
                this.entity.entityAspect.validationErrorsChanged.unsubscribe(this._subscription);

            this._subscription = this.entity.entityAspect.validationErrorsChanged.subscribe(() => {
                this.fillValidationErrors();
                this.showValidationErrors();
            });
        }

        const currentValue = changes.ngModel?.currentValue;
        if (this.isEntity && !this.type) this.type = this.breezePropertyToAppControlType;

        if (changes && (changes.entity || changes.codelist)) {
            const initialValue = changes.initialValue?.currentValue;
            this.applyFilter(null, initialValue || currentValue, true);
        }

        if (changes && this.type === AppControlType.YesNo) this.filteredOptions = this.yesNoValues;

        if (currentValue && changes?.entity && this.type === AppControlType.File) this.fetchFiles();

        if (changes?.options && ([AppControlType.CodeList, AppControlType.Select].includes(this.type))) this.filteredOptions = this.options;

        if (!currentValue && this.type === AppControlType.File && !this.multi && this.options) {
            this.file = null;
            this.options = []; // Remove the empty li tag
        }

        this.maybeApplyActive();
    }

    private getBreezeProperty() {
        const property = this.entity.entityType.getProperty(this.navigationProperty) as any;
        if (!property) throw new Error(`[Property = '${this.property}'] does not exists on [modelType = '${this.entity.entityType.shortName}']`);

        return property;
    }

    private getBreezePropertyNullability() {
        const property = this.getBreezeProperty();
        return (property instanceof DataProperty) ? this.nullable = property.isNullable : null;
    }

    private fillValidationErrors() {
        const navigationProperty = this.getNavigationProperty()?.name;
        const validationErrors = _.filter(this.entity.entityAspect.getValidationErrors(), (validatorError: ValidationError) =>
            !!validatorError.propertyName && validatorError.propertyName === navigationProperty || validatorError.propertyName === this.property
        );

        this.errors = validationErrors;
    }

    private showValidationErrors() {
        if (this.errors.length > 0 && !this.disableTooltip) {
            this.tooltip.ngbTooltip = _.map(this.errors, x => x.errorMessage).join('\n');
            if (this.showTooltip) this.tooltip.open();
        } else {
            this.tooltip.ngbTooltip = null;
            this.tooltip.close();
        }
    }

    addCustomValidationErrors(propertyName: string, errorMessage = 'Must not be empty') {
        this.errors = [...this.errors, {
            propertyName,
            errorMessage,
            key: null,
            context: null,
            property: null,
            isServerError: false
        }];
        this.showValidationErrors();
    }

    clearValidationErrors() {
        this.errors = [];
        this.showValidationErrors();
    }

    fetchFiles() {
        const navigationProperty = this.getNavigationProperty();
        if (navigationProperty) {
            if (!navigationProperty.isScalar) {
                this.multi = true;
                this.collectionRowEntityType = navigationProperty.entityType;
            } else {
                this.multi = false;
            }
        }

        // Retrieve attachments
        const ids = [];
        if (this.multi) {
            _.each(this.value, (entity) => {
                if (!entity.attachment) ids.push(entity.attachmentId);
            });
        } else if (navigationProperty) {
            const id = this.entity[this.navigationProperty];
            if (id) {
                if (id > 0) {
                    ids.push(id);
                } else {
                    this.options = [{ name: '' }]; // Hack in order to have a li tag generated
                    this.file = this.entity[navigationProperty.name];
                    return;
                }
            }
        }

        // Hack in order to have a li tag generated
        if (this.multi || ids.length) this.options = [{ name: '' }];

        if (!ids.length) return;

        const query = new EntityQuery('Attachments')
            .withParameters({
                $method: 'POST',
                $data: { ids }
            });

        return this.entity.entityAspect.entityManager.executeQuery(query).then((data) => {
            if (!this.multi) this.file = data.results[0];

            this.applicationRef.tick();
        });
    }

    private getNavigationProperty() {
        if (!this.isEntity) return null;

        const property = this.entity.entityType.getProperty(this.property) as any;
        return property instanceof NavigationProperty ? property : _.find(this.entity.entityType.foreignKeyProperties,
            fk => fk.name === this.property)?.relatedNavigationProperty;

    }

    async applyFilter(search = null, value = this.value, useCache = false) {
        this.maybeApplyActive(search);

        if (this.fetch) {
            this.fetch(search, value).then((data) => {
                if (_.isFunction(this.filter))
                    data = data.filter((x: any) => this.filter(x, search));
                this.filteredOptions = this.options = this.filteredOptions = data;
            });
            return;
        }

        if (!this.isCodeList) {
            this.filteredOptions = this.options?.filter((x, i) =>
                x.value === value
                || x.label?.toLowerCase().includes(search?.toLowerCase())
                || this.filter?.(x, search) === true);
            return;
        }

        if (!this.codelist) {
            const property = this.entity.entityType.getProperty(this.property) as any;
            this.codelist = property.entityType instanceof EntityType
                ? (property.entityType as EntityType).shortName
                : _.find(this.entity.entityType.foreignKeyProperties,
                    fk => fk.name === this.property).relatedNavigationProperty.entityType.shortName;
        }

        let selectedIds = _.isArray(value) ? value : (value ? [value] : null);
        if (selectedIds && this.childEntityName) {
            selectedIds = selectedIds.map(x => x[this.fkName]);
        }
        this.isBusy = true;

        try {
            let data = await this.codelistService.getCodelist({
                name: this.codelist,
                selectedIds,
                filter: search,
                take: this.codelistTake,
                useCache
            });
            if (!data) return;
            if (_.isFunction(this.filter))
                data = data.filter((x: any) => this.filter(x, search));
            this.options = data.map((x: any) => ({
                label: this.selectLabel(x),
                value: x.id
            }));
            this.filteredOptions = this.options.filter((x, i) => x.value === value || i < this.codelistTake + (selectedIds?.length || 0));

            this.isBusy = false;

            this.applicationRef.tick();
        }
        finally {
            this.isBusy = false;
        }
    }

    writeValue(obj: any): void {
        this.val = obj;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        // this.isDisabled = isDisabled;
    }

    get isCodeList() {
        // to avoid file and select
        if (this.type && this.type !== AppControlType.CodeList) return false;

        if (this.codelist || this.navigationProperty !== this.property) return true;

        if (this.isEntity && _.some(this.entity.entityType.foreignKeyProperties, fk => fk.name === this.property)) return true;

        return false;
    }

    public isItemSelected(val) {
        if (this.childEntityName) {
            const selectedValues = _.map(this.entity[this.property], x => x[this.fkName]);
            return selectedValues.some(x => x === val);
        }
        return this.value?.some(x => x === val);
    }

    onDropdownOpen() {
        if (this.fetchOnOpen || this.fetch && (!this.options || this.options.length <= 1)) {
            this.applyFilter(null, this.value, true);

            return;
        }

        this.filteredOptions = this.options.filter((x, i) => x.value === this.value || i < this.codelistTake);
    }

    getTooltip(value) {
        if (!value) return undefined;

        let values = this.childEntityName ? _.map(this.entity[this.property], x => x[this.fkName]) : value;
        if (!_.isArray(values)) values = [values];

        const labels = _.map(_.filter(this.options, (x: any) => values.includes(x.value)), (x: any) => x.label);

        return labels?.join('\n');
    }

    addFiles(event: SelectEvent) {
        if (this.tooltip.ngbTooltip) {
            this.tooltip.ngbTooltip = null;
            this.tooltip.close();
        }

        if (_.some(event.files, file => file.validationErrors && file.validationErrors.length > 0)) {
            const errors = _.chain(event.files)
                .filter(file => file.validationErrors && file.validationErrors.length > 0)
                .map(file => file.validationErrors)
                .flatten()
                .uniq()
                .map(msg => this.translateService.instant(msg))
                .value();

            this.tooltip.ngbTooltip = errors.join('\n');
            this.tooltip.open();
            event.preventDefault();
            return;
        }

        if (this.multi) {
            event.preventDefault(); // Avoid adding another li tag
            if (!_.isArray(this.value)) this.value = [];

            _.each(event.files, (file, i) => {
                this.loadFile(file, content => {
                    (<any>file).content = content;
                    if (this.isEntity) {
                        const entityManager = this.entity.entityAspect.entityManager;
                        const row: any = entityManager.createEntity(this.collectionRowEntityType);
                        row.attachment = entityManager.createEntity('Attachment', { content, name: file.name });
                        this.val.push(row);
                    } else {
                        this.value = this.value.slice(); // Copy array in order to update the grid
                        this.value.push({ name: file.name, content });
                    }
                });
            });
        } else {
            const file = event.files[0];
            this.loadFile(file, content => {
                (<any>file).content = content;
                if (this.file) {
                    event.preventDefault(); // Avoid adding another li tag
                }

                if (this.isEntity) {
                    this.entity[this.getNavigationProperty().name] = this.file =
                        this.entity.entityAspect.entityManager.createEntity('Attachment', { content, name: file.name });
                } else {
                    this.value = this.file = { content, name: file.name };
                }
            });
        }
    }

    removeFile(attachment) {
        if (this.multi) {
            if (this.isEntity) {
                const parent = _.filter(this.value, (row: any) => row.attachmentId === attachment.id)[0];
                attachment.entityAspect.setDeleted();
                parent.entityAspect.setDeleted();
            } else {
                this.value.splice(this.val.indexOf(attachment), 1);
                this.value = this.value.slice(); // Copy array in order to update the grid
            }
            return;
        }
        if (this.isEntity) attachment.entityAspect.setDeleted();

        this.value = null;
        this.options = []; // Remove the empty li tag
    }

    clearFiles(event: ClearEvent) {
        // BUG: Bug in FileSelect component which throws for single file
        // TODO: implement when the bug is fixed
    }

    private loadFile(file: FileInfo, onLoaded: (content: string) => void) {
        if (file.validationErrors?.length) return;

        const reader = new FileReader();
        reader.onload = ev => {
            const str = <string>ev.target.result; // data:text/plain;base64,[CONTENT]
            onLoaded(str.substring(str.indexOf(',') + 1));
        };
        reader.readAsDataURL(file.rawFile);
    }

    onKeyUp(event: KeyboardEvent) {
        if (this.form) this.form.onSubmit(this, event);
    }

    onKeyPress(event: KeyboardEvent) {
        if (!this.pattern) return;

        if (!new RegExp(this.pattern).test(event.key)) event.preventDefault();
    }

    focusInHandler(event: any) {
        this.hasValue = !!this.value || !this.isDisabled;
    }

    focusOutHandler(event: any) {
        this.maybeApplyActive();
    }
}
