import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class PrintService {
    returnUrl: string;
    constructor(private router: Router) { }

    printDocument(route: string, printData: any[]) {
        this.returnUrl = route;
        this.router.navigate([route, this.getRouterOutlet(['print', printData.join()])], this.getExtras());
    }

    onPrintReady() {
        setTimeout(() => {
            window.print();
            this.router.navigate([this.returnUrl || '', this.getRouterOutlet()], this.getExtras());
        });
    }

    getExtras(): NavigationExtras {
        return { queryParamsHandling: 'preserve' };
    }

    getRouterOutlet(outletData = null): NavigationExtras {
        return { outlets: { print: outletData } } as NavigationExtras;
    }
}
