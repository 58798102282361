import { Component, Input } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-chat-note-modal',
    templateUrl: './chat-note-modal.component.html',
    styleUrls: ['./chat-note-modal.component.scss'],
    providers: [BreezeViewService]
})
export class ChatNoteModalComponent extends AbstractBreezeListComponent {
    queryName = 'ChatNotes';
    parentRoute = '/chat-notes';
    persistFilter = false;
    notes = [];
    newNote = null;

    @Input() parentNote: any;
    @Input() vesselNotificationId: number;

    constructor(protected breezeViewService: BreezeViewService, public activeModal: NgbActiveModal) {
        super(breezeViewService);
    }

    getDefaultFilter() {
        return {};
    }

    override search() {
        // this.isBusy = true;
        if (!this.parentNote) return;

        this.filter.parentChatNoteId = this.parentNote?.id;
        this.filter.vesselNotificationId = this.vesselNotificationId;
        const query = this.getQuery();
        this.entityManager.executeQuery(query).then((response) => {
            this.isBusy = false;
            this.notes = response.results;
        });
        return query;
    }

    getNoteStyle(note, isCard: boolean) {
        const isFromCurrentUser = note.createdById === this.user?.id;
        return isCard ? { 'float': isFromCurrentUser ? 'right' : 'left' }
            : { 'background-color': isFromCurrentUser ? '#E6772E' : '#D4D4D4', };
    }

    saveNewNote() {
        this.newNote.parentChatNoteId = this.parentNote?.id;
        this.breezeViewService.handleCommand('SaveChatNote', this.newNote).then((savedNote) => {
            this.parentNote ||= savedNote;
            this.search();
            this.newNote = null;
        });
    }

    addNewNote() {
        this.newNote = { vesselNotificationId: this.vesselNotificationId };
        setTimeout(() => {
            const modalBody = document.getElementsByClassName('modal-body')[0];
            modalBody.scrollTop = modalBody.scrollHeight; // Scroll to bottom
        });
    }

    close() {
        this.activeModal.close();
    }

}
