<div class="jumbotron mt-3">
    <div class="row" style="vertical-align: middle">
        <div style="text-align: middle" class="col text-center">
            <h3 class="h2" style="text-align: middle">Welcome to Task Manager of NMSW Transport Malta</h3>
        </div>
    </div>
    <div class="card mt-3">
        <div class="row" style="height: 200px; display: flex; justify-content: center; align-items: center;"
            *ngIf="!hasAnyPermissions()">
            <h5>Your task manager is empty or you do not have permission view any of its contents</h5>
        </div>
        <div class="row" *ngIf="hasAnyPermissions()">
            <div [ngClass]="{'active-tab': activeTab === 1, 'border-right': isTabVisible([2, 3, 4, 5])}"
                (click)="activateTab(1)" *ngIf="isTabVisible([1])" class="col mt-2 mb-2 text-center pointer">
                <span>
                    <fa-icon icon="ship" size="3x" style="color:#4D4D4D;"></fa-icon>
                </span>
                <br />
                <h2 class="h2">{{vesselNotificationGrid?.appGrid?.internalData?.total}}</h2>
                <h3 translate>VESSEL NOTIFICATIONS</h3>
            </div>
            <div [ngClass]="{'active-tab': activeTab === 2, 'border-right': isTabVisible([3, 4, 5])}"
                (click)="activateTab(2)" *ngIf="isTabVisible([2])" class="col mt-2 mb-2 text-center pointer">
                <span>
                    <fa-icon icon="paste" size="3x" style="color:#4D4D4D;"></fa-icon>
                </span>
                <br />
                <h2 class="h2">{{vesselPermitGrid?.appGrid?.internalData?.total}}</h2>
                <h3 translate>VESSEL PERMITS</h3>
            </div>
            <div [ngClass]="{'active-tab': activeTab === 3, 'border-right': isTabVisible([4, 5])}"
                (click)="activateTab(3)" *ngIf="isTabVisible([3])" class="col mt-2 mb-2 text-center pointer">
                <span>
                    <fa-icon icon="ship" size="3x" style="color:#4D4D4D;"></fa-icon>
                </span>
                <br />
                <h2 class="h2">{{vesselShortNotificationGrid?.appGrid?.internalData?.total}}</h2>
                <h3 translate>SHORT NOTIFICATION</h3>
            </div>
            <div [ngClass]="{'active-tab': activeTab === 4, 'border-right': isTabVisible([5])}" (click)="activateTab(4)"
                *ngIf="isTabVisible([4])" class="col mt-2 mb-2 text-center pointer">
                <span>
                    <fa-icon icon="list-alt" size="3x" style="color:#4D4D4D;"></fa-icon>
                </span>
                <h2 class="h2">{{conveyanceGrid?.appGrid?.internalData?.total}}</h2>
                <h3 translate>CONVEYANCES</h3>
            </div>
            <div [ngClass]="{'active-tab': activeTab === 5}" (click)="activateTab(5)" *ngIf="isTabVisible([5])"
                class="col mt-2 mb-2 text-center pointer">
                <span>
                    <fa-icon icon="ship" size="3x" style="color:#4D4D4D;"></fa-icon>
                </span>
                <br />
                <h2 class="h2">{{vesselShiftGrid?.appGrid?.internalData?.total}}</h2>
                <h3 translate>VESSEL SHIFTS</h3>
            </div>
        </div>
    </div>
    <div *ngIf="!!vesselNotificationQuery" class="card mt-3" [hidden]="![0, 1].includes(activeTab)">
        <div class="card-header">
            <ul class="nav">
                <li class="nav-item">
                    <h5>
                        <fa-icon icon="ship"></fa-icon>{{'Vessel Notifications' | translate}}
                    </h5>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <app-vessel-notification-grid [query]="vesselNotificationQuery" [user]="user"
                [disableGridClickFilter]="true">
            </app-vessel-notification-grid>
        </div>
    </div>
    <div *ngIf="!!vesselPermitQuery" class="card mt-3" [hidden]="![0, 2].includes(activeTab)">
        <div class="card-header">
            <ul class="nav">
                <li class="nav-item">
                    <h5>
                        <fa-icon icon="paste"></fa-icon>{{'Vessel Permits' | translate}}
                    </h5>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <app-vessel-permit-grid [query]="vesselPermitQuery" [disableGridClickFilter]="true">
            </app-vessel-permit-grid>
        </div>
    </div>
    <div *ngIf="!!vesselShortNotificationQuery" class="card mt-3" [hidden]="![0, 3].includes(activeTab)">
        <div class="card-header">
            <ul class="nav">
                <li class="nav-item">
                    <h5>
                        <fa-icon icon="ship"></fa-icon>{{'Short Vessel Notifications' | translate}}
                    </h5>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <app-vessel-short-notification-grid [query]="vesselShortNotificationQuery" [user]="user"
                [disableGridClickFilter]="true">
            </app-vessel-short-notification-grid>
        </div>
    </div>
    <div *ngIf="!!conveyanceQuery" class="card mt-3" [hidden]="![0, 4].includes(activeTab)">
        <div class="card-header">
            <ul class="nav">
                <li class="nav-item">
                    <h5>
                        <fa-icon icon="list-alt"></fa-icon>{{'Conveyances' | translate}}
                    </h5>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <app-conveyance-grid [query]="conveyanceQuery" [disableGridClickFilter]="true"></app-conveyance-grid>
        </div>
    </div>
    <div *ngIf="!!vesselShiftQuery" class="card mt-3" [hidden]="![0, 5].includes(activeTab)">
        <div class="card-header">
            <ul class="nav">
                <li class="nav-item">
                    <h5>
                        <fa-icon icon="ship"></fa-icon>{{'Vessel Shifts' | translate}}
                    </h5>
                </li>
            </ul>
        </div>
        <div class="card-body">
            <app-vessel-shift-grid [query]="vesselShiftQuery" [disableGridClickFilter]="true"></app-vessel-shift-grid>
        </div>
    </div>
</div>