import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { GetVesselPipe } from '@common/pipes/getVessel.pipe';
import { BreezeService } from '@common/services/breeze.service';
import { Workbook, WorkbookSheet, WorkbookSheetColumn, WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import _ from 'lodash';

@Injectable({
    providedIn: 'root',
    deps: [CodelistPipe, DatePipe, GetVesselPipe]
})
export class ExportService {
    constructor(
        private breezeService: BreezeService,
        private codelist: CodelistPipe,
        private datePipe: DatePipe,
        private getVesselPipe: GetVesselPipe
    ) { }

    public async transformExcelExport(workbook, data: any[], entityName, exportFileName = 'Export.xlsx', logbookName = null) {
        const printDate = new Date();
        const sheet = workbook.sheets[0];
        sheet.freezePane = { rowSplit: 0, colSplit: 0 };
        const { rows, columns } = workbook.sheets[0] as { rows: WorkbookSheetRow[], columns: WorkbookSheetColumn[] };
        const headerOptions = rows[0].cells[0] as WorkbookSheetRowCell;
        rows.splice(0, rows.length);
        columns.splice(0, columns.length);

        const dtoGridRows = [];
        if (entityName === 'VtsEvent') data = _.reverse(_.sortBy(data, ['logTime']));
        data.forEach(element => dtoGridRows.push(this.breezeService.convertToDto(element)));

        if (entityName !== 'VtsEvent') {
            for (let i = 1; i < rows.length; i++) {
                for (let j = 0; j < rows[1].cells.length; j++) {
                    const cell = rows[i].cells[j];
                    const codelistKey = this.isCodeList(cell.value, dtoGridRows[i - 1]);
                    const columnTitle = rows[0].cells[j].value as string;
                    if (columnTitle !== 'ID') await this.formatCell(cell, codelistKey, entityName);
                }
            }
        }
        else {
            const maxEventTime = _.maxBy(data, x => x.eventTime)?.eventTime;
            const minEventTime = _.minBy(data, x => x.eventTime)?.eventTime;

            const firstHeaderCells = [
                { value: 'Created Date From: ', bold: true },
                { value: this.formatDate(minEventTime) },
                {}, {}, {},
                { value: 'VESSEL TRAFFIC SERVICES', bold: true },
                {}, {}, {},
                { value: logbookName, bold: true }
            ];

            rows.push({
                type: 'data',
                cells: firstHeaderCells
            });

            const secondHeaderCells = [
                { value: 'Created Date To: ', bold: true },
                { value: this.formatDate(maxEventTime) },
                {}, {}, {},
                { value: 'Ports & Yachting Directorate', bold: true }
            ];

            rows.push({
                type: 'data',
                cells: secondHeaderCells
            });

            const eventHeaderCells = [];
            eventHeaderCells.push(
                Object.assign({}, headerOptions, { value: 'AUDIT (Created Date)' }),
                Object.assign({}, headerOptions, { value: 'Log Time' }),
                Object.assign({}, headerOptions, { value: 'Country + Vessel' }),
                Object.assign({}, headerOptions, { value: 'V/L Type' }),
                Object.assign({}, headerOptions, { value: 'Event Type' }),
                Object.assign({}, headerOptions, { value: 'Event Time' }),
                Object.assign({}, headerOptions, { value: 'Last port of call' }),
                Object.assign({}, headerOptions, { value: 'Berth/Area From' }),
                Object.assign({}, headerOptions, { value: 'Berth/Area To' }),
                Object.assign({}, headerOptions, { value: 'Next port of call' }),
                Object.assign({}, headerOptions, { value: 'Event note' }),
                Object.assign({}, headerOptions, { value: 'Service Vessel' }),
                Object.assign({}, headerOptions, { value: 'Purpose of call' }),
                Object.assign({}, headerOptions, { value: 'Description' }),
                Object.assign({}, headerOptions, { value: 'Created By' }),
                Object.assign({}, headerOptions, { value: 'Discarded' })
            );

            eventHeaderCells.forEach((cell, i) => columns.push({ autoWidth: false, index: i, width: 10 }));

            rows.push({});
            rows.push({
                type: 'header',
                cells: eventHeaderCells
            });

            for (let i = 0; i < dtoGridRows.length; i++) {
                const eventCells = [];
                eventCells.push(
                    { value: this.formatDate(dtoGridRows[i].createdDate) },
                    { value: this.formatDate(dtoGridRows[i].logTime) },
                    {
                        value: this.getVesselPipe.transform(data[i]) ?
                            `${this.getVesselPipe.transform(data[i])?.countryId} - ${this.getVesselPipe.transform(data[i])?.name}` : '-'
                    },
                    { value: await this.codelist.transform(this.getVesselPipe.transform(data[i])?.typeId, 'VesselType') },
                    { value: `${dtoGridRows[i].isRevised ? 'Revised ' : ''}${await this.codelist.transform(dtoGridRows[i].typeId, 'VtsEventType')}` },
                    { value: this.formatDate(dtoGridRows[i].eventTime) },
                    { value: await this.formatLocationCell(dtoGridRows[i], 'lastPortId') },
                    { value: await this.formatLocationCell(dtoGridRows[i], 'lastBerthId') },
                    { value: await this.formatLocationCell(dtoGridRows[i], 'nextBerthId') },
                    { value: await this.formatLocationCell(dtoGridRows[i], 'nextPortId') },
                    { value: (dtoGridRows[i].typeId === 'REPRT' ? data[i].vtsReport?.details : dtoGridRows[i].note) || '-' },
                    { value: await this.formatServiceCell(dtoGridRows[i], 'ServiceVessel') },
                    { value: await this.formatServiceCell(dtoGridRows[i], 'CallPurpose') },
                    { value: dtoGridRows[i].description },
                    { value: await this.codelist.transform(dtoGridRows[i].createdById, 'User') },
                    { value: dtoGridRows[i].discarded ? 'Yes' : 'No' }
                );

                rows.push({
                    type: 'data',
                    cells: eventCells
                });
            }

            rows.push({});
            const footerCells = [
                { value: 'Printed on:', bold: true },
                { value: this.datePipe.transform(printDate, 'short') }
            ];

            rows.push({
                type: 'data',
                cells: footerCells
            });
        }

        // Override default autosize width
        if (rows.length > 1) this.setAutoColumnWidths(rows, columns, 3);

        new Workbook(workbook).toDataURL().then((dataUrl: string) => saveAs(dataUrl, `${exportFileName} ${this.datePipe.transform(printDate, 'dd-MM-yyyy')}.xlsx`));
    }

    async formatLocationCell(event: any, location: string) {
        const generalTypes = ['5OFF', 'ATPST', 'PION', 'PIOFF', 'PIDIH'];
        const someTypes = ['ETD', 'ATD', 'DPATD', 'DEABK'];
        const lastPortEventTypes = ['DEPAR', 'COFF'].concat(generalTypes).concat(someTypes);
        const nextPortEventTypes = generalTypes;
        const lastBerthEventTypes = generalTypes;
        const nextBerthEventTypes = generalTypes.concat(someTypes);

        switch (location) {
            case 'lastPortId':
                return lastPortEventTypes.includes(event.typeId) ? '-' : await this.codelist.transform(event[location], 'Location');
            case 'lastBerthId':
                return event.lastAreaId ? await this.codelist.transform(event.lastAreaId, 'VtsArea') :
                    ((event.typeId === 'ETS' || (event.typeId === 'PION' && event.vesselShiftId)) ?
                        await this.codelist.transform(event.vesselShift.berthShiftingFromId, 'Berth') :
                        lastBerthEventTypes.includes(event.typeId) ? '-' : await this.codelist.transform(event[location], 'Berth'));
            case 'nextBerthId':
                return event.lastAreaId ? await this.codelist.transform(event.nextAreaId, 'VtsArea') :
                    ((event.typeId === 'ETS' || (event.typeId === 'PION' && event.vesselShiftId)) ?
                        await this.codelist.transform(event.vesselShift.berthShiftingToId, 'Berth') :
                        nextBerthEventTypes.includes(event.typeId) ? '-' : await this.codelist.transform(event[location], 'Berth'));
            case 'nextPortId':
                return nextPortEventTypes.includes(event.typeId) ? '-' : await this.codelist.transform(event[location], 'Location');
            default: return '-';
        }
    }

    async formatServiceCell(event: any, type: string) {
        const bunkerBargeTypes = ['BALSH', 'CMPBU'];
        const conveyanceTypes = ['CALSH', 'CMPCO'];
        const noCallPurposeTypes = ['EVENT', 'REPRT', 'OCCUR', '5OFF', 'ATPST', 'PION', 'PIOFF', 'PIDIH', 'CNOPR', 'ETD', 'ATD', 'DPATD', 'DEABK', 'OPEND'];

        switch (type) {
            case 'ServiceVessel':
                return bunkerBargeTypes.includes(event.typeId) ? await this.codelist.transform(event.bunkerBargeServiceVesselId, 'VtsVessel') :
                    conveyanceTypes.includes(event.typeId) ? await this.codelist.transform(event.conveyanceServiceVesselId, 'VtsVessel') :
                    await this.codelist.transform(event.serviceVesselId, 'VtsVessel');
            case 'CallPurpose':
                return noCallPurposeTypes.includes(event.typeId) ? '-' :
                    bunkerBargeTypes.concat(conveyanceTypes).includes(event.typeId) ?
                        event.vtsVesselVisit ? this.getAdditionalCallPurpose(event.vtsVesselVisit) : '-' :
                    event.vesselShiftId ? await this.codelist.transform(event.vesselShift.shiftCallPurposeId, 'ShiftCallPurpose') :
                    await this.codelist.transform(event.vtsVesselVisit?.primaryCallPurposeId, 'VtsCallPurpose');
            default:
                return '';
        }
    }

    getAdditionalCallPurpose(visit): string {
        if (!visit.callPurposes) return '-';

        return visit.callPurposes.map(x => x.vtsCallPurpose.name).join(', ');
    }

    private async formatCell(cell: any, codelistKey: string, entityName: string) {
        // Format codelists
        if (['number', 'string'].includes(typeof cell.value) && codelistKey) {
            let codelistValue = await this.codelist.transform(cell.value, `${entityName}${codelistKey}`);
            if (codelistValue === '-') codelistValue = await this.codelist.transform(cell.value, codelistKey);
            if (codelistValue !== '-') cell.value = codelistValue;
        }
        // Format date
        if (typeof cell.value === 'object' && !!cell.value && !isNaN(Date.parse(cell.value))) {
            cell.value = this.formatDate(cell.value);
            cell.value = cell.value.substring(0, cell.value.length - 3);
        }
        // Format empty array
        if (typeof cell.value === 'object' && cell.value?.length === 0) {
            cell.value = '';
        }
        // Format invoices array
        if (typeof cell.value === 'object' && cell.value?.length > 0) {
            cell.value = cell.value[cell.value.length - 1].invoiceNumber;
        }
        // Format boolean
        if (typeof cell.value === 'boolean') {
            cell.value = cell.value ? '  ✓  ' : '';
        }
    }

    private formatDate(date: Date): string {
        if (!date) return '';
        return `${new Date(date).toLocaleDateString('en-SI')} ${new Date(date).toLocaleTimeString('en-SI')}`;
    }

    private setColumnWidths(columns: WorkbookSheetColumn[], width: number): void {
        columns.forEach(column => {
            column.autoWidth = false;
            column.width = width;
        });
    }

    private setAutoColumnWidths(rows: WorkbookSheetRow[], columns: WorkbookSheetColumn[], startRow = 1): void {
        const allLengths = [];
        for (let i = 0; i < rows[startRow].cells.length; i++) {
            const lengths = [];
            for (const row of rows) {
                const cellValue = row.cells ? row.cells[i]?.value : {};
                if (typeof cellValue === 'string') {
                    lengths.push(cellValue.length);
                }
                else if (typeof cellValue === 'number') {
                    lengths.push(cellValue.toString().length);
                }
                else {
                    lengths.push(5);
                }
            }
            allLengths.push(lengths);
        }

        const maxLengths = [];
        allLengths.forEach(col => maxLengths.push(Math.max(...col)));

        for (const col in columns) {
            if (!col) continue;
            columns[col].autoWidth = false;
            columns[col].width = maxLengths[col] <= 15 ? maxLengths[col] * 9 : maxLengths[col] * 7;
        }
    }

    private isCodeList(cellValue: any, gridRow: any): string {
        // Only checks one level
        for (const [key, value] of Object.entries(gridRow)) {
            if (['number', 'string'].includes(typeof value) && value === cellValue && key.substring(key.length - 2) === 'Id' && key.length > 2) {
                const keyString: string = key.substring(0, key.length - 2);
                return keyString.charAt(0).toUpperCase() + keyString.slice(1);
            }
        }
        return null;
    }
}
