<form novalidate #form="ngForm">
    <button *ngIf="canExport" class="btn btn-sm btn-primary mb-2" type="button" icon="file-excel" (click)="exportToExcel(grid)" translate><fa-icon icon="file-excel"></fa-icon>&nbsp;Export to Excel</button>
    <kendo-grid class="inline-editable"
        [data]="internalData"
        [skip]="state.skip"
        [pageable]="pageable"
        [pageSize]="pageSize || state.take"
        [loading]="isBusy"
        [sort]="sort"
        [rowClass]="rowClass"
        [kendoGridExpandDetailsBy]="expandDetailsBy"
        [(expandedDetailKeys)]="expandedDetailKeys"
        (dataStateChange)="onDataStateChanged($event)"
        (save)="onSave($event)"
        (remove)="onRemove($event)"
        (edit)="onEdit($event)"
        (add)="onAdd($event)"
        (cancel)="onCancel($event)"
        (excelExport)="onExcelExport($event)">
        <kendo-grid-excel *ngIf="canExport" fileName="Export.xlsx"></kendo-grid-excel>
        <kendo-grid-command-column [width]="50" *ngIf="editMode && canEdit">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-row>
                <button kendoGridEditCommand [primary]="true" class="btn btn-primary"  *ngIf="canEditRow(row)">
                    <fa-icon icon="edit"></fa-icon>
                </button>
                <button kendoGridSaveCommand class="btn btn-primary"  *ngIf="canEditRow(row)">
                    <fa-icon *ngIf="isNew !== undefined" icon="check"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-command-column [width]="45" *ngIf="editMode && canEdit && canDelete">
            <ng-template kendoGridCellTemplate let-isNew="isNew" let-row>
                <button kendoGridRemoveCommand class="btn btn-danger" *ngIf="canEditRow(row)">
                    <fa-icon icon="trash"></fa-icon>
                </button>
                <button kendoGridCancelCommand class="btn btn-danger"  *ngIf="canEditRow(row)">
                    <fa-icon icon="ban"></fa-icon>
                </button>
            </ng-template>
        </kendo-grid-command-column>
        <ng-template kendoGridToolbarTemplate position="bottom">
            <div *ngIf="!!detailTemplate && data?.length > 0">
                <button type="button" class="btn btn-sm btn-primary" (click)="expandAllDetails()" translate>Expand all</button>
                <button type="button" class="btn btn-sm btn-primary" (click)="collapseAllDetails()" translate>Collapse all</button>
            </div>
            <button *ngIf="editMode && canEdit && canAddNew" kendoGridAddCommand type="button"
                class="btn btn-sm btn-primary float-right" translate>Add</button>
        </ng-template>
    </kendo-grid>
</form>
