import { Component, Input, OnInit } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-accompanied',
    templateUrl: './vessel-notification-accompanied.component.html',
    styleUrls: ['./vessel-notification-accompanied.component.scss']
})
export class VesselNotificationAccompaniedComponent implements OnInit {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() tabs: Boolean;
    @Input() user: User;
    @Input() title = 'Accompanied';

    vehicleTypes = [];
    accompaniedGrouped = [];

    accompaniedFormOptions: EntityFormOptions = {
        entityName: 'VesselNotificationAccompaniedVehicle',
        canEdit: () => this.canEdit,
        propertyGroups: [
            [
                { name: 'vehicleTypeId', label: 'Vehicle Type', type: AppControlType.CodeList, codelist: 'VehicleType', colSize: 3 },
                { name: 'vehicleMake', label: 'Vehicle Make', maxlength: 250, colSize: 3 },
                { name: 'vehicleBrand', label: 'Vehicle Model', maxlength: 150, colSize: 3 },
                { name: 'quantity', label: 'Quantity', type: AppControlType.Number, maxlength: 24, decimals: 2, colSize: 3 },
                {
                    name: 'weightKgs', label: 'Weight', type: AppControlType.Number,
                    maxlength: 24, decimals: 2, colSize: 3,
                    onChange: (model, value) => model.unitOfMeasureId = value ? 'KGM' : null,
                },
                { name: 'unitOfMeasureId', label: 'Unit of Measure', type: AppControlType.CodeList, codelist: 'AccompaniedUnitOfMeasures', colSize: 3 },
                { name: 'length', label: 'Length', type: AppControlType.Number, maxlength: 24, decimals: 2, colSize: 3 },
                { name: 'width', label: 'Width', type: AppControlType.Number, maxlength: 24, decimals: 2, colSize: 3 },
                { name: 'vehicleNoPlate', label: 'Reg. No.', maxlength: 50, colSize: 3 },
                { name: 'cargoDescription', label: 'Cargo Description', maxlength: 50, colSize: 3 },
                { name: 'driverName', label: 'Driver Name', maxlength: 100, colSize: 3 }
            ]
        ]
    };

    async ngOnInit() {
        this.accompaniedGrouped = _.chain(this.model.accompaniedVehicles)
            .groupBy('vehicleTypeId')
            .map((value, key) => ({
                vehicleTypeId: key,
                quantity: _.sumBy(value, 'quantity'),
                weight: _.sumBy(value, 'weightKgs'),
                length: _.sumBy(value, 'length')
            }))
            .value();
    }
}
