import { DatePipe, CommonModule as NgCommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ChangesDialogComponent } from '@common/components/changes-dialog.component';
import { WarningDialogComponent } from '@common/components/warning-dialog.component';
import { PillDirective } from '@common/directives/pill.directive';
import { InitialsPipe } from '@common/pipes/initials.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule, ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { DateInputsModule, DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FileSelectModule } from '@progress/kendo-angular-upload';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { AccordionHeaderComponent } from './components/accordion-header.component';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { AppControlComponent } from './components/app-control/app-control.component';
import { AppFilterComponent } from './components/app-filter/app-filter.component';
import { AppFormComponent } from './components/app-form/app-form.component';
import { AppGridComponent } from './components/app-grid/app-grid.component';
import { AppListResultsContentComponent } from './components/app-list-results-content/app-list-results-content.component';
import { AppRejIconComponent } from './components/app-rej-icon.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { ContentHeaderComponent } from './components/content-header/content-header.component';
import { DialogFormComponent } from './components/dialog-form/dialog-form.component';
import { AppDrawerComponent } from './components/drawer/drawer.component';
import { EditableGridComponent } from './components/editable-grid/editable-grid.component';
import { EntitiesErrorsComponent } from './components/entities-errors/entities-errors.component';
import { EntityFormComponent } from './components/entity-form/entity-form.component';
import { ErrorDialogComponent } from './components/error-dialog.component';
import { FlagIconComponent } from './components/flag-icon.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { IdleWarningDialogComponent } from './components/idle-warning-dialog.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { UploadActionButtonsComponent } from './components/upload-action-buttons.component';
import { UploadMultipleFilesComponent } from './components/upload-multiple-files.component';
import { UploadSingleFileComponent } from './components/upload-single-file.component';
import { UserNotificationListComponent } from './components/user-notification-list/user-notification-list.component';
import { UserNotificationModalComponent } from './components/user-notification-modal/user-notification-modal.component';
import { VesselCertificatesComponent } from './components/vessel-certificates/vessel-certificates.component';
import { AuthGuard } from './guards/auth.guard';
import { AppHttpInterceptor } from './interceptors/http-interceptor';
import { ActivePipe } from './pipes/active.pipe';
import { CodelistPipe } from './pipes/codelist.pipe';
import { GetVesselPipe } from './pipes/getVessel.pipe';
import { PadPipe } from './pipes/pad.pipe';
import { XmlPipe } from './pipes/xml-pipe';
import { BreezeService } from './services/breeze.service';
import { CqrsService } from './services/cqrs.service';
import { DialogService } from './services/dialog.service';
import { NavigationService } from './services/navigation.service';
import { TitleService } from './services/title.service';
import { WebsocketsService } from './services/websockets.service';
import { ExcelModule } from '@progress/kendo-angular-grid';

@NgModule({
    imports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        FontAwesomeModule,

        NgbModule,

        TranslateModule,

        GridModule,
        InputsModule,
        ButtonModule,
        ButtonsModule,
        DropDownsModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,
        DialogModule,
        FileSelectModule,
        ChartsModule,
        NgxJsonViewerModule,
        ExcelModule
    ],
    exports: [
        NgCommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,

        NgbModule,

        FontAwesomeModule,

        GridModule,
        InputsModule,
        ButtonModule,
        ButtonsModule,
        DropDownsModule,
        DateInputsModule,
        DatePickerModule,
        LayoutModule,
        DialogModule,
        ChartsModule,

        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        ActionBarComponent,
        AppControlComponent,
        AppFormComponent,
        CheckboxComponent,
        LoaderComponent,
        ContentHeaderComponent,
        AccordionHeaderComponent,
        EntityFormComponent,
        UploadActionButtonsComponent,
        FlagIconComponent,
        UserNotificationListComponent,
        VesselCertificatesComponent,
        AppDrawerComponent,
        AppRejIconComponent,
        AppFilterComponent,
        AppListResultsContentComponent,

        TranslateModule,
        CodelistPipe,
        PadPipe,
        ActivePipe,
        InitialsPipe,
        PillDirective,
        XmlPipe,
        GetVesselPipe,

        AppGridComponent,
        EditableGridComponent,
        NgxJsonViewerModule
    ],
    declarations: [
        LoginComponent,
        UnauthorizedComponent,
        SidebarComponent,
        HeaderComponent,
        FooterComponent,
        AppControlComponent,
        CheckboxComponent,
        LoaderComponent,
        ConfirmDialogComponent,
        ChangesDialogComponent,
        WarningDialogComponent,
        IdleWarningDialogComponent,
        ActionBarComponent,
        ContentHeaderComponent,
        AccordionHeaderComponent,
        CodelistPipe,
        AppGridComponent,
        EntitiesErrorsComponent,
        DialogFormComponent,
        EntityFormComponent,
        ErrorDialogComponent,
        UploadSingleFileComponent,
        UploadMultipleFilesComponent,
        UploadActionButtonsComponent,
        UnauthorizedComponent,
        PadPipe,
        ActivePipe,
        InitialsPipe,
        PillDirective,
        XmlPipe,
        AppFormComponent,
        FlagIconComponent,
        UserNotificationModalComponent,
        UserNotificationListComponent,
        VesselCertificatesComponent,
        EditableGridComponent,
        AppDrawerComponent,
        AppRejIconComponent,
        AppFilterComponent,
        AppListResultsContentComponent,
        GetVesselPipe
    ],
    entryComponents: [
        ConfirmDialogComponent,
        WarningDialogComponent,
        ChangesDialogComponent,
        EntitiesErrorsComponent,
        DialogFormComponent,
        ErrorDialogComponent,
        UserNotificationModalComponent
    ],
    providers: [
        TitleService,
        NavigationService,
        DialogService,
        AuthGuard,
        CodelistPipe,
        DatePipe,
        GetVesselPipe,
        BreezeService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        CqrsService,
        WebsocketsService
    ]
})
export class CommonModule { }
