export enum VesselNotificationCategoryCodes {
    Voyage = 'VOY',
    DangerousGoods = 'HZM',
    Bunkers = 'BUN',
    Cargo = 'CRG',
    StoreProducts = 'SST',
    Workers = 'CRW',
    Passengers = 'PAS',
    Security = 'SEC',
    Wastes = 'WST',
    Mdh = 'MDH',
    Itinerary = 'ITI',
    Short = 'SHRT'
}
