<ngb-accordion activeIds="security-info">
    <ngb-panel id="security-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>ISPS & Security Information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <ngb-accordion activeIds="officer-list">
                <ngb-panel id="officer-list">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Company Security Officer Information</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="mb-2">
                            <strong translate>Company Security Officer (CSO) and 24 hour contact details for all security registrations.</strong>
                        </div>
                        <div class="row">
                            <div class="col">
                                <app-control label='Company Name' [maxlength]="50" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.securityOfficerCompanyName" [entity]="model" property="securityOfficerCompanyName"></app-control>
                            </div>
                            <div class="col">
                                <app-control label='Given Name' [maxlength]="50" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.securityOfficerGivenName" [entity]="model" property="securityOfficerGivenName"></app-control>
                            </div>
                            <div class="col">
                                <app-control label='Family Name' [maxlength]="50" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.securityOfficerFamilyName" [entity]="model" property="securityOfficerFamilyName"></app-control>
                            </div>
                            <div class="col">
                                <app-control label='Phone Number' pattern="[\d]" prepend="+" [maxlength]="20"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.securityOfficerPhone" [entity]="model" property="securityOfficerPhone"></app-control>
                            </div>
                            <div class="col">
                                <app-control label='Fax Number' pattern="[\d]" prepend="+" [maxlength]="20"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.securityOfficerFax" [entity]="model" property="securityOfficerFax"></app-control>
                            </div>
                            <div class="col">
                                <app-control label='E-mail Address' pattern="[\w\d\.\-\_\@]" [maxlength]="70"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.securityOfficerEmail" [entity]="model" property="securityOfficerEmail"></app-control>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="remarks">
                <ngb-panel id="remarks">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Remarks</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col">
                                <app-control label='ISPS remarks' [maxlength]="1000" type="textarea"
                                    [isDisabled]="!(user?.isSecurity() || user?.isSystemUser) || !canEdit || !editMode" [(ngModel)]="model.iSPSSecurityRemark" [entity]="model" property="iSPSSecurityRemark"></app-control>
                                </div>
                            <div class="col">
                                <app-control label='Agent remarks' [maxlength]="1000" type="textarea"
                                    [isDisabled]="!(user?.isAgent() || user?.isSystemUser) || !canEdit || !editMode" [(ngModel)]="model.agentSecurityRemark" [entity]="model" property="agentSecurityRemark"></app-control>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <div class="row">
                <div class="col">
                    <ngb-accordion activeIds="issc-details">
                        <ngb-panel id="issc-details">
                            <ng-template ngbPanelHeader let-panel>
                                <h5 translate>Vessel Certificate Security Details</h5>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Does the ship have a valid International Ship Security Certificate (ISSC)?'
                                            type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.isscValid" [entity]="model" property="isscValid"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Please provide reasons for non valid ISSC' type="textarea" [maxlength]="100"
                                            [isDisabled]="model.isscValid || isDisabled()" [(ngModel)]="model.invalidIsscRemarks" [entity]="model" property="invalidIsscRemarks"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <app-control label='ISSC Type' type="codelist" codelist="IsscType"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.isscTypeId" [entity]="model" property="isscTypeId"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <app-control label='ISSC Expiration Date' type="datetime"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.isscExpirationDate" [entity]="model" property="isscExpirationDate"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='ISSC Issuer Type' type="codelist" codelist="IsscIssuerType"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.isscIssuerTypeId" [entity]="model" property="isscIssuerTypeId"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='ISSC Issuer' [maxlength]="50"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.isscIssuer" [entity]="model" property="isscIssuer"></app-control>
                                    </div>
                                </div>
                                <div name="spacer" style="height: 20px;"></div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <div class="col">
                    <ngb-accordion activeIds="security-details">
                        <ngb-panel id="security-details">
                            <ng-template ngbPanelHeader let-panel>
                                <h5 translate>Vessel Security Details</h5>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Security level at which the Ship is currently operating'
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.securityLevelId" [entity]="model" property="securityLevelId"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Does the ship have an approved SSP on board?' type="yesno"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.sspOnBoard" [entity]="model" property="sspOnBoard"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Provide reason for non-valid SSP on board.' type="textarea" [maxlength]="250"
                                            [isDisabled]="model.sspOnBoard || isDisabled()" [(ngModel)]="model.sspRemarks" [entity]="model" property="sspRemarks"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Special or additional security measures taken by the ship.' type="yesno"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.hasAdditionalMeasures" [entity]="model" property="hasAdditionalMeasures"></app-control>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Is there any security-related matter you wish to report?' type="yesno"
                                            [isDisabled]="isDisabled()" [(ngModel)]="model.otherSecurityRemarks" [entity]="model" property="otherSecurityRemarks"></app-control>
                                        </div>
                                    </div>
                                <div class="row">
                                    <div class="col">
                                        <app-control label='Additional security measures details' type="textarea" [maxlength]="250"
                                            [isDisabled]="!model.hasAdditionalMeasures || isDisabled()" [(ngModel)]="model.additionalMeasuresRemarks" [entity]="model" property="additionalMeasuresRemarks"></app-control>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="last-ports">
                <ngb-panel id="last-ports">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Last 10 Port of Calls</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="previousPortOptions" [grid]="previousPortsGrid"></app-entity-form>
                        <h3 translate>Last 10 Ports</h3>
                        <app-grid #previousPortsGrid [data]="model.previousPorts" [sortable]="true" [sort]="previousPortSort">
                            <kendo-grid-column title="{{'Port' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.portId | codelist:'Location' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column field="portFacility" title="{{'Port Facility' | translate}}"></kendo-grid-column>
                            <kendo-grid-column title="{{'Arrival (ATA)' | translate}}" field="arrivalDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'Departure (ATD)' | translate}}" field="departureDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'Security Level' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.securityLevelId | codelist:'SecurityLevel' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Security Measures' | translate}}" field="securityMeasures"></kendo-grid-column>
                        </app-grid>
                        <div class="row mt-3">
                            <div class="col-3">
                                <app-control label='Reason for not submitting the 10 ports' type="codelist" codelist="ISPS10Ports"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.lessThanTenPortsReasonId" [entity]="model" property="lessThanTenPortsReasonId"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Comment' [maxlength]="100" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.lessThanTenPortsRemarks" [entity]="model" property="lessThanTenPortsRemarks"></app-control>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="vessel-activity">
                <ngb-panel id="vessel-activity">
                    <ng-template ngbPanelHeader let-panel>
                        <div class="row">
                            <div class="col-auto">
                                <h5 style="width: auto;" translate>Ship to Ship Activities</h5>
                            </div>
                            <div class="col" style="margin-left: -9px; margin-top: 3px;">
                                <strong style="font-size: 13px;">-   List bellow the ship-to-ship activities, in chronological order (most recent first), which have been carried out during the period of the last ten calls at port facilities listed above.</strong>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col-7">
                                <app-control label='Do you confirm that the ship security procedures specified in the approved SSP been maintained during each of these ship-to-ship activities?'
                                    type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.maintainedSspDuringSts" [entity]="model" property="maintainedSspDuringSts"></app-control>
                            </div>
                        </div>
                        <app-entity-form [options]="vesselActivityOptions" [grid]="vesselActivitiesGrid"></app-entity-form>
                        <hr class="my-3"/>
                        <h3 translate>List of Ship to Ship Activities</h3>
                        <app-grid #vesselActivitiesGrid [data]="model.vesselActivities" [sort]="vesselActivitySort">
                            <kendo-grid-column title="{{'Seq.' | translate}}" field="sequenceNumber"></kendo-grid-column>
                            <kendo-grid-column title="{{'From' | translate}}" field="startDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'To' | translate}}" field="endDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'STS Activity' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.vesselActivityId | codelist:'VesselActivity' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Location' | translate}}" field="locationId"></kendo-grid-column>
                            <kendo-grid-column title="{{'Latitude' | translate}}" field="latitude"></kendo-grid-column>
                            <kendo-grid-column title="{{'Longitude' | translate}}" field="longitude"></kendo-grid-column>
                            <kendo-grid-column title="{{'Security Measures' | translate}}" field="securityMeasures"></kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit && !user?.isSecurity()"
    [editMode]="editMode" title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
