<div class="modal-header">
    <h4 class="modal-title" translate>Create New Remark</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col">
            <kendo-editor [iframe]="false" [(ngModel)]="remarkText"></kendo-editor>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-3">
            <app-control label='Private' type="boolean" [(ngModel)]="model.private" [entity]="model" property="private"></app-control>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="close()">{{'Close' | translate}}</button>
    <button type="button" class="btn btn-primary" [disabled]="!canConfirm()" (click)="confirm()">{{'Confirm' | translate}}</button>
</div>
