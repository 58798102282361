import { Pipe, PipeTransform } from '@angular/core';
import { NavigationProperty } from '@cime/breeze-client';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { CodelistService } from '@common/services/codelist.service';

@Pipe({
    name: 'codelist'
})
export class CodelistPipe implements PipeTransform {
    constructor(private codelistService: CodelistService) { }

    async transform(value: string, ...args): Promise<string> {
        if (typeof args[0] === 'string') {
            if (!value) return Promise.resolve('-');

            const data = await this.codelistService.getCodelist({
                name: args[0],
                selectedIds: [value],
                filter: null,
                take: 1,
                useCache: true
            });
            return this.getText(data[0], value, args[0]);
        }

        const entity: BreezeEntity = args[0];
        const property: string = args[1];
        const { navigationProperty, dataProperty } = this.getProperties(entity, property);
        if (!navigationProperty || !navigationProperty.entityType)
            throw new Error(`Property ${property} does not exist on ${entity.entityType.shortName} or is not NavigationProperty`);

        if (!entity || !entity.entityAspect) return Promise.resolve('-');

        const syntheticPropertyName = dataProperty.name;
        const relationEntityType = navigationProperty.entityType.shortName;
        const id = entity[syntheticPropertyName];
        const data = await this.codelistService.getCodelist({
            name: relationEntityType,
            selectedIds: [id],
            filter: null,
            take: 1,
            useCache: true
        });
        const item = data.find(item => item.id === entity[syntheticPropertyName] || item.code === entity[syntheticPropertyName]);
        return this.getText(item, id, relationEntityType);
    }

    private getText(item, id, codelist) {
        if (!item) {
            if (id) console.error(`${id} not found in codelist ${codelist}`);

            return '-';
        }

        return `${item.customText || (`${item.code || item.id}-${item.name}`)}`;
    }

    private getProperties(entity: BreezeEntity, propertyName: string) {
        const property = entity.entityType.getProperty(propertyName) as any;
        return property instanceof NavigationProperty ? {
            navigationProperty: property,
            dataProperty: entity.entityType.foreignKeyProperties.find(fk => fk.relatedNavigationProperty === property)
        } : {
            navigationProperty: entity.entityType.foreignKeyProperties.find(fk => fk.name === propertyName).relatedNavigationProperty,
            dataProperty: property
        };
    }
}
