import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-health',
    templateUrl: './vessel-notification-health.component.html',
    styleUrls: ['./vessel-notification-health.component.scss']
})
export class VesselNotificationHealthComponent implements OnInit {
    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    attachmentOptions: EntityFormOptions;

    previousPortOptions: EntityFormOptions = {
        entityName: 'VesselNotificationMdhPreviousPort',
        canEdit: () => this.canEdit && !this.user?.isHealth(),
        beforeAdd: (model) => {
            const copy = this.model.mdhPreviousPorts.slice();
            copy.push(model);
            this.updateSequenceNumber(copy, p => p.departureDate);
        },
        onUpdate: () => this.updateSequenceNumber(this.model.mdhPreviousPorts, p => p.departureDate),
        onRemove: () => this.updateSequenceNumber(this.model.mdhPreviousPorts, p => p.departureDate),
        propertyGroups: [
            [
                { name: 'portId', label: 'Port', type: AppControlType.CodeList, codelist: 'Location', colSize: 3 },
                { name: 'departureDate', label: 'Departure Date', type: AppControlType.DateTime, colSize: 3 }
            ]
        ]
    };

    joinedPersonOptions: EntityFormOptions = {
        entityName: 'VesselNotificationJoinedPerson',
        canEdit: () => this.canEdit && !this.user?.isHealth(),
        propertyGroups: [
            [
                { name: 'name', label: 'Name' },
                { name: 'departureDate', label: 'Departure Date', type: AppControlType.DateTime, time: true },
                { name: 'joinedFrom1Id', label: 'Joined From (1)', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'joinedFrom2Id', label: 'Joined From (2)', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'joinedFrom3Id', label: 'Joined From (3)', type: AppControlType.CodeList, codelist: 'Location' }
            ]
        ]
    };

    constructor(private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.attachmentOptions = {
            entityName: 'VesselNotificationMdhAttachment',
            canEdit: () => this.canEdit && !this.user?.isHealth(),
            beforeAdd: (data) => data.sequenceNumber = this.model.mdhAttachments.length + 1,
            onAdd: (entity) => {
                this.model.mdhAttachments.push(entity);
                this.model.entityAspect.validateEntity();
            },
            propertyGroups: [
                [
                    {
                        name: 'personTypeId',
                        label: 'Person type',
                        type: AppControlType.CodeList,
                        codelist: 'PersonType',
                        isDisabled: (model) => model.workerId !== null || model.passengerId !== null,
                        colSize: 2
                    },
                    {
                        name: 'workerId',
                        label: 'Crew member',
                        type: AppControlType.Select,
                        options: this.mapPeople(this.model.workers),
                        isVisible: (model) => model.personTypeId === 'C',
                        colSize: 3
                    },
                    {
                        name: 'passengerId',
                        label: 'Passenger',
                        type: AppControlType.Select,
                        options: this.mapPeople(this.model.passengers),
                        isVisible: (model) => model.personTypeId === 'P',
                        colSize: 3
                    }
                ],
                [
                    { name: 'age', label: 'Age', type: AppControlType.Number },
                    { name: 'joinedDate', label: 'Port, date joined ship/vessel', type: AppControlType.DateTime },
                    { name: 'illness', label: 'Illness', maxlength: 100 },
                    { name: 'symptomsDate', label: 'Symptoms date', type: AppControlType.DateTime },
                    { name: 'reportedToMedicalOfficer', label: 'Reported to PM?', type: AppControlType.Boolean }
                ],
                [
                    { name: 'disposal', label: 'Disposal', maxlength: 100 },
                    { name: 'location', label: 'Location', maxlength: 100 },
                    { name: 'treatment', label: 'Treatment', maxlength: 100 },
                    { name: 'remarks', label: 'Comments', maxlength: 100 },
                ]
            ]
        };
        this.updateSequenceNumber(this.model.mdhPreviousPorts, p => p.departureDate);
    }

    private updateSequenceNumber(items, sortBy) {
        const sorted = _.sortBy(items, sortBy);
        _.each(sorted, (p: any, i) => p.sequenceNumber = i + 1);
        this.changeDetectorRef.detectChanges();
    }

    mapPeople(people) {
        return (people || []).map(x => ({ label: `${x?.givenName} ${x?.familyName}`, value: x?.id }));
    }

    getPerson(model) {
        return model.worker ? model.worker : model.passenger;
    }

    isDisabled() {
        return !this.canEdit || !this.editMode || this.user?.isHealth();
    }
}
