import { Component, ViewChild } from '@angular/core';
import { EntityChangedEventArgs } from '@cime/breeze-client';
import { AbstractBreezeViewComponent } from '@common/classes/breeze-view';
import { EntityFormOptions } from '@common/classes/entity-form';
import { ConveyancePermissions, VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlComponent, AppControlType } from '@common/components/app-control/app-control.component';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import _ from 'lodash';

@Component({
    selector: 'app-conveyance-view',
    templateUrl: './conveyance-view.component.html',
    styleUrls: ['./conveyance-view.component.scss'],
    providers: [BreezeViewService]
})
export class ConveyanceViewComponent extends AbstractBreezeViewComponent {
    entityName = 'Conveyance';
    createPermission = ConveyancePermissions.Action.create;
    editPermission = ConveyancePermissions.Action.edit;

    cloneId: number;
    serviceBoats: any[];
    vesselNotificationId: number;
    additionalTypes: string[] = [];

    @ViewChild('serviceBoatsControl', { static: false }) serviceBoatsControl: AppControlComponent;

    breadcrumb = [
        {
            icon: 'file-alt',
            title: 'Conveyances',
            route: `${this.parentRoute}/list`
        },
        {
            icon: 'file',
            title: 'Conveyance'
        }
    ];

    formOptions: EntityFormOptions = {
        entityName: 'ConveyanceAttachment',
        canEdit: () => this.user?.hasPermission(ConveyancePermissions.Action.edit) && this.editMode,
        beforeAdd: (model) => {
            model.createdDate = new Date();
            model.createdById = this.user?.id;
        },
        propertyGroups: [
            [
                { name: 'attachment', label: 'Attachment', type: AppControlType.File },
                { name: 'remarks', label: 'Remarks', type: AppControlType.String }
            ]
        ]
    };

    constructor(breezeViewService: BreezeViewService) {
        super(breezeViewService);
        this.cloneId = this.breezeViewService.activatedRoute.snapshot.queryParams.clone;
        this.vesselNotificationId = this.breezeViewService.activatedRoute.snapshot.queryParams.vesselNotificationId;
        this.editActionBarGroup.items = [
            ...this.editActionBarGroup.items,
            {
                label: 'Cancel Conveyance',
                icon: 'ban',
                isVisible: () => this.canCancelConveyance(),
                onClick: () => this.cancelConveyance()
            },
            {
                label: 'Send',
                icon: 'paper-plane',
                isVisible: () => this.canSend(),
                onClick: () => this.send()
            },
            {
                label: 'Discard',
                icon: 'trash',
                isVisible: () => this.canDiscard(),
                onClick: () => this.discard()
            },
            {
                label: 'Approve',
                icon: 'thumbs-up',
                isVisible: () => this.canApprove(),
                isDisabled: () => this.disableApproveReject() || (this.user?.isPort() && this.model.transportMaltaApprovalDate),
                onClick: () => this.approve()
            },
            {
                label: 'Reject',
                icon: 'thumbs-down',
                isVisible: () => this.canReject(),
                isDisabled: () => this.disableApproveReject(),
                onClick: () => this.reject()
            },
            {
                label: 'Print',
                icon: 'print',
                isVisible: () => this.canPrint(),
                onClick: () => this.print()
            },
            {
                label: 'Clone',
                icon: 'clone',
                isVisible: () => this.canClone(),
                onClick: () => this.clone()
            },
            {
                label: 'Unconfirm',
                icon: 'times',
                isVisible: () => this.canUnconfirm(),
                onClick: () => this.unconfirm()
            }
        ];
    }

    override modelLoaded() {
        super.modelLoaded();
        this.onVesselNotificationChange(this.vesselNotificationId);
        this.additionalTypes = _.map(this.model.additionalTypes, o => o.conveyanceTypeId);
    }

    override createEntity() {
        return super.createEntity({ statusId: 'D', edtOfService: null });
    }

    override async initialize() {
        this.entityManager.entityChanged.subscribe((entityData: EntityChangedEventArgs) => {
            if (entityData.entity.entityType.shortName === 'ConveyanceAdditionalType' && entityData.entityAction.name === 'EntityStateChange')
                this.additionalTypes = this.model.additionalTypes.map(a => a.conveyanceTypeId);
        });

        if (!this.cloneId) {
            await super.initialize();
            this.fetchServiceBoats();
            return;
        }

        await this.executeCommand({ commandName: 'CloneConveyance', data: { id: this.cloneId }, tracking: false })
            .then(data => {
                const entity = data.results[0];
                this.model = this.entityManager.createEntity(this.entityName, entity);
                this.model.entityAspect.loadNavigationProperty('vessel');
                this.fetchServiceBoats();
                return this.model;
            });
    }

    override getIdentifier() {
        return this.model.yearNumber;
    }

    override canCreateNew() {
        return super.canCreateNew() ||
            this.user?.hasPermission(VesselNotificationPermissions.Action.createConveyance);
    }

    override canEdit() {
        return ['D', 'W', 'U'].includes(this.model.statusId) && super.canEdit();
    }

    onVesselNotificationChange(value) {
        if (!value || this.viewMode) return;

        this.model.vesselNotificationId = value;
        this.model.vesselId = null;
        this.model.entityAspect.loadNavigationProperty('vesselNotification').then((data) => {
            this.model.vesselId = data.results[0].vesselId;
            this.model.entityAspect.loadNavigationProperty('vessel');
        });
    }

    canSend() {
        return this.viewMode && this.model.statusId === 'D' &&
            this.user?.hasPermission(ConveyancePermissions.Action.send);
    }

    async send() {
        const res = await this.dialogService.openConfirmDialog('Conveyance',
            this.translateService.instant('You are about to send the conveyance request to be approved by Transport Malta, Immigration Police, Customs and Medical Health. <br>'
                + 'Please press Send to send the request or press Cancel to cancel it.'),
            false,
            'Cancel',
            'Send');
        if (!res) return;
        await this.executeAction('Send');
        return this.router.navigate([`${this.parentRoute}/list/`]);
    }

    canClone() {
        return this.viewMode &&
            ['W', 'A'].includes(this.model.statusId) &&
            this.user?.hasPermission(ConveyancePermissions.Action.clone);
    }

    clone() {
        this.router.navigate([`${this.parentRoute}/create/`], { queryParams: { clone: this.model.id } });
    }

    canCancelConveyance() {
        return ['W', 'U'].includes(this.model.statusId) &&
            this.viewMode &&
            this.user?.hasPermission(ConveyancePermissions.Action.cancel);
    }

    async cancelConveyance() {
        await this.executeAction('Cancel', { ids: [this.model.id] });
    }

    canDiscard() {
        return this.viewMode && this.model.statusId === 'D' &&
            this.user?.hasPermission(ConveyancePermissions.Action.discard);
    }

    async discard() {
        const res = await this.dialogService.openConfirmDialog('Discard',
            this.translateService.instant('Are you sure you want to discard the current conveyance request?')
        );
        if (!res) return;
        await this.executeAction('Discard', { ids: [this.model.id] });
    }

    canApprove() {
        return this.viewMode && this.model.statusId === 'W' &&
            this.user?.hasPermission(ConveyancePermissions.Action.approve);
    }

    async approve() {
        await this.executeActionWithRemarks('Approve');
    }

    disableApproveReject() {
        return ((this.user?.isCustoms() && this.model.customsApprovalDate) ||
            (this.user?.isImmigration() && this.model.immigrationApprovalDate) ||
            (this.user?.isHealth() && this.model.healthApprovalDate)) &&
            this.model.statusId === 'W';
    }

    canReject() {
        return this.viewMode && ['W', 'A'].includes(this.model.statusId) &&
            this.user?.hasPermission(ConveyancePermissions.Action.reject);
    }

    async reject() {
        await this.executeActionWithRemarks('Reject');
    }

    canPrint() {
        return this.viewMode && ['A', 'R', 'C'].includes(this.model.statusId) &&
            this.user?.hasPermission(ConveyancePermissions.Action.print);
    }

    async print() {
        return this.breezeViewService.handleCommand('PrintConveyances', { ids: [this.model.id] })
            .then(content => UploadActionButtonsComponent.preview(`Conveyance Request.pdf`, content.$value));
    }

    canUnconfirm() {
        return this.viewMode &&
            ['A', 'R', 'W'].includes(this.model.statusId) &&
            this.user?.hasPermission(ConveyancePermissions.Action.unconfirm);
    }

    async unconfirm() {
        await this.executeActionWithRemarks('Unconfirm');
    }

    onLaunchOperatorChange(launchOperatorId) {
        this.model.serviceBoatId = null;
        this.fetchServiceBoats(launchOperatorId);
    }

    async fetchServiceBoats(launchOperatorId = this.model?.launchOperatorId) {
        const results = await this.breezeViewService.handleQuery('ServiceBoats', { launchOperatorId });
        this.serviceBoats = results.map(x => x.id);
        this.serviceBoatsControl?.applyFilter();
    }

    fetchBerths = (filter) => this.breezeViewService.handleQuery('Berths', { filter, portId: 'MTWTW' })
        .then(this.fetchResultHandler);

    fetchNotifications = (name) => this.breezeViewService.handleQuery('DraftVesselNotifications', { name, isPermit: false })
        .then(this.fetchResultHandler);

    serviceBoatsFilter(item) {
        return this.serviceBoats?.includes(item.id);
    }

    onAdditionalTypesChange(ids) {
        this.breezeViewService.onMultiSelectChange(ids, this.model, 'additionalTypes', 'conveyanceTypeId', 'ConveyanceAdditionalType', 'conveyanceId');
    }
}
