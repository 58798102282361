<ngb-accordion activeIds="cargo-info">
    <ngb-panel id="cargo-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>IMO FAL 2 - Cargo information</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col-2">
                    <app-control label='Port of Loading' type="codelist" codelist="Location"
                        [isDisabled]="false" [(ngModel)]="filter.portOfLoadingId" [entity]="filter" property="portOfLoadingId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Port of Discharge' type="codelist" codelist="Location"
                        [isDisabled]="false" [(ngModel)]="filter.portOfDischargeId" [entity]="filter" property="portOfDischargeId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Cargo Type' type="codelist" codelist="ManifestCargoType"
                        [isDisabled]="false" [(ngModel)]="filter.manifestCargoTypeId" [entity]="filter" property="manifestCargoTypeId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Commodity Classification' type="codelist" codelist="Commodity"
                        [isDisabled]="false" [(ngModel)]="filter.commodityId" [entity]="filter" property="commodityId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Package Type' type="codelist" codelist="PackagingType"
                        [isDisabled]="false" [(ngModel)]="filter.packageTypeId" [entity]="filter" property="packageTypeId"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Desciption of Goods' [isDisabled]="false"
                        [(ngModel)]="filter.goodsDescription" [entity]="filter" property="goodsDescription"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Gross Weight (kg)' type="number" format="n2" decimals="2"
                        [isDisabled]="false" [(ngModel)]="filter.grossWeight" [entity]="filter" property="grossWeight"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Bill of Lading' [isDisabled]="false" [(ngModel)]="filter.billOfLading" [entity]="filter" property="billOfLading"></app-control>
                </div>
            </div>
            <div class="row mt-3 mb-3" *ngIf="!editMode && ['D','A','W','R'].includes(model.statusId)">
                <div class="col">
                    <div class="float-right">
                        <button class="btn btn-sm btn-danger mr-2" (click)="clearFilter()">
                            <fa-icon class="ml-1" icon="trash"></fa-icon>&nbsp;<span class="btn-text" translate>Clear</span>
                        </button>
                        <button class="btn btn-sm btn-primary" (click)="search()">
                            <fa-icon class="ml-1" icon="search"></fa-icon>&nbsp;<span class="btn-text" translate>Search</span>
                        </button>
                    </div>
                </div>
            </div>
            <ngb-accordion activeIds="cargo-totals" *ngIf="!editMode && data?.length > 0">
                <ngb-panel id="cargo-totals">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Cargo Summary</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <h3 translate>Total cargo in transit</h3>
                        <kendo-grid [data]="cargoDataTransitGrouped">
                            <ng-template ngFor let-unit [ngForOf]="units">
                                <kendo-grid-column [field]="unit" [title]="unit" *ngIf="!!cargoDataTransitGrouped[0][unit]"></kendo-grid-column>
                            </ng-template>
                        </kendo-grid>
                        <h3 class="mt-3" translate>Total cargo {{model.notificationTypeId === 'A' ? 'Discharge' : 'Loading'}} Malta</h3>
                        <kendo-grid [data]="cargoDataGrouped">
                            <ng-template ngFor let-unit [ngForOf]="units">
                                <kendo-grid-column [field]="unit" [title]="unit" *ngIf="!!cargoDataGrouped[0][unit]"></kendo-grid-column>
                            </ng-template>
                        </kendo-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="row mt-3 mb-3">
                <div class="col-3">
                    <h3 translate>Cargo {{isCargoData ? 'Data' : 'List'}}</h3>
                </div>
                <div class="col-9" *ngIf="isCargoData && editMode">
                    <span class="float-right" translate>
                        <button class="btn btn-sm btn-primary" (click)="copyCargoData()" translate>Copy FAL2 Data</button>
                    </span>
                </div>
            </div>
            <app-entity-form [options]="formOptions" [grid]="cargoGrid"></app-entity-form>
            <app-grid #cargoGrid [data]="editMode ? cargoCollection : data">
                <kendo-grid-column *ngIf="!isCargoData" title="{{'Sequence Number' | translate}}" field="sequenceNumber" [width]="80"></kendo-grid-column>
                <kendo-grid-column title="{{'Port of Loading' | translate}}" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.portOfLoadingId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Port of Discharge' | translate}}" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.portOfDischargeId | codelist:'Location' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Cargo Type' | translate}}" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.manifestCargoTypeId | codelist:'ManifestCargoType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Number of Units' | translate}}" field="cargoUnitQuantity" [width]="70"></kendo-grid-column>
                <kendo-grid-column title="{{'Empty Unit' | translate}}" [width]="80">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.empty === null ? '-' : row.empty ? 'Yes' : 'No'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Transhipment' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.transhipment === null ? '-' : row.transhipment ? 'Yes' : 'No'}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Commodity Classification' | translate}}" [width]="200">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.commodityId | codelist:'Commodity' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Type of Packages' | translate}}" [width]="110">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.packagingTypeId | codelist:'PackagingType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Bill of Lading' | translate}}" field="billOfLading" [width]="130"></kendo-grid-column>
                <kendo-grid-column title="{{'Marks & Numbers' | translate}}" field="transportUnitNumber" [width]="130"></kendo-grid-column>
                <kendo-grid-column *ngIf="!isCargoData" title="{{'Number of Packages' | translate}}" field="quantity" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Description' | translate}}" field="description" [width]="150"></kendo-grid-column>
                <kendo-grid-column title="{{'Gross Mass' | translate}}" field="grossWeight" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Unit of Measurement' | translate}}" [width]="130">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.unitOfMeasureId | codelist:'UnitOfMeasure' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngIf="isCargoData" title="{{'Consignee' | translate}}" field="consignee" [width]="130"></kendo-grid-column>
                <kendo-grid-column *ngIf="isCargoData" title="{{'Notify Party' | translate}}" field="notifyParty" [width]="130"></kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit" [editMode]="editMode"
    title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
