import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AbstractBreezeListComponent } from './breeze-list';

export abstract class AutoFilterBreezeListComponent extends AbstractBreezeListComponent {
    autoSearch: boolean;
    autoFilterChanged: Subject<any> = new Subject<any>();
    hiddenFilter;

    constructor(protected breezeViewService: BreezeViewService) {
        super(breezeViewService);
        this.autoFilterChanged
            .pipe(debounceTime(500))
            .subscribe(() => this.search());
    }

    onAutoFilterChange(event = null) {
        this.autoFilterChanged.next(this.filter);
    }

    onFilterPanelChange(event: NgbPanelChangeEvent) {
        this.autoSearch = !event.nextState;
        const hiddenFilter = this.hiddenFilter;
        this.hiddenFilter = this.filter;
        this.filter = hiddenFilter || this.getDefaultFilter();
    }
}
