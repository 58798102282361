<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title" translate>Chat Notes</h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="height: 700px; scroll-behavior: smooth">
            <div *ngFor="let note of notes">
                <div class="card mt-3 mb-3" style="width:90%"
                    [ngClass]="note.createdById === user?.id ? 'myMessageCard' : 'otherMessageCard'">
                    <div class="card-header" [ngStyle]="getNoteStyle(note, false)">
                        <div class="row"
                            [ngClass]="note.createdById === user?.id ? 'justify-content-end' : 'justify-content-start'">
                            <div class="col-3">
                                <h6> Note type: {{ note.typeId | codelist:'ChatNoteType' | async }} </h6>
                            </div>
                            <div class="col-3" *ngIf="note.forUserId">
                                <h6> For user: {{ note.forUserId | codelist:'User' | async }} </h6>
                            </div>
                            <div class="col-3" *ngIf="note.forOrganizationId">
                                <h6> For organization:
                                    {{ note.forOrganizationId | codelist:'Organization' | async }}
                                </h6>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row  text-center">
                            <div class="col">
                                <h5> {{note.note}} </h5>
                            </div>
                        </div>
                        <div class="row" *ngIf="!!note.attachment">
                            <div class="col">
                                {{note.attachment.name}}
                                <app-upload-action-buttons [file]="note.attachment" [disabled]="true"
                                    [multiple]="false">
                                </app-upload-action-buttons>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer" [ngStyle]="getNoteStyle(note, false)">
                        <div [ngStyle]="getNoteStyle(note, true)">
                            <h6>
                                {{note.createdById | codelist:note:'createdById'|async}}, {{note.createdDate | date: 'dd/MM/yyyy HH:mm'}}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="newNote" class="card mt-3 mb-3 float-right" style="width:90%">
                <div class="card-body">
                    <div class="row">
                        <div class="col-3">
                            <app-control label="Note Type" type="codelist" codelist="ChatNoteType"
                                [(ngModel)]="newNote.typeId" [entity]="newNote" property="typeId" [isDisabled]="false">
                            </app-control>
                        </div>
                        <div class="col-3">
                            <app-control label="For User" type="codelist" codelist="User"
                                [(ngModel)]="newNote.forUserId" [entity]="newNote" property="forUserId" [isDisabled]="false">
                            </app-control>
                        </div>
                        <div class="col-3">
                            <app-control label="For Organization" type="codelist" codelist="Organization"
                                [(ngModel)]="newNote.forOrganizationId" [entity]="newNote" property="forOrganizationId" [isDisabled]="false">
                            </app-control>
                        </div>
                        <div class="col-3">
                            <app-control label="Attachment" type="file" [(ngModel)]="newNote.attachment" [entity]="newNote" property="attachment"
                                [isDisabled]="false">
                            </app-control>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-control label="Note" type="textarea" [(ngModel)]="newNote.note" [entity]="newNote" property="note"  [isDisabled]="false">
                            </app-control>
                        </div>
                    </div>
                </div>
                <div class="card-footer" style="background-color: inherit !important">
                    <div class="row justify-content-end">
                        <button type="button" class="btn btn-danger mr-2"
                            (click)="newNote = null">{{'Cancel'|translate}}</button>
                        <button type="button" class="btn btn-primary" (click)="saveNewNote()"
                            [disabled]="!newNote.note || !newNote.typeId">{{'Save'|translate}}</button>
                    </div>
                </div>
            </div>


        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="close()">{{'Close'|translate}}</button>
            <button type="button" class="btn btn-primary" (click)="addNewNote()"
                [disabled]="!!newNote">{{'Add reply'|translate}}</button>
        </div>
    </ng-template>
</app-loader>
