import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { EntityManager, Validator } from '@cime/breeze-client';
import { Organization } from '@common/breeze-models/organization';
import { User } from '@common/breeze-models/user';
import { Vessel } from '@common/breeze-models/vessel';
import { VesselNotification } from '@common/breeze-models/vessel-notification';
import { VesselNotificationDangerousGoods } from '@common/breeze-models/vessel-notification-dangerous-goods';
import { VesselNotificationMdhAttachment } from '@common/breeze-models/vessel-notification-mdh-attachment';
import { VesselPermit } from '@common/breeze-models/vessel-permit';
import { UserService } from '@common/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class EntityValidator {

    constructor(
        private entityManager: EntityManager,
        private userService: UserService,
        private translateService: TranslateService,
        private toastrService: ToastrService) { }

    async fetchUserAndMetadata(state: RouterStateSnapshot) {
        try {
            await this.userService.getCurrentUser();
            if (this.entityManager.metadataStore.isEmpty()) {
                await this.entityManager.fetchMetadata().then(store => {
                    // TODO: move elsewhere
                    this.entityManager.metadataStore.registerEntityTypeCtor('User', User);
                    this.entityManager.metadataStore.registerEntityTypeCtor('Organization', Organization);
                    this.entityManager.metadataStore.registerEntityTypeCtor('Vessel', Vessel);
                    this.entityManager.metadataStore.registerEntityTypeCtor('VesselPermit', VesselPermit);
                    this.entityManager.metadataStore.registerEntityTypeCtor('VesselNotification', VesselNotification);
                    this.entityManager.metadataStore.registerEntityTypeCtor('VesselNotificationMdhAttachment', VesselNotificationMdhAttachment);
                    this.entityManager.metadataStore.registerEntityTypeCtor('VesselNotificationDangerousGoods', VesselNotificationDangerousGoods);

                    // Vessel Notification
                    const vesselNotification = this.entityManager.metadataStore.getEntityType('VesselNotification');
                    vesselNotification.getProperty('dpgContactEmail').validators.push(Validator.emailAddress(null));
                    vesselNotification.getProperty('securityOfficerEmail').validators.push(Validator.emailAddress(null));

                    const organization = this.entityManager.metadataStore.getEntityType('Organization');
                    organization.getProperty('email').validators.push(Validator.emailAddress(null));

                    // Vessel
                    let entityType = this.entityManager.metadataStore.getEntityType('VesselCertificate');
                    entityType.addValidator(new Validator(
                        'expirationDateRequired',
                        (value, context) => context.entity.permanent ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'expirationDate');

                    // VesselNotificationDangerousGoods
                    entityType = this.entityManager.metadataStore.getEntityType('VesselNotificationDangerousGoods');
                    const imdgValidator = new Validator(
                        'imdgValidator',
                        (value, context) => 'IMDG' !== context.entity.cargoRegulationId ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    );
                    entityType.addValidator(imdgValidator, 'imdgClassId');
                    entityType.addValidator(imdgValidator, 'unNumber');
                    entityType.addValidator(imdgValidator, 'packagingTypeId');
                    entityType.addValidator(imdgValidator, 'portOfLoadingId');
                    entityType.addValidator(imdgValidator, 'portOfDischargeId');
                    entityType.addValidator(new Validator(
                        'ibcValidator',
                        (value, context) => 'IBC' !== context.entity.cargoRegulationId ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'ibcCodeId');
                    entityType.addValidator(new Validator(
                        'imsbcValidator',
                        (value, context) => 'IMSBC' !== context.entity.cargoRegulationId ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'imsbcCodeId');
                    entityType.addValidator(new Validator(
                        'unitOfMeasureIdRequired',
                        (value, context) => ['IMDG', 'IMSBC', 'IBC'].indexOf(context.entity.cargoRegulationId) < 0 ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'unitOfMeasureId');
                    entityType.addValidator(new Validator(
                        'transportNumberValid',
                        (value, context) => ['CN', 'VN'].includes(context.entity.packagingTypeId)
                            && context.entity.cargoRegulationId === 'IMDG' ? !!value : true,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'transportUnitNumber');

                    // VesselNotificationCargo
                    entityType = this.entityManager.metadataStore.getEntityType('VesselNotificationCargo');
                    entityType.addValidator(new Validator(
                        'cargoTransportNumberValid',
                        (value, context) => ['CN', 'VN'].includes(context.entity.packagingTypeId) ? !!value : true,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    ), 'transportUnitNumber');

                    const cargoValidator = new Validator(
                        'cargoValidator',
                        (value, context) => !!value,
                        this.getValidatorContext(this.translateService.instant('Must not be empty.'))
                    );
                    entityType.addValidator(cargoValidator, 'portOfLoadingId');
                    entityType.addValidator(cargoValidator, 'portOfDischargeId');
                    entityType.addValidator(cargoValidator, 'unitOfMeasureId');

                    // VesselNotificationVesselActivity
                    entityType = this.entityManager.metadataStore.getEntityType('VesselNotificationVesselActivity');
                    entityType.addValidator(new Validator(
                        'locationValidator',
                        (value, context) => !!context.entity.latitude || !!context.entity.longitude ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('If port name is not provided you must insert latitude and longitude.'))
                    ), 'locationId');
                    const locationFulfilledValidator = new Validator(
                        'locationFulfilledValidator',
                        (value, context) => !!context.entity.locationId ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('If latitude and longitude are not provided you must insert port name.'))
                    );
                    entityType.addValidator(locationFulfilledValidator, 'latitude');
                    entityType.addValidator(locationFulfilledValidator, 'longitude');
                    // VesselNotificationMdhAttachment
                    entityType = this.entityManager.metadataStore.getEntityType('VesselNotificationMdhAttachment');
                    entityType.addValidator(new Validator(
                        'passengerValidator',
                        (value, context) => context.entity.personTypeId !== 'P' ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Passenger name is required.'))
                    ), 'passengerId');
                    entityType.addValidator(new Validator(
                        'workerValidator',
                        (value, context) => context.entity.personTypeId !== 'C' ? true : !!value,
                        this.getValidatorContext(this.translateService.instant('Worker name is required.'))
                    ), 'workerId');
                });
            }

            return true;
        } catch (error) {
            this.toastrService.error(this.translateService.instant('You must be authenticated!'));
            this.userService.logout();

            return false;
        }
    }

    private getValidatorContext(message: string) {
        return {
            messageTemplate: message
        };
    }
}
