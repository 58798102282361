import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem, NavigationService } from '@common/services/navigation.service';
import { EventData, SharedService } from '@common/services/shared.service';
import { UserService } from '@common/services/user.service';
import _ from 'lodash';
import { filter, first } from 'rxjs/operators';
import { User } from '../../models/User';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
    url: string;
    navigation: MenuItem[];
    isCollapsed = false;
    selectedItem: MenuItem;

    user: User;
    public data = this.isCollapsed;

    constructor(
        navigationService: NavigationService,
        private router: Router,
        userService: UserService,
        private sharedService: SharedService) {
        this.navigation = navigationService.getNavigation();

        userService.currentUserSubject.subscribe((user) => {
            this.user = user;
            this.setPermissions();
        });

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) this.url = this.router.url;
        });

        this.router.events.pipe(filter(x => x instanceof NavigationEnd), first()).subscribe((event) => {
            const item = _.find(this.navigation.filter(x => x.route === this.router.url || _.find(x.children, c => this.router.url.indexOf(c.route) === 0)));

            if (item) this.selectedItem = item;
        });
        this.sharedService.on('collapse-sidebar', () => this.isCollapsed = true);
    }

    isActive(route: string) {
        return this.url && (this.url === route || this.url.indexOf(route) === 0);
    }

    selectItem(item) {
        if (item.route) {
            this.router.navigate([item.route]);
            this.selectedItem = null;
            return;
        }
        if (this.selectedItem === item) {
            this.selectedItem = null;
            return;
        }
        this.isCollapsed = false;
        this.selectedItem = item;
    }

    selectChild(child) {
        if (child.route) this.router.navigate([child.route]);
    }

    toggle() {
        this.isCollapsed = !this.isCollapsed;
        this.sharedService.emit(new EventData('sidebarCollapsed', this.isCollapsed));
    }

    private setPermissions() {
        this.navigation.forEach(nav => this.setMenuItemPermission(nav));
    }

    private setMenuItemPermission(menuItem: MenuItem) {
        menuItem.hasPermission = _.isFunction(menuItem.isVisible) ?
            menuItem.isVisible(this.user) : this.hasPermission(menuItem);

        if (this.user && _.isFunction(menuItem.icon))
            menuItem.icon = menuItem.icon(this.user);
        if (this.user && _.isFunction(menuItem.class))
            menuItem.class = menuItem.class(this.user);

        menuItem.children?.forEach(child => this.setMenuItemPermission(child));
    }

    hasPermission(menuItem: MenuItem) {
        if (!this.user) return false;

        const permissions = _.isArray(menuItem.permissions) ? menuItem.permissions : (menuItem.permissions ? [menuItem.permissions] : []);

        if (!menuItem.permissions || (_.isArray(menuItem.permissions) && menuItem.permissions.length === 0))
            return true;

        if ((['vts', 'vessels', 'faq', 'document'].some(x => menuItem.route?.includes(x)) || ['Vessel Traffic System', 'Vessel Registry', 'Help', 'Administration'].some(x => menuItem.title.includes(x))) &&
            this.user?.isVtsSystemUser) return true;

        return this.user?.hasPermission(...permissions);
    }

    getNumber(menuItem: MenuItem) {
        if (!menuItem.getNumber || !this.user) return null;

        return menuItem.getNumber(this.user);
    }
}
