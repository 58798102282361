import { Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EntityManager } from '@cime/breeze-client';
import { VesselNotificationPermissions, VesselPermissions } from '@common/classes/permissions';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { SharedService } from '@common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';
import { VesselNotificationCategoryCodes } from 'app/vessel-notification/classes/vessel-notification-category-codes';
import { ImportIhsVesselModalComponent } from 'app/vessel/components/import-ihs-vessel-modal/import-ihs-vessel-modal.component';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

interface ITabPermission {
    viewPermission: any;
    editPermission: any;
    viewProperty: string;
    editProperty: string;
    ruleSet: string;
}
@Component({
    selector: 'app-vessel-short-notification-view-content',
    templateUrl: './vessel-short-notification-view-content.component.html',
    styleUrls: ['./vessel-short-notification-view-content.component.scss']
})
export class VesselShortNotificationViewContentComponent implements OnInit {

    static tabPermissions: { [index: number]: ITabPermission } = {
        0: {
            viewPermission: VesselNotificationPermissions,
            editPermission: VesselNotificationPermissions,
            viewProperty: null,
            editProperty: null,
            ruleSet: VesselNotificationCategoryCodes.Voyage
        },
        1: {
            viewPermission: VesselNotificationPermissions.View.workers,
            editPermission: VesselNotificationPermissions.Edit.workers,
            viewProperty: 'viewWorkers',
            editProperty: 'editWorkers',
            ruleSet: VesselNotificationCategoryCodes.Workers
        },
        2: {
            viewPermission: VesselNotificationPermissions.View.passengers,
            editPermission: VesselNotificationPermissions.Edit.passengers,
            viewProperty: 'viewPassengers',
            editProperty: 'editPassengers',
            ruleSet: VesselNotificationCategoryCodes.Passengers
        },
        3: {
            viewPermission: VesselNotificationPermissions.View.wastes,
            editPermission: VesselNotificationPermissions.Edit.wastes,
            viewProperty: 'viewWastes',
            editProperty: 'editWastes',
            ruleSet: VesselNotificationCategoryCodes.Wastes
        },
        4: {
            viewPermission: VesselNotificationPermissions.View.mdh,
            editPermission: VesselNotificationPermissions.Edit.mdh,
            viewProperty: 'viewMdh',
            editProperty: 'editMdh',
            ruleSet: VesselNotificationCategoryCodes.Mdh
        },
        5: {
            viewPermission: VesselNotificationPermissions.View.attachments,
            editPermission: VesselNotificationPermissions.Edit.attachments,
            viewProperty: 'viewAttachments',
            editProperty: 'editAttachments',
            ruleSet: null
        },
        6: {
            viewPermission: VesselNotificationPermissions.View.logs,
            editPermission: VesselNotificationPermissions.Edit.logs,
            viewProperty: 'viewLogs',
            editProperty: 'editLogs',
            ruleSet: null
        },
        7: { // Print
            viewPermission: VesselNotificationPermissions,
            editPermission: VesselNotificationPermissions,
            viewProperty: null,
            editProperty: null,
            ruleSet: null
        }
    };

    currentStep = 0;
    selectedTabIndex = 0;
    numberOfTabs = Object.keys(VesselShortNotificationViewContentComponent.tabPermissions).length;
    activeTab: string;
    finalStepIndex = this.numberOfTabs - 3; // V.N. completed at attachments
    confirmed: boolean;
    validating;
    additionalAgents: string[] = [];
    selectedVessel;
    searchData = [];
    actionBarStatus: any;

    searchFilterChanged = new Subject<any>();
    vesselSelectSortFields = [
        { columnName: 'IMO', field: 'iMONumber', sort: null },
        { columnName: 'MMSI', field: 'mmsi', sort: null },
        { columnName: 'Vessel Name', field: 'name', sort: false },
        { columnName: 'Flag', field: 'countryId', sort: null },
    ];

    @ViewChild('autocomplete', { static: false })
    public autocomplete: AutoCompleteComponent;

    get editMode() {
        return this.mode !== ViewMode.view;
    }

    get viewMode() {
        return this.mode === ViewMode.view;
    }

    get isCreate() {
        return this.mode === ViewMode.create;
    }

    @Input() model: any;
    @Input() modelId: number;
    @Input() entityManager: EntityManager;
    @Input() user: User;
    @Input() mode: ViewMode;
    @Input() canEdit: boolean;
    @Input() enableSteps: boolean;
    @Input() initialSelectedTabIndex: number;
    @Input() showHeader: boolean;

    @Output() confirm = new EventEmitter();
    @Output() tabSelected = new EventEmitter();

    @ViewChild('tabs', { static: false }) tabs: TabStripComponent;
    @ViewChild('additionalAgentsControl', { static: false }) additionalAgentsControl: AppControlComponent;

    constructor(
        private translateService: TranslateService,
        private breezeViewService: BreezeViewService,
        private dialogService: DialogService,
        private router: Router,
        private injector: Injector,
        private sharedService: SharedService) {
        this.searchFilterChanged
            .pipe(debounceTime(500))
            .subscribe(filter => {
                this.searchData = [];
                this.autocomplete.loading = true;
                this.breezeViewService.handleQuery('VesselsList', { filter })
                    .then(results => {
                        _.each(results, (val, i) => val.index = i.toString());
                        this.searchData = results;
                        this.sortVesselSelect('name');
                    })
                    .finally(() => this.autocomplete.loading = false);
            });

        this.sharedService.on('action-bar-toggle', data => this.actionBarStatus = data);
    }
    async ngOnInit() {
        // If rendered in vessel visit view
        if (!this.model) [this.model] = await this.breezeViewService.handleQuery('VesselNotification', { id: this.modelId });
        this.modelLoaded();
        if (!this.enableSteps) {
            this.currentStep = null;
            this.selectedTabIndex = this.initialSelectedTabIndex && this.isVisible(this.initialSelectedTabIndex) ? this.initialSelectedTabIndex : 0;
        }
    }

    modelLoaded() {
        this.additionalAgents = _.map(this.model.additionalAgents, o => o.agentId);
        if (!this.model.agent && this.model.agentId)
            this.model.entityAspect.loadNavigationProperty('agent');

        if (this.editMode) {
            const entityAspect = this.model.entityAspect;
            entityAspect.propertyChanged.subscribe((event) => {
                if (event.propertyName === 'primaryCallPurposeId') entityAspect.validateEntity();
            });
        }

        if (this.isCreate) {
            this.model.isShort = true;
            this.model.preArrival = false;
        }
    }

    async onTabSelect(event: SelectEvent) {
        if (this.validating) {
            event.preventDefault();
            return;
        }

        // Reached final tab
        if (this.finalStepIndex === this.currentStep) {
            // Clicked on the confirm TAB
            if (this.confirmed) {
                event.preventDefault();

                const res = await this.dialogService.openConfirmDialog('Save Vessel Notification',
                    this.translateService.instant('By pressing on Confirm the vessel notification will be saved.')
                );
                if (!res) {
                    this.confirmed = false;
                    return;
                }

                this.confirm.emit();
            }

            return;
        }

        if (this.canGoNext() && this.tabs.tabs.length === (event.index + 1)) {
            event.preventDefault();

            if (!await this.validate()) return;

            this.currentStep++;
            while (this.currentStep < this.finalStepIndex && !this.isVisible(this.currentStep) && await this.validate()) this.currentStep++;

            this.selectedTabIndex++;
        }

        this.selectedTabIndex = event.index;
        this.tabSelected.emit(this.selectedTabIndex);
    }

    async validate() {
        const ruleSet = VesselShortNotificationViewContentComponent.tabPermissions[this.currentStep].ruleSet;
        if (!ruleSet) return true;

        this.validating = true;
        try {
            await this.breezeViewService.saveChanges('ValidateVesselNotification', this.model, { ruleSet });
            return true;
        } catch { return false; }
        finally { this.validating = false; }
    }

    getStepIndex(tabIndex) {
        let stepIndex = -1;
        for (let i = 0; i <= this.numberOfTabs - 1; i++) {
            stepIndex++;

            if (stepIndex === tabIndex) return i;
        }

        return this.isCreate ? this.finalStepIndex : 0;
    }

    isSelected(index) {
        return this.getStepIndex(this.selectedTabIndex) === index;
    }

    isEditable(index) {
        if (!this.canEdit) return false;

        const organizationId = this.user?.organizationId;
        const tabPermission = VesselShortNotificationViewContentComponent.tabPermissions[index];
        const permission = tabPermission.editPermission;
        const permissionProperty = tabPermission.editProperty;
        const vnPermission = _.find(this.model.permissions, o => o.organizationId === organizationId);
        if (vnPermission && permissionProperty) return vnPermission[permissionProperty];

        if (!this.user?.hasPermission(permission)) return false;

        return !this.user?.isAgent() || // Malta transport can edit all
            this.model.agentId === organizationId; // Primary agent can edit all
    }

    isVisible(index) {
        // Prevent Print from breaking
        if (index > this.numberOfTabs - 1) return false;

        if (index === 0 && this.currentStep === null /* Voyage */)
            return true;

        if (index === 3 /*Waste*/ && (this.model.vessel.emsaExemption || this.model.portOfCallId === 'MTWTW'))
            return false;

        if (index === 4 /*Health*/ && this.model.notificationTypeId !== 'A')
            return false;

        if (index === 6 /*Log*/ && this.editMode)
            return false;

        if (index === 7 /*Print*/)
            return this.mode !== ViewMode.create;

        const organizationId = this.user?.organizationId;
        const tabPermission = VesselShortNotificationViewContentComponent.tabPermissions[index];
        const permission = this.editMode ? tabPermission.editPermission : tabPermission.viewPermission;
        const permissionProperty = this.editMode ? tabPermission.editProperty : tabPermission.viewProperty;
        const vnPermission = _.find(this.model.permissions, o => o.organizationId === organizationId);
        if (vnPermission && permissionProperty) return vnPermission[permissionProperty];

        if (!this.user?.hasPermission(permission)) return false;

        if (this.currentStep === null) {
            return !this.user?.isAgent() || // Malta transport can view all
                this.model.agentId === organizationId; // Primary agent can view all
        }

        return index <= this.currentStep;
    }

    getTabClass(index) {
        return this.isSelected(index) ? 'text-warning' : 'text-success';
    }

    getDangerTabClass(index) {
        return this.isSelected(index) ? 'text-warning' : 'text-danger';
    }

    canGoNext() {
        if (this.mode !== ViewMode.create || this.getStepIndex(this.selectedTabIndex) !== this.currentStep) return false;

        return this.model.vessel != null;
    }

    onDetailsTabSelected(index) {
        if (!this.isVisible(index)) return;

        let tabIndex = 0;
        for (let i = 0; i < index; i++) {
            if (this.isVisible(i)) tabIndex++;
        }

        this.tabs.selectTab(tabIndex);
    }

    onAgentChange(value) {
        if (!value) return;

        this.model.agentId = value;
        this.model.entityAspect.loadNavigationProperty('agent');
        this.additionalAgentsControl?.applyFilter();
    }

    onAdditionalAgentsChange(ids) {
        const modelIds = _.map(this.model.additionalAgents.filter(x => x.agentRoleId === 'VA'), (o, i) => o.agentId);
        // Add
        _.each(_.difference(ids, modelIds), id => {
            this.model.additionalAgents.push(this.model.entityAspect.entityManager
                .createEntity('VesselNotificationAgent', {
                    agentId: id,
                    vesselNotificationId: this.model.id,
                    agentRoleId: 'VA'
                }));
        });
        // Remove
        _.each(_.difference(modelIds, ids), id => {
            const entity = _.find(this.model.additionalAgents, o => o.agentId === id && o.agentRoleId === 'VA');
            entity.entityAspect.setDeleted();
        });
    }

    excludePrimaryAgent(item) {
        return item.id !== this.model?.agentId;
    }

    onStepModalClosed(event: boolean, tab) {
        // Go to next tab
        if (!event) this.onTabSelect(new SelectEvent(this.selectedTabIndex + 1, ''));
    }

    canOpenCargoModal() {
        // for cargo if Primary Call purpose = 1 and call = LOA; Only relevant for Create and Edit
        return this.model.primaryCallPurposeId === 1 &&
            this.model.callActivityId === 'LOA' &&
            this.editMode;
    }

    canOpenHazmatModal() {
        return this.canOpenModal();
    }

    canOpenModal() {
        // For hazmat and bunkers
        return [1, 2, 3, 4, 5, 6].includes(this.model.primaryCallPurposeId) &&
            ['MTMAR', 'MTMLA', 'MTWTW'].includes(this.model.portOfCallId) &&
            this.editMode;
    }

    isTabVisible = (index) => this.isVisible(index);

    canImportIHSVessel() {
        return (this.user.hasPermission(VesselPermissions.Action.ihsImport) || this.user.hasPermission(VesselPermissions.Action.ihsImportAgent)) &&
            this.isCreate;
    }

    async importIHSVessel() {
        const result = await this.dialogService.open(ImportIhsVesselModalComponent, {
            injector: this.injector,
            size: 'xl'
        });
        if (result) {
            this.model.vesselId = result;
            this.model.entityAspect.loadNavigationProperty('vessel');
            this.model.notificationTypeId = 'A';
            this.model.entityAspect.validateEntity();
        }
    }

    canImportEMSAVessel() {
        return (this.user.hasPermission(VesselPermissions.Action.emsaImport) || this.user.hasPermission(VesselPermissions.Action.emsaImportAgent)) &&
            this.isCreate;
    }

    async importEMSAVessel() { }

    searchValueChange(value) {
        this.autocomplete.reset();
        const index = parseInt(value, null);
        if (isNaN(index)) return;

        this.selectedVessel = this.searchData[index];

        if (!this.selectedVessel) return;
    }

    searchFilterChange(filter) {
        if (filter.length >= 2) {
            this.searchFilterChanged.next(filter);
        } else {
            this.autocomplete.toggle(false);
            if (this.searchData.length) this.searchData = [];
        }
    }

    createArrival() {
        this.model.vessel = this.selectedVessel.vessel;
        this.model.notificationTypeId = 'A';
        this.model.entityAspect.validateEntity();
    }

    sortVesselSelect(field: string) {
        if (_.find(this.vesselSelectSortFields, vesselSort => vesselSort.field === field).sort) {
            this.searchData = this.searchData.sort((a, b) => (a.vessel[field] < b.vessel[field] ? 1 : -1));
            this.modifyVesselSelectSort(false, field);
        } else {
            this.searchData = this.searchData.sort((a, b) => (a.vessel[field] < b.vessel[field] ? -1 : 1));
            this.modifyVesselSelectSort(true, field);
        }
    }

    modifyVesselSelectSort(order: boolean, field: string) {
        _.forEach(this.vesselSelectSortFields, vesselSort => vesselSort.sort = vesselSort.field === field ? order : null);
    }

    currentlyOn(location): boolean {
        return this.router.url.split('/').indexOf(location) !== -1;
    }

    isDarkMode() {
        return JSON.parse(localStorage.getItem('dark'));
    }

    isDetained() {
        return this.model.vessel.statusId === 'DT';
    }

    isAlerted() {
        return this.model.vessel.statusId === 'BN';
    }
}
