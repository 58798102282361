import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { SortDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-security',
    templateUrl: './vessel-notification-security.component.html',
    styleUrls: ['./vessel-notification-security.component.scss']
})
export class VesselNotificationSecurityComponent implements OnInit {
    vesselActivitySort: SortDescriptor[];
    previousPortSort: SortDescriptor[];

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    previousPortOptions: EntityFormOptions = {
        entityName: 'VesselNotificationPreviousPort',
        canEdit: () => this.canEdit && !this.user?.isSecurity(),
        beforeAdd: (model) => {
            const copy = this.model.previousPorts.slice();
            copy.push(model);
            this.updateSequenceNumber(copy, p => p.arrivalDate, p => p.departureDate);
        },
        onUpdate: () => this.updateSequenceNumber(this.model.previousPorts, p => p.arrivalDate, p => p.departureDate),
        onRemove: () => this.updateSequenceNumber(this.model.previousPorts, p => p.arrivalDate, p => p.departureDate),
        onAdd: () => this.updateSequenceNumber(this.model.previousPorts, p => p.arrivalDate, p => p.departureDate),
        propertyGroups: [
            [
                { name: 'portId', label: 'Port', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'portFacility', label: 'Port Facility', maxlength: 4, pattern: '[0-9]' },
                { name: 'arrivalDate', label: 'Arrival (ATA)', type: AppControlType.DateTime },
                { name: 'departureDate', label: 'Departure (ATD)', type: AppControlType.DateTime },
                { name: 'securityLevelId', label: 'Security Level', type: AppControlType.CodeList, codelist: 'SecurityLevel', colSize: 3 }
            ],
            [
                { name: 'securityMeasures', label: 'Security Measures', type: AppControlType.TextArea, maxlength: 100 }
            ]
        ]
    };

    vesselActivityOptions: EntityFormOptions = {
        entityName: 'VesselNotificationVesselActivity',
        canEdit: () => this.canEdit && !this.user?.isSecurity(),
        beforeAdd: (model) => {
            const copy = this.model.vesselActivities.slice();
            copy.push(model);
            this.updateSequenceNumber(copy, p => p.startDate, p => p.endDate);
        },
        onUpdate: () => this.updateSequenceNumber(this.model.vesselActivities, p => p.startDate, p => p.endDate),
        onRemove: () => this.updateSequenceNumber(this.model.vesselActivities, p => p.startDate, p => p.endDate),
        propertyGroups: [
            [
                { name: 'startDate', label: 'Date From', type: AppControlType.DateTime },
                { name: 'endDate', label: 'Date To', type: AppControlType.DateTime },
                { name: 'locationId', label: 'Location', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'vesselActivityId', label: 'Ship to Ship Activity', type: AppControlType.CodeList, codelist: 'VesselActivity' },
            ],
            [
                { name: 'latitude', label: 'Latitude', maxlength: 50, colSize: 4 },
                { name: 'longitude', label: 'Longitude', maxlength: 50, colSize: 4 }
            ],
            [
                { name: 'securityMeasures', label: 'Security Measures', type: AppControlType.TextArea, maxlength: 100, colSize: 6 }
            ]
        ]
    };

    constructor(private changeDetectorRef: ChangeDetectorRef) {
        this.vesselActivitySort = this.previousPortSort = [{ field: 'sequenceNumber', dir: 'desc' }];
    }

    ngOnInit() {
        this.updateSequenceNumber(this.model.previousPorts, p => p.arrivalDate, p => p.departureDate);
    }

    private updateSequenceNumber(items, sortFirst, sortSecond) {
        const sortedSecond = _.sortBy(items, sortSecond);
        const sortedFirst = _.sortBy(sortedSecond, sortFirst);
        _.each(sortedFirst, (p: any, i) => p.sequenceNumber = i + 1);
        this.changeDetectorRef.detectChanges();
    }

    isDisabled() {
        return !this.canEdit || !this.editMode || this.user?.isSecurity();
    }
}
