<app-loader [isBusy]="isBusy">
    <ng-template>
        <div class="modal-header">
            <h4 class="modal-title" translate>Cargo Agent Data Confirmation table </h4>
            <button type="button" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <app-grid [data]="query">
                <kendo-grid-column title="{{'Agent' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <div [ngClass]="{'shippingAgent': row.shippingAgent}">
                            {{row.agentId | codelist:'Organization' | async}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Manifests' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <div [ngClass]="{'shippingAgent': row.shippingAgent}">
                            {{row.manifests}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Completed' | translate}}">
                    <ng-template kendoGridCellTemplate let-row>
                        <div [ngClass]="{'shippingAgent': row.shippingAgent}">
                            {{row.completed}}
                        </div>
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="close()" translate>Close</button>
        </div>
    </ng-template>
</app-loader>