import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { environment } from 'environments/environment';
import _ from 'lodash';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-user-notification-list',
    templateUrl: './user-notification-list.component.html',
    styleUrls: ['./user-notification-list.component.scss'],
    providers: [BreezeViewService]
})
export class UserNotificationListComponent {
    @Input() showHeader = true;
    @Input() onlyImportant = false;

    notifications = [];

    breadcrumb = [
        {
        icon: 'file',
        title: 'Notice board'
    }
];

    constructor(private http: HttpClient, private breezeViewService: BreezeViewService) {
        this.breezeViewService.handleQuery('UserNotifications')
            .then((notifications) => this.notifications = this.onlyImportant ? _.filter(notifications, n => n.important) : notifications);
    }

    async download(event, id) {
        event.preventDefault();
        const attachment: any = await firstValueFrom(this.http.get(`${environment.settings.appControl.fileDownloadUrl}?id=${id}`));
        UploadActionButtonsComponent.download(attachment.name, attachment.content);
    }
}
