import { AfterViewInit, ApplicationRef, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { GridEditService } from '@common/services/grid-edit.service';
import { environment } from '@environments/environment';
import { AddEvent, DetailTemplateDirective, EditEvent, GridComponent, GridDataResult, RemoveEvent, SaveEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import _ from 'lodash';
import { AppGridComponent } from '../app-grid/app-grid.component';
import { ExportService } from '@common/services/export.service';

@Component({
    selector: 'app-editable-grid',
    templateUrl: './editable-grid.component.html',
    styleUrls: ['./editable-grid.component.scss'],
    providers: [GridEditService],
    encapsulation: ViewEncapsulation.None
})
export class EditableGridComponent extends AppGridComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
    @Input() entityName: string;
    @Input() editMode: boolean;
    @Input() canAddNew = true;
    @Input() canDelete = true;
    @Input() canEdit = true;
    @Input() canEditRow: (row: any) => boolean = () => true;
    @Input() canExport = false;
    @Input() pageSize;
    @Input() initialData = {};
    @Input() sort = [...environment.settings.grid.sort] as SortDescriptor[];
    @Input() rowClass = () => _.noop();

    @Output() rowAction = new EventEmitter();
    @Output() save = new EventEmitter<SaveEvent>();
    @Output() remove = new EventEmitter<RemoveEvent>();
    @Output() export = new EventEmitter<any>();

    expandedDetailKeys: any[] = [];

    constructor(changeDetectorRef: ChangeDetectorRef,
        breezeViewService: BreezeViewService,
        renderer: Renderer2,
        applicationRef: ApplicationRef,
        zone: NgZone,
        public gridEdit: GridEditService,
        exportService: ExportService) {
        super(changeDetectorRef, breezeViewService, renderer, applicationRef, zone, exportService);
    }

    public get detailTemplate(): DetailTemplateDirective {
        return this.detailTemplateChildren?.first || undefined;
    }

    public expandDetailsBy = (dataItem: any): any => dataItem.id;

    ngOnInit() {
        this.gridEdit.entityManager = this.entityManager;
        super.ngOnInit();
    }

    ngAfterViewInit() {
        this.grid.detailTemplate = this.detailTemplate;
        this.updateColumns();
    }

    updateColumns() {
        const columns = this.columns?.toArray() || [];
        if (this.editMode) {
            this.commandColumns.forEach(commandColumn => columns.push(commandColumn));
        }
        this.grid.columns.reset(columns);
        this.changeDetectorRef.detectChanges();
    }

    expandAllDetails() {
        this.expandItems((this.grid.data as GridDataResult).data.map(({ id }) => id));
    }

    collapseAllDetails() {
        this.expandedDetailKeys = [];
    }

    exportToExcel(grid: GridComponent): void {
        grid.saveAsExcel();
    }

    onAdd(event: AddEvent) {
        const id = this.gridEdit.addHandler(event, this.data, this.entityName, this.initialData);
        this.rowAction.emit();
        this.expandItems([id]);
    }

    onEdit(event: EditEvent) {
        this.gridEdit.editHandler(event, this.entityName);
        this.rowAction.emit();
        this.expandItems([event.dataItem.id]);
    }

    onSave(event: SaveEvent) {
        this.rowAction.emit();
        this.gridEdit.saveHandler(event);
        this.save.emit(event);
        this.collapseItem(event.dataItem.id);
    }

    onRemove(event: RemoveEvent) {
        this.gridEdit.removeHandler(event);
        this.rowAction.emit();
        this.remove.emit(event);
        this.collapseItem(event.dataItem.id);
    }

    onCancel(event) {
        this.gridEdit.cancelHandler(event);
        this.rowAction.emit();
        this.collapseItem(event.dataItem.id);
    }

    expandItems(ids: number[]) {
        ids.forEach(id => this.expandedDetailKeys.push(id));
    }

    collapseItem(id: number) {
        const data = this.grid.data as GridDataResult;
        this.expandedDetailKeys.splice(data.data.findIndex(d => d.id === id), 1);
    }

    onExcelExport(args: any): void {
        args.preventDefault();
        if (this.entityName === 'Manifest') this.export.emit(args);
        else this.transformExcelExport(args);
    }
}
