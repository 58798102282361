<app-action-bar [items]="actionBar"></app-action-bar>
<app-content-header title='Vessel Permits' [breadcrumb]="breadcrumb"></app-content-header>
<app-filter [hasMetaFilter]="true" (search)="onAutoFilterChange()" (clearFilter)="clearFilter()">
    <div class="row" meta-filter>
        <div class="col">
            <app-control label='Permit Number' type="number" format="'#'" [min]="1"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.number" [entity]="filter" property="number"></app-control>
        </div>
        <div class="col">
            <app-control label='Permit Type' type="codelist" codelist="VesselPermitType"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.typeId" [entity]="filter" property="typeId"></app-control>
        </div>
        <div class="col">
            <app-control label='Permit Status' type="codelist" codelist="VesselPermitStatus"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
        </div>
        <div class="col">
            <app-control label='Issued Date From' type="datetime" [time]="true"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.issuedDateFrom" [entity]="filter" property="issuedDateFrom"></app-control>
        </div>
        <div class="col">
            <app-control label='Issued Date To' type="datetime" [time]="true"
                (ngModelChange)="onAutoFilterChange($event)" [(ngModel)]="filter.issuedDateTo" [entity]="filter" property="issuedDateTo"></app-control>
        </div>
    </div>
    <div class="row" all-filter>
        <div class="col-2">
            <app-control label='Permit Type' type="codelist" codelist="VesselPermitType"
                [(ngModel)]="filter.typeId" [entity]="filter" property="typeId"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Permit Status' type="codelist" codelist="VesselPermitStatus"
                [(ngModel)]="filter.statusId" [entity]="filter" property="statusId"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Issued Date From' type="datetime" [time]="true"
                [(ngModel)]="filter.issuedDateFrom" [entity]="filter" property="issuedDateFrom"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Issued Date To' type="datetime" [time]="true"
                [(ngModel)]="filter.issuedDateTo" [entity]="filter" property="issuedDateTo"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Validity of Permit From' type="datetime" [time]="true"
                [(ngModel)]="filter.validFrom" [entity]="filter" property="validFrom"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Validity of Permit To' type="datetime" [time]="true"
                [(ngModel)]="filter.validTo" [entity]="filter" property="validTo"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Permit Number' type="number" format="'#'" [min]="1"
                [(ngModel)]="filter.number" [entity]="filter" property="number"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Vessel Name' type="codelist" codelist="Vessel"
                [(ngModel)]="filter.vesselId" [entity]="filter" property="vesselId"></app-control>
        </div>
        <div class="col-2">
            <app-control label='IMO Number' type="number" format="'#'" [maxlength]="7"
                [(ngModel)]="filter.imoNumber" [entity]="filter" property="imoNumber"></app-control>
        </div>
        <div class="col-2">
            <app-control label='MMSI Number' type="number" format="'#'" [maxlength]="9"
                [(ngModel)]="filter.mmsi" [entity]="filter" property="mmsi"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Location' type="codelist" codelist="Berth"
                [(ngModel)]="filter.berthId" [entity]="filter" property="berthId"></app-control>
        </div>
        <div class="col-2">
            <app-control label='Agent' type="codelist" codelist="Organization"
                [(ngModel)]="filter.requestingOrganizationId" [entity]="filter" property="requestingOrganizationId"></app-control>
        </div>
    </div>
</app-filter>
<app-list-results-content [nrOfActiveFilters]="nrOfActiveFilters">
    <kendo-tabstrip (tabSelect)="onTabSelect($event)">
        <kendo-tabstrip-tab [selected]="true" title="{{'Vessel Permits' | translate}}"></kendo-tabstrip-tab>
        <kendo-tabstrip-tab title="{{'Archived Vessel Permits' | translate}}"></kendo-tabstrip-tab>
    </kendo-tabstrip>
    <app-vessel-permit-grid [query]="query" [selection]="selection"></app-vessel-permit-grid>
</app-list-results-content>
