import { ControlSize } from '@common/classes/control-size';
import { ViewMode } from '@common/models/view-mode';
import { PopupSettings } from '@progress/kendo-angular-dropdowns';

export const serverUrl = `${window.location.protocol}//${window.location.hostname}`;
export const environment = {
    production: false,
    name: null,
    apiUrl: null,
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    title: 'NMSW Malta',
    version: '0.1.0',
    excelTemplateFile: 'VesselNotification_Excel_Template_1.3.1.xlsx',
    shortExcelTemplateFile: 'ShortVesselNotification_Excel_Template_1.3.xlsx',
    vtsVesselTemplateFile: 'ServiceVessels_Excel_Template_1.0.xlsx',
    authorisedMastersBBTemplateFile: 'Bunker_Barges_Authorised_Masters_Template_1.0.xlsx',
    authorisedMastersHSCTemplateFile: 'HSC_Authorised_Masters_Template_1.0.xlsx',
    settings: {
        appControl: {
            format: 'n2',
            size: ControlSize.normal,
            multi: false,
            time: false,
            codelist: {
                take: 100
            },
            fileDownloadUrl: 'api:///query/DownloadAttachment',
            fileMaxSize: 20_971_520, // 20MB
            fileAllowedExtensions: [
                '.jpg', '.jpeg', '.png', '.bmp', '.tif', // Images
                '.doc', '.docx', '.xls', '.xlsx', '.xlsm', '.txt', '.pdf', 'xslt' // Documents
            ],
            dropdown: {
                popupSettings: undefined as PopupSettings
            }
        },
        grid: {
            pageable: { type: 'numeric', info: true, buttonCount: 4, pageSizes: [10, 20, 50] },
            pageSize: 20,
            pageSizes: [10, 20, 50],
            sortable: false,
            sort: []
        },
        view: {
            defaultViewMode: ViewMode.view,
            save: {
                redirectToViewMode: ViewMode.view
            }
        },
        list: {
            persistFilter: true
        },
        validation: {
            errors: {
                showTooltip: false,
                showFeedback: false
            }
        }
    }
};
