<ngb-accordion activeIds="accompanied-info">
    <ngb-panel id="accompanied-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>{{title}}</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <ngb-accordion activeIds="vehicle-types-totals" *ngIf="!editMode && accompaniedGrouped?.length > 0">
                <ngb-panel id="vehicle-types-totals">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Vehicle Type Totals</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <kendo-grid [data]="accompaniedGrouped">
                            <kendo-grid-column title="{{'Vehicle Type' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.vehicleTypeId | codelist:'VehicleType' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity" format="n2"></kendo-grid-column>
                            <kendo-grid-column title="{{'Weight' | translate}}" field="weight" format="n2"></kendo-grid-column>
                            <kendo-grid-column title="{{'Length' | translate}}" field="length" format="n2"></kendo-grid-column>
                        </kendo-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="row mt-3 mb-3">
                <div class="col">
                    <h3 translate>Accompanied Vehicle List</h3>
                </div>
            </div>
            <app-entity-form [options]="accompaniedFormOptions" [grid]="accompaniedVehicleGrid"></app-entity-form>
            <app-grid #accompaniedVehicleGrid [data]="model.accompaniedVehicles">
                <kendo-grid-column title="{{'Vehicle Type' | translate}}" field="vehicleTypeId">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.vehicleTypeId | codelist:'VehicleType' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Vehicle Make' | translate}}" field="vehicleMake"></kendo-grid-column>
                <kendo-grid-column title="{{'Vehicle Model' | translate}}" field="vehicleBrand"></kendo-grid-column>
                <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity" format="n2"></kendo-grid-column>
                <kendo-grid-column title="{{'Weight' | translate}}" field="weightKgs" format="n2"></kendo-grid-column>
                <kendo-grid-column title="{{'UoM' | translate}}" field="unitOfMeasureId"></kendo-grid-column>
                <kendo-grid-column title="{{'Length' | translate}}" field="length" format="n2"></kendo-grid-column>
                <kendo-grid-column title="{{'Width' | translate}}" field="width" format="n2"></kendo-grid-column>
                <kendo-grid-column title="{{'Reg. No.' | translate}}" field="vehicleNoPlate"></kendo-grid-column>
                <kendo-grid-column title="{{'Cargo Description' | translate}}" field="cargoDescription"></kendo-grid-column>
                <kendo-grid-column title="{{'Driver Name' | translate}}" field="driverName"></kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="accompanied-info">
    <ngb-panel id="accompanied-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Supporting Documents</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div *ngIf="tabs">
                <app-vessel-notification-accompanied-grid [accompanied]="model.accompanied" [canEdit]="canEdit"
                    [editMode]="false" [tabs]="tabs" [user]="user"></app-vessel-notification-accompanied-grid>
            </div>
            <div *ngIf="!tabs">
                <app-vessel-notification-accompanied-grid [accompanied]="model.accompanied" [canEdit]="canEdit"
                    [editMode]="editMode" [tabs]="tabs" [user]="user"></app-vessel-notification-accompanied-grid>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
