<ngb-accordion activeIds="permision-info">
    <ngb-panel id="permision-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Permissions</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <app-entity-form [options]="formOptions" [grid]="permissionGrid"></app-entity-form>
            <hr class="my-3"/>
            <h3 translate>View Permissions</h3>
            <app-grid #permissionGrid [data]="model.permissions">
                <kendo-grid-column title="{{'Organization' | translate}}" [width]="100">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.organizationId | codelist:'Organization' | async}}
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View DG' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewDangerousGoods"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Bunkers' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewBunkers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Cargo' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewCargo"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Product Store' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewStoreProducts"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Crew' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewWorkers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Passengers' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewPassengers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Security' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewSecurity"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Wastes' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewWastes"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Health' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewMdh"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Itineraries' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewCruiseItineraries"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Logs' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewLogs"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Attachments' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewAttachments"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Accompanied' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewAccompanied"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'View Manifests' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.viewManifests"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Manifests' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editManifests"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit DG' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editDangerousGoods"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Bunkers' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editBunkers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Cargo' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editCargo"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Product Store' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editStoreProducts"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Crew' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editWorkers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Passengers' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editPassengers"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Security' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editSecurity"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Wastes' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editWastes"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Health' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editMdh"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column title="{{'Edit Itineraries' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.editCruiseItineraries"></app-checkbox>
                    </ng-template>
                </kendo-grid-column>
                <!-- <kendo-grid-column title="{{'Create Departure' | translate}}" [width]="100">
                    <ng-template let-row kendoGridCellTemplate>
                        <app-checkbox [disabled]="true" [(ngModel)]="row.createDeparture"></app-checkbox>
                    </ng-template>
                </kendo-grid-column> -->
                <kendo-grid-column title="{{'Created Date' | translate}}" field="createdDate" format="g" [width]="100"></kendo-grid-column>
                <kendo-grid-column title="{{'Created By' | translate}}" [width]="150">
                    <ng-template kendoGridCellTemplate let-row>
                        {{row.createdById | codelist:'User' | async}}
                    </ng-template>
                </kendo-grid-column>
            </app-grid>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
