import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'getVessel',
    pure: true
})
export class GetVesselPipe implements PipeTransform {

    transform(item: any, args?: any): any {
        return item?.vessel || item?.vtsVessel || item?.serviceVessel || item?.serviceVtsVessel || item?.vesselNotification?.vessel || item?.vtsVesselVisit?.vessel || item?.vtsVesselVisit?.vtsVessel;
    }
}
