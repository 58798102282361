<app-grid [data]="query" [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="[{ field: 'id', dir: 'asc' }]">
    <kendo-grid-checkbox-column [width]="40" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column title="{{'Permit Number' | translate}}" field="id" [width]="160">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-permit/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselPermit' }">{{row.referenceNumber}}</div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vessel.name">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vessel.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vessel.statusId, true)}}" placement="right">
                <fa-icon icon="exclamation" class="redIcon"></fa-icon>
            </div>
            <a [routerLink]="['/vessels/view', row.vesselId]" class="text-primary" target="_blank">
                {{row.vesselId | codelist:'Vessel' | async}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Permit Type' | translate}}" field="type.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.typeId | codelist:'VesselPermitTypeRevision' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Status' | translate}}" field="status.name" [width]="145">
        <ng-template kendoGridCellTemplate let-row>
            <div [appPill]="{ status: row.statusId, module: 'vesselPermit', muted: true }">
                {{row.statusId | codelist:'VesselPermitStatus' | async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="vesselNotification.vesselVisit.number" title="{{'Vessel Visit' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <a class="text-primary" [routerLink]="['/vessel-visit/view', row.vesselNotification?.vesselVisitId]" target="_blank">
                {{row.vesselNotification?.vesselVisit?.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Location' | translate}}" field="berth.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthId | codelist:'Berth' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Created Date' | translate}}" field="createdDate" format="g"></kendo-grid-column>
    <kendo-grid-column title="{{'Approved Date' | translate}}" field="approvalDate" format="g"></kendo-grid-column>
    <kendo-grid-column field="validFromDate" title="{{'Validity from' | translate}}" format="g"></kendo-grid-column>
    <kendo-grid-column field="validToDate" title="{{'Validity to' | translate}}" format="g"></kendo-grid-column>
    <kendo-grid-column title="{{'Agent' | translate}}" field="requestingOrganization.name"></kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'TM' | translate}}" [width]="40">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="" moduleType="VesselPermit"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>