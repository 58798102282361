<app-grid [data]="errors">
    <kendo-grid-column title="{{'Entity' | translate}}" headerClass="bg-danger text-white">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{row.entity?.entityType?.shortName | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Property' | translate}}" field="propertyName" headerClass="bg-danger text-white">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{row.propertyName | translate}}</span>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{'Error' | translate}}" field="errorMessage" headerClass="bg-danger text-white">
    </kendo-grid-column>
</app-grid>
