import { AfterViewInit, Component, ComponentRef, Injector, OnDestroy, OnInit, StaticProvider, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { AppControlType } from '../app-control/app-control.component';

export interface DialogFormOptions {
    title: string;
    message?: string;
    model?: any;
    cancelText?: string;
    confirmText?: string;
    properties?: FormProperty[];
    canConfirm?: (model: any) => boolean;
    contentComponent?: Type<any>;
    contentComponentDataProviders?: StaticProvider[];
    onContentComponentCreated?: (component: any) => void;
}

export interface FormProperty {
    name: string;
    type?: AppControlType;
    label?: string;
    codelist?: string;
    initialValue?: any;
    time?: boolean;
    pattern?: string;
    prepend?: string;
    allowedExtensions?: string[];
    min?: number;
    max?: number;
    format?: string;
    filter?: (item, search) => boolean;
    isDisabled?: (model: any) => boolean;
}

@Component({
    selector: 'app-dialog-form',
    templateUrl: './dialog-form.component.html',
    styleUrls: ['./dialog-form.component.scss']
})
export class DialogFormComponent implements OnDestroy, AfterViewInit {
    private contentComponentRef: ComponentRef<any>;
    private injector: Injector;

    model;
    options: DialogFormOptions;
    message: string;
    confirmAction: Function;
    cancelText = 'Cancel';
    confirmText = 'Confirm';
    canConfirmPredicate: (model: any) => boolean;
    cancelAction: Function;

    @ViewChild('template', { static: false, read: ViewContainerRef }) templateRef: ViewContainerRef;

    constructor(public activeModal: NgbActiveModal) { }

    public initialize(options: DialogFormOptions, injector: Injector) {
        if (!options.properties && !options.contentComponent)
            throw new Error('properties or contentComponent option has to be set');

        this.options = options;
        this.model = this.options.model || {};
        this.message = this.options.message;
        this.injector = injector;
        this.confirmText = this.options.confirmText || this.confirmText;
        this.cancelText = this.options.cancelText || this.cancelText;
        this.canConfirmPredicate = this.options.canConfirm || this.canConfirm.bind(this);
    }

    confirm() {
        this.activeModal.close(this.model);
    }

    canConfirm(model: any) {
        return true;
    }

    cancel() {
        this.activeModal.close(null);
    }

    private loadComponent(component, dataProviders: StaticProvider[]) {
        dataProviders = (dataProviders || []).concat([{ provide: NgbActiveModal, useValue: this.activeModal }]);
        const injector = Injector.create({providers: dataProviders || [], parent: this.injector});
        this.contentComponentRef = this.templateRef.createComponent(component, {injector});

        if (this.contentComponentRef.instance.model) this.model = this.contentComponentRef.instance.model;
        else this.contentComponentRef.instance.model = this.model;

        if (_.isFunction(this.contentComponentRef.instance.confirm))
            this.confirmAction = this.contentComponentRef.instance.confirm.bind(this.contentComponentRef.instance);

        if (_.isFunction(this.contentComponentRef.instance.canConfirm))
            this.canConfirmPredicate = this.contentComponentRef.instance.canConfirm.bind(this.contentComponentRef.instance);

        if (_.isFunction(this.contentComponentRef.instance.cancel))
            this.cancelAction = this.contentComponentRef.instance.cancel.bind(this.contentComponentRef.instance);

        if (_.isFunction(this.options.onContentComponentCreated))
            this.options.onContentComponentCreated(this.contentComponentRef.instance);

        this.contentComponentRef.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.options.contentComponent)
            this.loadComponent(this.options.contentComponent, this.options.contentComponentDataProviders);

        this.confirmAction = this.confirmAction || this.confirm.bind(this);
        this.cancelAction = this.cancelAction || this.cancel.bind(this);
    }

    ngOnDestroy(): void {
        if (this.contentComponentRef) this.contentComponentRef.destroy();
    }

    getName(property: FormProperty) {
        return property.name;
    }
}
