import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { EntityValidator } from './entity-validator';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
    private fetchMetadataPromise: Promise<boolean>;

    constructor(
        private userService: UserService,
        private entityValidator: EntityValidator) {

        this.userService.isAuthenticatedSubject.pipe(filter(x => !x))
            .subscribe(value => this.fetchMetadataPromise = null);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.fetchMetadataPromise)
            this.fetchMetadataPromise = this.entityValidator.fetchUserAndMetadata(state);

        return this.fetchMetadataPromise;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canActivate(childRoute, state);
    }
}
