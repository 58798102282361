import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntityState, MergeStrategy } from '@cime/breeze-client';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { CodelistService } from '@common/services/codelist.service';
import { DialogService } from '@common/services/dialog.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-cargo',
    templateUrl: './vessel-notification-cargo.component.html',
    styleUrls: ['./vessel-notification-cargo.component.scss']
})
export class VesselNotificationCargoComponent extends AbstractBreezeListComponent implements AfterViewInit, OnInit {
    queryName = 'VesselNotificationCargo';
    persistFilters = false;
    data = [];
    units = [];
    cargoDataGrouped = [];
    cargoDataTransitGrouped = [];

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() canOpenModal: boolean;
    @Input() isCargoData: boolean = false;
    @Input() editMode: any;
    @Input() user: User;

    @Output() public modalClose = new EventEmitter<any>();

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationCargo', // dynamic
        getCollection: () => this.cargoCollection,
        canEdit: () => this.canEdit,
        beforeAdd: (data) => {
            const cargoCollection = this.cargoCollection;
            data.ownerId = 1; // TODO: Remove when update to new breeze
            data.sequenceNumber = cargoCollection.length > 0
                ? cargoCollection[cargoCollection.length - 1].sequenceNumber + 1
                : 1;
        },
        onAdd: () => this.model.entityAspect.validateEntity(),
        onRemove: (item) => {
            const cargoCollection = this.cargoCollection;
            this.model.entityAspect.validateEntity();
            if (item.sequenceNumber <= cargoCollection.length)
                this.updateSequenceNumber(cargoCollection);
        },
        propertyGroups: [
            [
                { name: 'portOfLoadingId', label: 'Port of Loading', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'portOfDischargeId', label: 'Port of Discharge', type: AppControlType.CodeList, codelist: 'Location' },
                {
                    name: 'manifestCargoTypeId', label: 'Cargo Type', type: AppControlType.CodeList, codelist: 'ManifestCargoType',
                    onChange: (model, value) => model.cargoUnitQuantity = !['1', '2', '9'].some(num => _.startsWith(model.manifestCargoTypeId, num)) ? 1 : null
                },
                {
                    name: 'cargoUnitQuantity', label: 'Number of Units', type: AppControlType.Number, min: 1,
                    isDisabled: (model) => ['1', '2', '9'].some(num => _.startsWith(model.manifestCargoTypeId, num))
                }
            ],
            [
                { name: 'transhipment', label: 'Transhipment', type: AppControlType.Boolean, isVisible: () => !this.isCargoData },
                { name: 'commodityId', label: 'Commodity Classification', type: AppControlType.CodeList, codelist: 'Commodity' },
                { name: 'billOfLading', label: 'Bill Of Lading', maxlength: 250 },
                { name: 'empty', label: 'Empty Unit', type: AppControlType.Boolean, initialValue: false },
            ],
            [
                { name: 'transportUnitNumber', label: 'Marks & Numbers', maxlength: 100 },
                { name: 'quantity', label: 'Number of Packages', type: AppControlType.Number, decimals: 2, isVisible: () => !this.isCargoData },
                { name: 'packagingTypeId', label: 'Type of Packages', type: AppControlType.CodeList, codelist: 'PackagingType', isVisible: () => !this.isCargoData },
                { name: 'grossWeight', label: 'Gross Mass', type: AppControlType.Number, decimals: 2 },
            ],
            [
                { name: 'description', label: 'Description of Goods', maxlength: 250, colSize: 6 },
                { name: 'unitOfMeasureId', label: 'Unit of Measurement', type: AppControlType.CodeList, codelist: 'UnitOfMeasure', colSize: 3 },
            ],
            [
                { name: 'consignee', label: 'Consignee', colSize: 6, isVisible: () => this.isCargoData },
                { name: 'notifyParty', label: 'Notify Party', colSize: 6, isVisible: () => this.isCargoData }
            ]
        ]
    };

    constructor(private dialogService: DialogService,
        private codelistService: CodelistService,
        breezeViewService: BreezeViewService) {
        super(breezeViewService);
    }

    get cargoCollection(): any[] {
        return this.isCargoData ? this.model.cargoData : this.model.cargo;
    }

    async initialize() {
        super.initialize();
        const units = await this.codelistService.getCodelist({ name: 'UnitOfMeasure', useCache: false });
        this.units = units.map(u => u.id);
        this.formOptions = { ...this.formOptions, entityName: this.isCargoData ? 'VesselNotificationCargoData' : 'VesselNotificationCargo' };
    }

    override ngAfterViewInit(): void {
        if (!this.canOpenModal) return;
        setTimeout(async () => {
            const result = await this.dialogService.yesNo('Cargo', this.translateService.instant('Does vessel have any cargo on board?'));
            this.modalClose.emit(result);
        });
    }

    override search() {
        const query = this.getQuery();
        this.entityManager.executeQuery(query).then(data => {
            this.data = data.results;
            this.cargoDataGrouped =  this.groupData(this.data);
            this.cargoDataTransitGrouped = this.groupData(this.data, true);
        });
        return query;
    }

    groupData(data: any[], transit = false): any[] {
        return [_.chain(data)
            .groupBy('unitOfMeasureId')
            .map((value, key) => ({
                key,
                value: _.sumBy(value.filter(x => this.model.notificationTypeId === 'A' ? (_.startsWith(x.portOfDischargeId, 'MT')) !== transit : (_.startsWith(x.portOfLoadingId, 'MT')) !== transit), 'grossWeight')
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {})
            .value()];
    }

    private updateSequenceNumber(items) {
        items.forEach((p: any, i) => p.sequenceNumber = i + 1);
    }

    override getDefaultFilter() {
        return {
            id: this.model?.id,
            isCargoData: this.isCargoData
        };
    }

    async copyCargoData() {
        const confirmed = await this.dialogService.openConfirmDialog(
            this.translateService.instant('Copy FAL2 Data'),
            this.translateService.instant('Existing Cargo Data will be overwritten. Are you sure you would like to proceed?')
        );
        if (!confirmed) return;

        // No idea why it doesn't work any other way (don't change)
        const len = this.model.cargoData.length;
        for (let i = 0; i < len; i++) this.model.cargoData[0].entityAspect.setDeleted();

        this.model.cargo.forEach(c => {
            const dto = this.breezeService.convertToDto(c);
            dto.sequenceNumber = null;
            const dtoPrimitive = {};
            Object.entries(dto).forEach(([key, value]) => typeof value !== 'object' && (dtoPrimitive[key] = dto[key]));
            const cargoDataRow = this.entityManager.createEntity('VesselNotificationCargoData', dtoPrimitive, EntityState.Added, MergeStrategy.OverwriteChanges);
            this.model.cargoData.push(cargoDataRow);
        });
    }
}
