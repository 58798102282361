<ngb-accordion *ngIf="model.vessel" activeIds="certificates">
    <ngb-panel id="certificates">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel" (panelChange)="disableAppControls()">
                <div *ngIf="model.notificationTypeId !== 'D'" style="display: flex">
                    <h5 style="display: inline-block; font-weight: 600;" translate>Vessel Certificates</h5>
                    <strong style="display: inline-block; padding-top: 4px; margin-left: 4px; font-weight: 900;">-  TICK THE CHECK-BOX TO CONFIRM IF CERTIFICATES ARE VALID</strong>
                </div>
                <div *ngIf="model.notificationTypeId === 'D'">
                    <h5 translate>Do you confirm that the certificates submitted on arrival are still valid for vessel's departure?</h5>
                </div>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-6" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Select Yes/No' type="yesno" [(ngModel)]="model.confirmSubmittedCertificatesOnArrival" [entity]="model" property="confirmSubmittedCertificatesOnArrival"></app-control>
                </div>
                <div class="col-6" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Comments' [(ngModel)]="model.confirmSubmittedCertificatesOnArrivalRemarks" [entity]="model" property="confirmSubmittedCertificatesOnArrivalRemarks"></app-control>
                </div>
            </div>
            <div class="row certificates-container" *ngIf="model.notificationTypeId === 'A' || (model.notificationTypeId === 'D' && model.confirmSubmittedCertificatesOnArrival !== null)">
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('certificateOfRegistry')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='C.O.R. Issued by the Flag Administration' [tooltipText]="getCertificateTooltip('certificateOfRegistry')"
                                [tooltipClass]="getTooltipClass('certificateOfRegistry')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.certificateOfRegistry" [entity]="model" property="certificateOfRegistry"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.certificateOfRegistryRemarks" [entity]="model" property="certificateOfRegistryRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('civilLiabilityCertificate')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Civil Liability Certificate (CLC)' [tooltipText]="getCertificateTooltip('civilLiabilityCertificate')"
                                [tooltipClass]="getTooltipClass('civilLiabilityCertificate')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.civilLiabilityCertificate" [entity]="model" property="civilLiabilityCertificate"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.civilLiabilityCertificateRemarks" [entity]="model" property="civilLiabilityCertificateRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('piInsurance')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='P&I Insurance' [tooltipText]="getCertificateTooltip('piInsurance')"
                                [tooltipClass]="getTooltipClass('piInsurance')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.piInsurance" [entity]="model" property="piInsurance"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.piInsuranceRemarks" [entity]="model" property="piInsuranceRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('hullMachinery')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Hull & Machinery' [tooltipText]="getCertificateTooltip('hullMachinery')"
                                [tooltipClass]="getTooltipClass('hullMachinery')"tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.hullMachinery" [entity]="model" property="hullMachinery"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.hullMachineryRemarks" [entity]="model" property="hullMachineryRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('maritimeLabourConventionCertificate')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Maritime Labour Convention Certificate' [tooltipText]="getCertificateTooltip('maritimeLabourConventionCertificate')"
                                [tooltipClass]="getTooltipClass('maritimeLabourConventionCertificate')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.maritimeLabourConventionCertificate" [entity]="model" property="maritimeLabourConventionCertificate"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.maritimeLabourConventionCertificateRemarks" [entity]="model" property="maritimeLabourConventionCertificateRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('internationalOilPollutionPrevention')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='International Oil Pollution Prevention (IOPP)' [tooltipText]="getCertificateTooltip('internationalOilPollutionPrevention')"
                                [tooltipClass]="getTooltipClass('internationalOilPollutionPrevention')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.internationalOilPollutionPrevention" [entity]="model" property="internationalOilPollutionPrevention"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.internationalOilPollutionPreventionRemarks" [entity]="model" property="internationalOilPollutionPreventionRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('insuranceForMaritimeClaims')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Insurance For Maritime Claims' [tooltipText]="getCertificateTooltip('insuranceForMaritimeClaims')"
                                [tooltipClass]="getTooltipClass('insuranceForMaritimeClaims')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.insuranceForMaritimeClaims" [entity]="model" property="insuranceForMaritimeClaims"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.insuranceForMaritimeClaimsRemarks" [entity]="model" property="insuranceForMaritimeClaimsRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('bunkerOilPollutionDamage')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Bunker Certificate (BOPD)' [tooltipText]="getCertificateTooltip('bunkerOilPollutionDamage')"
                                [tooltipClass]="getTooltipClass('bunkerOilPollutionDamage')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.bunkerOilPollutionDamage" [entity]="model" property="bunkerOilPollutionDamage"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.bunkerOilPollutionDamageRemarks" [entity]="model" property="bunkerOilPollutionDamageRemarks"></app-control>
                        </div>
                    </div>
                </div>
                <div class="certificate-container mb-3" *ngIf="shouldShowCertificates('wreckRemovalCertificates')">
                    <div class="row">
                        <div class="col-12 font-weight-bold">
                            <app-control label='Wreck Removal Certificates' [tooltipText]="getCertificateTooltip('wreckRemovalCertificates')"
                                [tooltipClass]="getTooltipClass('wreckRemovalCertificates')" tooltipPlacement="right" type="boolean"
                                [isDisabled]="!editMode" [(ngModel)]="model.wreckRemovalCertificates" [entity]="model" property="wreckRemovalCertificates"></app-control>
                        </div>
                        <div class="col-12">
                            <app-control label='Remarks' type="textarea" [(ngModel)]="model.wreckRemovalCertificatesRemarks" [entity]="model" property="wreckRemovalCertificatesRemarks"></app-control>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div *ngIf="shortNotification" class="col">
                    <h6 translate>Is V/L certified in terms of the local commercial provisions (CVC)</h6>
                    <div class="row">
                        <div class="col-2">
                            <app-control type="yesno" [(ngModel)]="model.hasCvc" [entity]="model" property="hasCvc"></app-control>
                        </div>
                        <div class="col-4">
                            <app-control *ngIf="model.hasCvc === false" label='Remarks' [(ngModel)]="model.cvcRemarks" [entity]="model" property="cvcRemarks"></app-control>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-2">
                    <app-vessel-certificates [model]="model.vessel" [canEdit]="canEdit" [editMode]="editMode" [user]="user" [notification]="{isShort: shortNotification, typeId: model.notificationTypeId}"></app-vessel-certificates>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion *ngIf="model.vessel" activeIds="voyage-info">
    <ngb-panel id="voyage-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel" (panelChange)="disableAppControls()">
                <h5 translate>IMO FAL 1 - Voyage information</h5>
            </app-accordion-header>
        </ng-template>
        <!-- NOT SHORT NOTIFICATION: -->
        <ng-template ngbPanelContent *ngIf="!shortNotification">
            <div class="row">
                <div class="col">
                    <app-control *ngIf="model.notificationTypeId !== 'D'" label='Last Port of Call'
                        type="codelist" codelist="Location" [(ngModel)]="model.lastPortId" [entity]="model" property="lastPortId"></app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D' && !['Z', 'X'].includes(model.statusId)"
                        label='Port of Call (Malta)' type="codelist" codelist="LocalPort"
                        (ngModelChange)="onPortOfCallChange($event)" [(ngModel)]="model.portOfCallId" [entity]="model" property="portOfCallId"></app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D' && ['Z', 'X'].includes(model.statusId)"
                        label='Port of Call (Malta)' [isDisabled]="true" [ngModel]="canceledText"></app-control>
                </div>
                <div class="col">
                    <app-control *ngIf="model.notificationTypeId !== 'D'" label='Last Port of Call ATD'
                        type="datetime" [time]="true" [(ngModel)]="model.lastPortEtd" [entity]="model" property="lastPortEtd"></app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D'" label='Port of Call ETD (Malta)'
                        type="datetime" [time]="true" [(ngModel)]="model.portOfCallEtd" [entity]="model" property="portOfCallEtd"></app-control>
                </div>
                <div class="col">
                    <app-control label='Next Port of Call'type="codelist" codelist="Location"
                        [(ngModel)]="model.nextPortId" [entity]="model" property="nextPortId"></app-control>
                </div>
                <div class="col">
                    <app-control *ngIf="model.notificationTypeId !== 'D'" label='Security Level'
                        type="codelist" codelist="SecurityLevel" [(ngModel)]="model.securityLevelId" [entity]="model" property="securityLevelId"></app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D'" label='Next Port Of Call ETA'
                        type="datetime" [time]="true" [(ngModel)]="model.nextPortOfCallEta" [entity]="model" property="nextPortOfCallEta"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col">
                    <app-control *ngIf="!['Z', 'X'].includes(model.statusId)"
                        label='Port of Call (Malta)' type="codelist" codelist="LocalPort"
                        (ngModelChange)="onPortOfCallChange($event)" [(ngModel)]="model.portOfCallId" [entity]="model" property="portOfCallId"></app-control>
                    <app-control *ngIf="['Z', 'X'].includes(model.statusId)"
                        label='Port of Call (Malta)' [isDisabled]="true" [ngModel]="canceledText"></app-control>
                </div>
                <div class="col">
                    <app-control label='Port of Call ETA (Malta)' type="datetime"
                        [time]="true" [(ngModel)]="model.portOfCallEta" [entity]="model" property="portOfCallEta"></app-control>
                </div>
                <div class="col">
                    <app-control label='Port of Call ETD (Malta)' type="datetime"
                        [time]="true" [(ngModel)]="model.portOfCallEtd" [entity]="model" property="portOfCallEtd"></app-control>
                </div>
                <div class="col">
                    <app-control label='Inf Class of the Ship' type="codelist"
                        codelist="InfClass" [(ngModel)]="model.infClassId" [entity]="model" property="infClassId"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <app-control label='Terminal Operator' type="codelist"
                        codelist="TerminalOperator" [(ngModel)]="model.terminalOperatorId" [entity]="model" property="terminalOperatorId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Berth' [fetchOnOpen]="true" [fetch]="fetchBerths"
                        [(ngModel)]="model.berthId" [entity]="model" property="berthId" (ngModelChange)="onBerthChange($event)"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Port Facility' type="codelist" codelist="PortFacility"
                        [isDisabled]="true" [(ngModel)]="model.portFacilityId" [entity]="model" property="portFacilityId"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId !== 'D'">
                    <app-control label='Voyage IN' [(ngModel)]="model.voyageIn" [entity]="model" property="voyageIn"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Side at Berth' type="codelist"
                        codelist="BerthType" [(ngModel)]="model.berthTypeId" [entity]="model" property="berthTypeId"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.portOfCallId !== 'MTWTW' && model.notificationTypeId !== 'D'">
                <div class="col-3">
                    <app-control label='Side at Berth' type="codelist"
                        codelist="BerthType" [(ngModel)]="model.berthTypeId" [entity]="model" property="berthTypeId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='First Bollard' [(ngModel)]="model.firstBollard" [entity]="model" property="firstBollard"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Last Bollard' [(ngModel)]="model.lastBollard" [entity]="model" property="lastBollard"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Voyage OUT' [(ngModel)]="model.voyageOut" [entity]="model" property="voyageOut"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId === 'D'">
                <div class="col">
                    <app-control label='First Bollard' [(ngModel)]="model.firstBollard" [entity]="model" property="firstBollard"></app-control>
                </div>
                <div class="col">
                    <app-control label='Last Bollard' [(ngModel)]="model.lastBollard" [entity]="model" property="lastBollard"></app-control>
                </div>
                <div class="col">
                    <app-control label='Departure Draught Bow (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.departureDraughtBow" [entity]="model" property="departureDraughtBow"></app-control>
                </div>
                <div class="col">
                    <app-control label='Departure Draught Stern (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.departureDraughtStern" [entity]="model" property="departureDraughtStern"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col">
                    <app-control label='Arrival Draught Bow (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.arrivalDraughtBow" [entity]="model" property="arrivalDraughtBow"></app-control>
                </div>
                <div class="col">
                    <app-control label='Arrival Draught Stern (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.arrivalDraughtStern" [entity]="model" property="arrivalDraughtStern"></app-control>
                </div>
                <div class="col">
                    <app-control label='Tentative Departure Draught Bow (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.departureDraughtBow" [entity]="model" property="departureDraughtBow"></app-control>
                </div>
                <div class="col">
                    <app-control label='Tentative Departure Draught Stern (metres)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.departureDraughtStern" [entity]="model" property="departureDraughtStern"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <app-control label='Brief description of cargo on board' [(ngModel)]="model.cargoDescription" [entity]="model" property="cargoDescription"
                        (ngModelChange)="onCargoDataChange()"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Cargo Gross Weight (kg/l)' type="number" format="n2"
                        [decimals]="2" [(ngModel)]="model.cargoGrossWeight" [entity]="model" property="cargoGrossWeight"
                        (ngModelChange)="onCargoDataChange()"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Voyage IN' [(ngModel)]="model.voyageIn" [entity]="model" property="voyageIn"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Voyage OUT' [(ngModel)]="model.voyageOut" [entity]="model" property="voyageOut"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Departure Remarks' [(ngModel)]="model.departureRemarks" [entity]="model" property="departureRemarks"></app-control>
                </div>
            </div>
            <hr class="my-3" *ngIf="model.notificationTypeId !== 'D' "/>
            <div class="row" *ngIf="model.notificationTypeId !== 'D' ">
                <div class="col-3">
                    <app-control label='Primary Call Purpose' type="codelist" codelist="CallPurpose"
                        (ngModelChange)="onPrimaryCallPurposesChange($event)" [(ngModel)]="model.primaryCallPurposeId" [entity]="model" property="primaryCallPurposeId"></app-control>
                </div>
                <div class="col-3">
                    <app-control #callActivitiesControl *ngIf="model.primaryCallPurposeId"
                        label='Call Activity' [filter]="callActivityFilter.bind(this)" [fetchOnOpen]="true"
                        type="codelist" codelist="CallActivity" [(ngModel)]="model.callActivityId" [entity]="model" property="callActivityId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label="Will Vessel require OPS connection (Onshore Power Supply)?"
                        type="yesno" [(ngModel)]="model.isOpsConnectionRequired" [entity]="model" property="isOpsConnectionRequired"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col">
                    <app-control label='Additional Call Purposes' type="codelist" codelist="CallPurpose" [multi]="true"
                        (ngModelChange)="onCallPurposesChange($event)" [(ngModel)]="callPurposes" [entity]="" property="callPurposes"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col">
                    <app-control label='Further details' type="textarea"
                        [(ngModel)]="model.callPurposeRemarks" [entity]="model" property="callPurposeRemarks"></app-control>
                </div>
            </div>
            <hr class="my-3" />
            <div class="row">
                <div class="col-2">
                    <app-control label='Name of Master' [(ngModel)]="model.masterName" [entity]="model" property="masterName"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Crew' [isDisabled]="!!model.workers.length || !editMode"
                        [(ngModel)]="model.numberOfCrew" [entity]="model" property="numberOfCrew" (ngModelChange)="passengersChange('numberOfCrew')"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Pax' [isDisabled]="!!model.passengers.length || !editMode"
                        [(ngModel)]="model.numberOfPax" [entity]="model" property="numberOfPax" (ngModelChange)="passengersChange('numberOfPax')"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Stowaways' [(ngModel)]="model.numberOfStowaways" [entity]="model" property="numberOfStowaways"
                        (ngModelChange)="passengersChange()">
                    </app-control>
                </div>
                <div class="col-2">
                    <app-control label='Persons on board' [isDisabled]="!isEditable"
                        [(ngModel)]="model.personsOnBoard" [entity]="model" property="personsOnBoard" (ngModelChange)="personsChange()"></app-control>
                </div>
            </div>
        </ng-template>
        <!-- SHORT NOTIFICATION: -->
        <ng-template ngbPanelContent *ngIf="shortNotification">
            <div class="row">
                <div class="col-3" *ngIf="model.notificationTypeId !== 'D'">
                    <app-control label='Last Port of Call'type="codelist"
                        codelist="Location" [(ngModel)]="model.lastPortId" [entity]="model" property="lastPortId"></app-control>
                </div>
                <div class="col-3">
                    <app-control *ngIf="!['Z', 'X'].includes(model.statusId)" label='Port of Call (Malta)'
                        type="codelist" codelist="LocalPort" [(ngModel)]="model.portOfCallId" [entity]="model" property="portOfCallId"
                        (ngModelChange)="onPortOfCallChange($event)"></app-control>
                    <app-control *ngIf="['Z', 'X'].includes(model.statusId)" label='Port of Call (Malta)'
                        [isDisabled]="true" [ngModel]="canceledText"> </app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'A'">
                    <app-control label='Port of Call ETA (Malta)' type="datetime" [time]="true"
                        [(ngModel)]="model.portOfCallEta" [entity]="model" property="portOfCallEta"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Port of Call ETD (Malta)' type="datetime" [time]="true"
                        [(ngModel)]="model.portOfCallEtd" [entity]="model" property="portOfCallEtd"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Terminal Operator' type="codelist"
                        codelist="TerminalOperator" [(ngModel)]="model.terminalOperatorId" [entity]="model" property="terminalOperatorId"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3" *ngIf="model.notificationTypeId !== 'D'">
                    <app-control label='Terminal Operator' type="codelist"
                        codelist="TerminalOperator" [(ngModel)]="model.terminalOperatorId" [entity]="model" property="terminalOperatorId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Berth' [fetchOnOpen]="true" [fetch]="fetchBerths"
                        [(ngModel)]="model.berthId" [entity]="model" property="berthId" (ngModelChange)="onBerthChange($event)"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Port Facility' type="codelist" codelist="PortFacility"
                        [isDisabled]="true" [(ngModel)]="model.portFacilityId" [entity]="model" property="portFacilityId"></app-control>
                </div>
                <div class="col-3" *ngIf="model.notificationTypeId === 'D'">
                    <app-control label='Next Port of Call' type="codelist"
                        codelist="Location" [(ngModel)]="model.nextPortId" [entity]="model" property="nextPortId"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.portOfCallId !== 'MTWTW' && model.notificationTypeId !== 'D'">
                <div class="col-3">
                    <app-control label='Side at Berth' type="codelist"
                        codelist="BerthType" [(ngModel)]="model.berthTypeId" [entity]="model" property="berthTypeId"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='First Bollard' [(ngModel)]="model.firstBollard" [entity]="model" property="firstBollard"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Last Bollard' [(ngModel)]="model.lastBollard" [entity]="model" property="lastBollard"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <app-control *ngIf="model.notificationTypeId !== 'D'" label='Arrival Draught Bow (metres)'
                        type="number" format="n2" [decimals]="2" [(ngModel)]="model.arrivalDraughtBow" [entity]="model" property="arrivalDraughtBow"></app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D'" label='Departure Draught Bow (metres)'
                        type="number" format="n2" [decimals]="2" [(ngModel)]="model.departureDraughtBow" [entity]="model" property="departureDraughtBow"></app-control>
                </div>
                <div class="col-3">
                    <app-control *ngIf="model.notificationTypeId !== 'D'" label='Arrival Draught Stern (metres)'
                        type="number" format="n2" [decimals]="2" [(ngModel)]="model.arrivalDraughtStern" [entity]="model" property="arrivalDraughtStern">
                    </app-control>
                    <app-control *ngIf="model.notificationTypeId === 'D'" label='Departure Draught Stern (metres)'
                        type="number" format="n2" [decimals]="2" [(ngModel)]="model.departureDraughtStern" [entity]="model" property="departureDraughtStern"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Voyage Remarks' [(ngModel)]="model.shortRemarks" [entity]="model" property="shortRemarks"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <app-control label='Cargo on board' type="yesno" [(ngModel)]="model.cargoOnBoard" [entity]="model" property="cargoOnBoard"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Bunkers on board' type="yesno" [(ngModel)]="model.bunkersOnBoard" [entity]="model" property="bunkersOnBoard"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='DPG on board' type="yesno" [(ngModel)]="model.dpgOnBoard" [entity]="model" property="dpgOnBoard"></app-control>
                </div>
            </div>
            <div class="row">
                <div class="col-3">
                    <app-control label='Cargo description' type="textarea" [(ngModel)]="model.cargoDescription" [entity]="model" property="cargoDescription"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='Bunkers description' type="textarea" [(ngModel)]="model.bunkersDescription" [entity]="model" property="bunkersDescription"></app-control>
                </div>
                <div class="col-3">
                    <app-control label='DPG description' type="textarea" [(ngModel)]="model.dpgDescription" [entity]="model" property="dpgDescription"></app-control>
                </div>
            </div>
            <hr class="my-3" *ngIf="model.notificationTypeId !== 'D'" />
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col-3">
                    <app-control label='Call Purpose' type="codelist" codelist="ShortCallPurpose"
                        [(ngModel)]="model.primaryShortCallPurposeId" [entity]="model" property="primaryShortCallPurposeId"></app-control>
                </div>
                <div class="col-6" *ngIf="model.primaryShortCallPurposeId === 40 || otherSelected"><!-- Id:40 === Other -->
                    <app-control label='Call Purpose Remarks' [(ngModel)]="model.shortCallPurposeRemarks" [entity]="model" property="shortCallPurposeRemarks"></app-control>
                </div>
            </div>
            <div class="row" *ngIf="model.notificationTypeId !== 'D'">
                <div class="col">
                    <app-control label='Additional Call Purposes' type="codelist" codelist="ShortCallPurpose" [multi]="true"
                        (ngModelChange)="onCallPurposesChange($event)" [(ngModel)]="shortCallPurposes" [entity]="" property="shortCallPurposes"></app-control>
                </div>
            </div>
            <hr class="my-3" />
            <div class="row">
                <div class="col-2">
                    <app-control label='Name of Master' [(ngModel)]="model.masterName" [entity]="model" property="masterName"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Crew' [isDisabled]="!!model.workers.length || !editMode"
                        [(ngModel)]="model.numberOfCrew" [entity]="model" property="numberOfCrew" (ngModelChange)="passengersChange('numberOfCrew')"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Pax' [isDisabled]="!!model.passengers.length || !editMode"
                        [(ngModel)]="model.numberOfPax" [entity]="model" property="numberOfPax" (ngModelChange)="passengersChange('numberOfPax')"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Number of Stowaways' [(ngModel)]="model.numberOfStowaways" [entity]="model" property="numberOfStowaways"
                        (ngModelChange)="passengersChange()"></app-control>
                </div>
                <div class="col-2">
                    <app-control label='Persons on board' [isDisabled]="!isEditable"
                        [(ngModel)]="model.personsOnBoard" [entity]="model" property="personsOnBoard" (ngModelChange)="personsChange()"></app-control>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="prearrival-info" *ngIf="!shortNotification && model.vessel && model.notificationTypeId !== 'D'">
    <ngb-panel id="prearrival-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Paris MoU Pre-Arrival 3 Days Notification Details</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <table class="table table-striped table-bordered mb-3">
                <tbody>
                    <tr>
                        <td translate>Is Malta the first EU port of call?</td>
                        <td><app-control type="yesno" [(ngModel)]="model.preArrival" [entity]="model" property="preArrival"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Possible anchorage?</td>
                        <td><app-control type="yesno" [isDisabled]="!model.preArrival || !editMode" [(ngModel)]="model.possibleAnchorage" [entity]="model" property="possibleAnchorage"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Planned operations?</td>
                        <td><app-control [isDisabled]="!model.preArrival || !editMode" [(ngModel)]="model.plannedOperations" [entity]="model" property="plannedOperations"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Planned Statutory Survey/Maintenance/Repair work?</td>
                        <td><app-control type="yesno" [isDisabled]="!editMode || !model.preArrival" [(ngModel)]="model.plannedWorks" [entity]="model" property="plannedWorks"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>If Yes, state details.</td>
                        <td><app-control [isDisabled]="!model.preArrival || !editMode" [(ngModel)]="model.plannedWorksRemarks" [entity]="model" property="plannedWorksRemarks"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Volume and nature of cargo.</td>
                        <td >
                            <app-control *ngIf="model.preArrival || model.preArrivalSentDate; else emptyCargoVolumeNature" [isDisabled]="!model.preArrival" [(ngModel)]="model.cargoVolumeNature" [entity]="model" property="cargoVolumeNature"></app-control>
                            <ng-template #emptyCargoVolumeNature>
                                <app-control [isDisabled]="true"></app-control>
                            </ng-template>
                        </td>
                    </tr>
                    <tr>
                        <td translate>Is the ship due for a Paris MOU expanded inspection?</td>
                        <td><app-control type="yesno" [isDisabled]="!model.preArrival || !editMode" [(ngModel)]="model.parisMouInspection" [entity]="model" property="parisMouInspection"></app-control></td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion activeIds="tanker-info" *ngIf="model.vessel?.type?.tanker">
    <ngb-panel id="tanker-info">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel" (panelChange)="disableAppControls()">
                <h5 translate>Tanker Info</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <table class="table table-striped table-bordered mb-3">
                <tbody>
                    <tr>
                        <td translate>Tanker hull configuration</td>
                        <td><app-control [(ngModel)]="model.tankerTypeId" [entity]="model" property="tankerTypeId"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Valid International Pollution Prevention Certificate for the Carriage of Noxious Liquid Substances in Bulk (NLS Certificate)</td>
                        <td><app-control type="yesno" [(ngModel)]="model.validPollutionPreventionCertificate" [entity]="model" property="validPollutionPreventionCertificate"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Valid Certificate of fitness for the Carriage of Dangerous Chemical in Bulk or International Certificate for the carriage of Dangerous Goods in Bulk</td>
                        <td><app-control type="yesno" [(ngModel)]="model.validCarriageDangerousChemicalBulkCertificate" [entity]="model" property="validCarriageDangerousChemicalBulkCertificate"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Valid Certificate for the Carriage of Liquefied Gases in Bulk</td>
                        <td><app-control type="yesno" [(ngModel)]="model.validCarriageLiquefiedGasesBulkCertificate" [entity]="model" property="validCarriageLiquefiedGasesBulkCertificate"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Valid Certificate of Fitness for the Carriage of INF Cargo</td>
                        <td><app-control type="yesno" [(ngModel)]="model.validCarriageInfCargoCertificate" [entity]="model" property="validCarriageInfCargoCertificate"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Is Vessel fitted with a fixed inert gas system?</td>
                        <td><app-control type="yesno" [(ngModel)]="model.hasInertGasSystem" [entity]="model" property="hasInertGasSystem"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>If Yes, is system operational?</td>
                        <td><app-control type="yesno" [(ngModel)]="model.isInertGasSystemOperational" [entity]="model" property="isInertGasSystemOperational"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Is vessel inerted?</td>
                        <td><app-control type="yesno" [(ngModel)]="model.inertedVessel" [entity]="model" property="inertedVessel"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>OXYGEN CONTENT (% by Volume)</td>
                        <td><app-control type="number" format="n2" [decimals]="2" [(ngModel)]="model.inertGasSystemOxygen" [entity]="model" property="inertGasSystemOxygen"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>MINIMUM POSITIVE PRESSURE (mmgh)</td>
                        <td><app-control type="number" format="n2" [decimals]="2" [(ngModel)]="model.inertGasSystemMinimumPressure" [entity]="model" property="inertGasSystemMinimumPressure"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Is vessel GAS FREE?</td>
                        <td><app-control type="yesno" [(ngModel)]="model.gasFree" [entity]="model" property="gasFree"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>OXYGEN READING (% by Volume)</td>
                        <td><app-control type="number" format="n2" [decimals]="2" [(ngModel)]="model.gasFreeOxygen" [entity]="model" property="gasFreeOxygen"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>FLAMMABLE gases (%LFL)</td>
                        <td><app-control type="number" format="n2" [decimals]="2" [(ngModel)]="model.flammableGasses" [entity]="model" property="flammableGasses"></app-control></td>
                    </tr>
                    <tr>
                        <td translate>Is vessel under vapours</td>
                        <td><app-control type="yesno" [(ngModel)]="model.isUnderVapors" [entity]="model" property="isUnderVapors"></app-control></td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<ngb-accordion *ngIf="model.vessel">
    <ngb-panel isOpen="true">
        <ng-template ngbPanelHeader let-panel>
            <app-accordion-header [panel]="panel">
                <h5 translate>Ship's Defects</h5>
            </app-accordion-header>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col-12">
                    <app-control label='Defects' type="codelist" codelist="Defect" [multi]="true"
                        [isDisabled]="!editMode || !canEdit || user?.isSecurity()"
                        (ngModelChange)="onDefectsChange($event)" [(ngModel)]="defects" [entity]="" property="defects"></app-control>
                </div>
                <div class="col-12">
                    <app-control label='Defect remarks' type="textarea"
                        [isDisabled]="!editMode || !canEdit || user?.isSecurity()"
                        [(ngModel)]="model.defectRemarks" [entity]="model" property="defectRemarks"></app-control>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment *ngIf="model.vessel" [attachments]="model.attachments" [canEdit]="canEdit && !user?.isSecurity()"
    [editMode]="editMode" title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
