<app-grid [data]="query" [selectable]="{ enabled: true, checkboxOnly: true }" [selection]="selection"
    [sortable]="true" [sort]="[{ field: 'id', dir: 'asc' }]">
    <kendo-grid-checkbox-column [width]="45" [showSelectAll]="true"></kendo-grid-checkbox-column>
    <kendo-grid-column title="V/S Notification" [width]="125" field="yearNumber">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-shift/view', row.id]">
                <div [appPill]="{ status: row.statusId, module: 'vesselShift' }">{{row.yearNumber}}</div>
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Status' | translate}}" field="status.name" [width]="145">
        <ng-template kendoGridCellTemplate let-row>
            <div [appPill]="{ status: row.statusId, module: 'vesselShift', muted: true }">
                {{row.statusId | codelist:'VesselShiftStatus' | async}}
            </div>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vesselNotification.vessel.name">
        <ng-template kendoGridCellTemplate let-row>
            <div *ngIf="isRestricted(row.vesselNotification.vessel.statusId)" class="badge box-shadow tight" style="font-size: 16px;"
                ngbTooltip="{{isRestricted(row.vesselNotification.vessel.statusId, true)}}" placement="right">
                <fa-icon icon="exclamation" class="redIcon"></fa-icon>
            </div>
            <a [routerLink]="['/vessels/view', row.vesselNotification.vesselId]" class="text-primary" target="_blank">
                {{row.vesselNotification.vesselId | codelist:'Vessel' | async}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'V/L Notification' | translate}}" field="vesselNotification.number">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-notification/view', row.vesselNotificationId]" class="text-primary" target="_blank">
                {{row.vesselNotification.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Vessel Visit' | translate}}" field="vesselNotification.vesselVisit.number">
        <ng-template kendoGridCellTemplate let-row>
            <a [routerLink]="['/vessel-visit/view', row.vesselNotification?.vesselVisitId]" class="text-primary" target="_blank">
                {{row.vesselNotification?.vesselVisit?.yearNumber}}
            </a>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Shifting From' | translate}}" field="shiftingDate" format="g" [width]="140"></kendo-grid-column>
    <kendo-grid-column title="{{'Shifting To' | translate}}" field="portOfCallEtd" format="g" [width]="140"></kendo-grid-column>
    <kendo-grid-column title="{{'Berth/Area From' | translate}}" field="berthShiftingFrom.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthShiftingFromId | codelist:'Berth' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Berth/Area To' | translate}}" field="berthShiftingTo.name">
        <ng-template kendoGridCellTemplate let-row>
            {{row.berthShiftingToId | codelist:'Berth' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'ATS' | translate}}" field="ats" format="g"></kendo-grid-column>
    <kendo-grid-column title="{{'Agent' | translate}}" field="requestingAgent.name"></kendo-grid-column>
    <kendo-grid-column class="text-center" title="{{'TM' | translate}}" [width]="40">
        <ng-template kendoGridCellTemplate let-row>
            <app-rej-icon [item]="row" organizationName="" moduleType="VesselShift"></app-rej-icon>
        </ng-template>
    </kendo-grid-column>
</app-grid>
