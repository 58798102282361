import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { EntityFormOptions } from '@common/classes/entity-form';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { EntityFormComponent } from '@common/components/entity-form/entity-form.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { CodelistService } from '@common/services/codelist.service';
import { DialogService } from '@common/services/dialog.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-dangerous-goods',
    templateUrl: './vessel-notification-dangerous-goods.component.html',
    styleUrls: ['./vessel-notification-dangerous-goods.component.scss']
})
export class VesselNotificationDangerousGoodsComponent extends AbstractBreezeListComponent implements AfterViewInit, OnInit {
    queryName = 'VesselNotificationDangerousGoods';
    data = [];
    units = [];
    dpgData = [];
    filter = this.getDefaultFilter();

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() canOpenModal: boolean;
    @Input() editMode: any;
    @Input() user: User;

    @Output() public modalClose = new EventEmitter<any>();

    @ViewChild(EntityFormComponent) public entityForm: EntityFormComponent;

    formOptions: EntityFormOptions = {
        entityName: 'VesselNotificationDangerousGoods',
        canEdit: () => this.canEdit,
        getCollection: () => this.model.dangerousGoods,
        beforeAdd: (model) => {
            model.sequenceNumber = this.model.dangerousGoods.length > 0
                ? this.model.dangerousGoods[this.model.dangerousGoods.length - 1].sequenceNumber + 1
                : 1;
        },
        onRemove: (item) => {
            if (item.sequenceNumber <= this.model.dangerousGoods.length)
                this.updateSequenceNumber(this.model.dangerousGoods);
        },
        propertyGroups: [
            [
                { name: 'cargoRegulationId', label: 'Classification', type: AppControlType.CodeList, codelist: 'CargoRegulation' },
                { name: 'portOfLoadingId', label: 'Port of Loading', type: AppControlType.CodeList, codelist: 'Location'  },
                { name: 'portOfDischargeId', label: 'Port of Discharge', type: AppControlType.CodeList, codelist: 'Location' },
                { name: 'transportUnitNumber', label: 'Marks & Numbers', maxlength: 100, isDisabled: (model) => this.generalInputDisabledRule(model) }
            ],
            [
                {
                    name: 'numberOfPackages',
                    label: 'Number of Packages',
                    type: AppControlType.Number,
                    decimals: 2,
                    isDisabled: (model) => ['IBC', 'IGC', 'IMSBC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId)
                },
                {
                    name: 'packagingTypeId',
                    label: 'Type of Packages',
                    type: AppControlType.CodeList,
                    codelist: 'PackagingType',
                    isDisabled: (model) => this.generalInputDisabledRule(model)
                },
                { name: 'ems', label: 'EMS', maxlength: 20, isDisabled: (model) => this.generalInputDisabledRule(model) },
                { name: 'shippingName', label: 'Shipping Name', maxlength: 250 }
            ],
            [
                {
                    name: 'imdgClassId',
                    label: 'Class',
                    type: AppControlType.CodeList,
                    codelist: 'ImdgClass',
                    isVisible: (model) => !['IBC', 'IMSBC'].includes(model.cargoRegulationId),
                    isDisabled: (model) => ['IGC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId)
                },
                {
                    name: 'ibcCodeId',
                    label: 'Class',
                    type: AppControlType.CodeList,
                    codelist: 'IbcCode',
                    isVisible: (model) => model.cargoRegulationId === 'IBC'
                },
                {
                    name: 'imsbcCodeId',
                    label: 'Class',
                    type: AppControlType.CodeList,
                    codelist: 'ImsbcCode',
                    isVisible: (model) => model.cargoRegulationId === 'IMSBC'
                },
                {
                    name: 'unNumber',
                    label: 'UN Number',
                    pattern: '[0-9]',
                    maxlength: 4,
                    isDisabled: (model) => ['IBC', 'IGC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId)
                },
                {
                    name: 'imdgPackingGroupId',
                    label: 'Packing group',
                    type: AppControlType.CodeList,
                    codelist: 'ImdgPackingGroup',
                    isDisabled: (model) => this.generalInputDisabledRule(model)
                },
                {
                    name: 'subsidiaryRisks',
                    label: 'Subsidiary',
                    maxlength: 50,
                    isDisabled: (model) => this.generalInputDisabledRule(model)
                }
            ],
            [
                {
                    name: 'flashpoint',
                    label: 'Flashpoint (\xB0C)',
                    pattern: '[\°0-9\-\+\.C]',
                    maxlength: 20,
                    isDisabled: (model) => ['IGC', 'IMSBC'].includes(model.cargoRegulationId)
                },
                {
                    name: 'marpolCategoryId',
                    label: 'Marine Pollutant',
                    type: AppControlType.CodeList,
                    codelist: 'MarpolCategory',
                    isVisible: (model) => model.cargoRegulationId !== 'IMDG'
                },
                {
                    name: 'imdgMarinePollutantId',
                    label: 'Marine Pollutant',
                    type: AppControlType.CodeList,
                    codelist: 'ImdgMarinePollutant',
                    isVisible: (model) => model.cargoRegulationId === 'IMDG',
                    isDisabled: (model) => ['IGC', 'IMSBC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId)
                },
                { name: 'grossWeight', label: 'Gross Mass', type: AppControlType.Number, decimals: 2 },
                { name: 'unitOfMeasureId', label: 'UoM', type: AppControlType.CodeList, codelist: 'UnitOfMeasure' },
            ],
            [
                { name: 'netWeight', label: 'Net Weight', type: AppControlType.Number, decimals: 2, colSize: 3 },
                { name: 'stowagePosition', label: 'Stowage Position', maxlength: 20, colSize: 3 },
                {
                    name: 'bookingReferenceNumber',
                    label: 'Reference Number',
                    maxlength: 20,
                    isDisabled: (model) => ['IBC', 'IGC', 'IMSBC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId),
                    colSize: 3
                },
            ],
            [
                { name: 'additionalInformation', label: 'Additional Information', type: AppControlType.TextArea, maxlength: 500 }
            ]
        ]
    };

    constructor(breezeViewService: BreezeViewService,
        private codelistService: CodelistService,
        private dialogService: DialogService) {
        super(breezeViewService);
    }

    override async ngOnInit() {
        super.ngOnInit();

        const units = await this.codelistService.getCodelist({ name: 'UnitOfMeasure', useCache: false });
        this.units = units.map(u => u.id);
    }

    override ngAfterViewInit(): void {
        if (!this.canOpenModal || this.model.dangerousGoods?.length !== 0) return;
        setTimeout(async () => {
            const result = await this.dialogService.yesNo(this.translateService.instant('DPG'), this.translateService.instant('The Vessel has DPG on board?'));
            this.model.dpgListOnBoard = result;
            this.modalClose.emit(result);
        });
    }

    override search() {
        const query = this.getQuery();
        this.entityManager.executeQuery(query).then(data => {
            this.data = data.results;
            this.dpgData = _.chain(this.data)
                .map(dpg => ({
                    unitOfMeasureId: dpg.unitOfMeasureId,
                    grossWeight: dpg.grossWeight,
                    class: `${dpg.cargoRegulationId} ${this.getClass(dpg)}`.trim()
                }))
                .groupBy('class')
                .map((value, key) => ({
                    class: key,
                    uoms: _.chain(value)
                        .groupBy('unitOfMeasureId')
                        .map((value, key) => ({
                            uom: key,
                            total: _.sumBy(value, 'grossWeight')
                        })).value()
                }))
                .orderBy(['class'])
                .value();
        });
        return query;
    }

    getClass(dpg: any) {
        const property = !['IBC', 'IMSBC'].includes(dpg.cargoRegulationId) ? 'imdgClassId'
            : dpg.cargoRegulationId === 'IBC' ? 'ibcCodeId' : 'imsbcCodeId';
        return dpg[property] || '';
    }

    getUnitTotal(row: { uoms: { uom: string, total: number }[], key: string }, unit: string) {
        return row.uoms.find(u => u.uom === unit)?.total || '-';
    }

    editRow(row) {
        this.entityForm.edit(row);
    }

    removeRow(row) {
        this.entityForm.remove(row);
    }

    generalInputDisabledRule(model): boolean {
        return ['IBC', 'IGC', 'IMSBC', 'MARPOL_ANNEX1'].includes(model.cargoRegulationId);
    }

    isRegulationSet(model) {
        return !!model.cargoRegulationId;
    }

    notContainRegulations(model, ...codes: string[]) {
        return this.isRegulationSet(model) && !codes.includes(model.cargoRegulationId);
    }

    containsAnyRegulations(model, ...codes: string[]) {
        return this.isRegulationSet(model) && codes.includes(model.cargoRegulationId);
    }

    private updateSequenceNumber(items) {
        items.forEach((p: any, i) => p.sequenceNumber = i + 1);
    }

    override getDefaultFilter() {
        return {
            id: this.model?.id
        };
    }
}
