import { Component } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-cargo-agent-data-confirmation-modal',
    templateUrl: './cargo-agent-data-confirmation-modal.component.html',
    styleUrls: ['./cargo-agent-data-confirmation-modal.component.scss'],
    providers: [BreezeViewService]
})
export class CargoAgentDataConfirmationModalComponent extends AbstractBreezeListComponent {
    queryName: string = 'DataConfirmations';
    id: number;

    constructor(breezeViewService: BreezeViewService,
        public activeModal: NgbActiveModal) {
        super(breezeViewService);
        this.persistFilter = false;
    }

    close() {
        this.activeModal.close(false);
    }

    getDefaultFilter() {
        return { vesselNotificationId: this.id };
    }
}
