<kendo-grid #grid
            id="{{id}}"
            [data]="internalData"
            [loading]="isBusy"
            [skip]="state.skip"
            [pageable]="pageable"
            [pageSize]="pageSize || state.take"
            [sortable]="sortable"
            [sort]="state.sort"
            [scrollable]="scrollable"
            [filterable]="filterable"
            [filter]="state.filter"
            (dataStateChange)="onDataStateChanged($event)"
            (selectionChange)="onSelectionChange($event)"
            (pageChange)="pageChange.emit($event)"
            (excelExport)="transformExcelExport($event)"
            [selectable]="selectable"
            [kendoGridSelectBy]="selectBy"
            [selectedKeys]="selection"
            [groupable]="groupable"
            [height]="height"
            [rowClass]="rowClass"
            #tooltip="ngbTooltip" placement="top" ngbTooltip=""
            tooltipClass="error-tooltip arrow-bottom" [ngClass]="{ 'is-invalid': errors.length > 0, 'sticky-header': stickyHeader }">
    <kendo-grid-excel></kendo-grid-excel>
</kendo-grid>
