<ngb-accordion activeIds="health-info">
    <ngb-panel id="health-info">
        <ng-template ngbPanelHeader let-panel>
            <h5 translate>Maritime Declaration Of Health Information</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <ngb-accordion activeIds="remarks">
                <ngb-panel id="remarks">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Remarks</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col">
                                <app-control label='Health remarks' [maxlength]="1000" type="textarea"
                                    [isDisabled]="!(user?.isHealth() || user?.isSystemUser) || !canEdit || !editMode"
                                    [(ngModel)]="model.healthRemarks" [entity]="model" property="healthRemarks"></app-control>
                                </div>
                            <div class="col">
                                <app-control label='Agent remarks' [maxlength]="1000" type="textarea"
                                    [isDisabled]="!(user?.isAgent() || user?.isSystemUser) || !canEdit || !editMode"
                                    [(ngModel)]="model.agentHealthRemarks" [entity]="model" property="agentHealthRemarks"></app-control>
                            </div>
                        </div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="health-list">
                <ngb-panel id="health-list">
                    <ng-template ngbPanelHeader let-panel>
                        <app-accordion-header [panel]="panel">
                            <h5 translate>Maritime Declaration Of Health</h5>
                        </app-accordion-header>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="row">
                            <div class="col-3">
                                <app-control label='Submitted at the Port of (LOCODE)' type="codelist" codelist="LocalPort"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.mdhIssuedAtId" [entity]="model" property="mdhIssuedAtId"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Date submitted' type="datetime" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.mdhIssuedDate" [entity]="model" property="mdhIssuedDate"></app-control>
                            </div>
                        </div>
                        <hr class="my-3"/>
                        <div class="row">
                            <div class="col-6">
                                <app-control label='Valid Sanitation Control Exemption/Control Certificate carried on board?' type="yesno"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.hasValidControlCertificate" [entity]="model" property="hasValidControlCertificate"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Issued at' type="codelist" codelist="Location" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.submittedAtThePortOfId" [entity]="model" property="submittedAtThePortOfId"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Date issued' type="datetime" [isDisabled]="isDisabled()"
                                    [(ngModel)]="model.controlCertificateIssuedDate" [entity]="model" property="controlCertificateIssuedDate"></app-control>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <app-control label='Re-inspection required?' type="yesno"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.reinspectionRequired" [entity]="model" property="reinspectionRequired"></app-control>
                            </div>
                        </div>
                        <hr class="my-3"/>
                        <div class="row mb-3">
                            <div class="col-3">
                                <app-control label='Has ship visited an affected area?' type="yesno"
                                    [isDisabled]="isDisabled()" [(ngModel)]="model.visitedAffectedArea" [entity]="model" property="visitedAffectedArea"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Port of visit (affected area)'
                                    [isDisabled]="!model.visitedAffectedArea || isDisabled()" [(ngModel)]="model.affectedAreaPortId" [entity]="model" property="affectedAreaPortId"></app-control>
                            </div>
                            <div class="col-3">
                                <app-control label='Date of visit (affected area)'
                                    [isDisabled]="!model.visitedAffectedArea || isDisabled()" [(ngModel)]="model.affectedAreaDate" [entity]="model" property="affectedAreaDate"></app-control>
                            </div>
                        </div>
                        <hr class="my-3"/>
                        <ngb-accordion activeIds="health-questions">
                            <ngb-panel id="health-questions">
                                <ng-template ngbPanelHeader let-panel>
                                    <app-accordion-header [panel]="panel">
                                        <h5 translate>HEALTH QUESTIONS</h5>
                                    </app-accordion-header>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <table class="table table-striped table-bordered mb-3">
                                        <thead>
                                            <tr>
                                                <th scope="col" translate>No.</th>
                                                <th scope="col" translate>Question</th>
                                                <th scope="col" translate>Answer</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td translate>Has any person died on board during the voyage otherwise than as a result of an accident? If YES state total number of deaths.</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasAnyPersonDied" [entity]="model" property="hasAnyPersonDied"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>1a</td>
                                                <td translate>Total number of deaths</td>
                                                <td><app-control type="number" format="'#'" [isDisabled]="!model.hasAnyPersonDied || isDisabled()"
                                                        [(ngModel)]="model.numberOfDeaths" [entity]="model" property="numberOfDeaths"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td translate>Is there on board or has there been during the international voyage any case of disease which you suspect to be of an infectious nature?</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasInfectiousDiseaseOnBoard" [entity]="model" property="hasInfectiousDiseaseOnBoard"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td translate>Has the total number of ill persons during the voyage been greater than normal / expected?</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasNumberOfIllPeopleGreaterThanExpected" [entity]="model" property="hasNumberOfIllPeopleGreaterThanExpected"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>3a</td>
                                                <td translate>Number of ill persons</td>
                                                <td><app-control type="number" format="'#'" [isDisabled]="isDisabled()"
                                                        [(ngModel)]="model.numberOfIllPeople" [entity]="model" property="numberOfIllPeople"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td translate>Is there any sick person on board now? If YES state particulars in attached schedule</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasAnyIllPeopleOnBoard" [entity]="model" property="hasAnyIllPeopleOnBoard"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td translate>Was a medical practitioner consulted? If YES state particulars of medical treatment or advice provided in attached schedule</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.wasMedicalPractitionerConsulted" [entity]="model" property="wasMedicalPractitionerConsulted"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td translate>Are you aware of any condition which may lead to infection or the spread of disease?</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.awareOfConditionForDiseaseSpread" [entity]="model" property="awareOfConditionForDiseaseSpread"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td translate>Has any sanitary measure (e.g. quarantine, isolation, disinfection or decontamination) been applied on board? If YES specify type, place and date</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasAnySanitaryMeasure" [entity]="model" property="hasAnySanitaryMeasure"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>7a</td>
                                                <td translate>Type of sanitary measure</td>
                                                <td><app-control [isDisabled]="!model.hasAnySanitaryMeasure || isDisabled()" [(ngModel)]="model.sanitaryMeasureType" [entity]="model" property="sanitaryMeasureType"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>7b</td>
                                                <td translate>Place of sanitary measure</td>
                                                <td><app-control [isDisabled]="!model.hasAnySanitaryMeasure || isDisabled()" [(ngModel)]="model.sanitaryMeasurePlace" [entity]="model" property="sanitaryMeasurePlace"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>7c</td>
                                                <td translate>Date of sanitary measure</td>
                                                <td><app-control type="datetime" [isDisabled]="!model.hasAnySanitaryMeasure || isDisabled()"
                                                        [(ngModel)]="model.sanitaryMeasureDate" [entity]="model" property="sanitaryMeasureDate"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td translate>Have any stowaways been found on board?</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasStowawaysOnBoard" [entity]="model" property="hasStowawaysOnBoard"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>8a</td>
                                                <td translate>If YES, where did they join the ship (if known)? Location or 'unknown' must be provided</td>
                                                <td><app-control [isDisabled]="!model.hasStowawaysOnBoard || isDisabled()" [(ngModel)]="model.stowawaysLocation" [entity]="model" property="stowawaysLocation"></app-control></td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td translate>Is there a sick animal or pet on board?</td>
                                                <td><app-control type="yesno" [isDisabled]="isDisabled()" [(ngModel)]="model.hasIllAnimalsOnBoard" [entity]="model" property="hasIllAnimalsOnBoard"></app-control></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="last-ports">
                <ngb-panel id="last-ports">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Previous Ports</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <div class="mb-2">
                            <strong translate>List ports of call from commencement of voyage with dates of departures, or within past thirty days, wichever is shorter.</strong>
                        </div>
                        <app-entity-form [options]="previousPortOptions" [grid]="previousPortsGrid"></app-entity-form>
                        <hr class="my-3"/>
                        <h3 translate>Previous Ports</h3>
                        <app-grid #previousPortsGrid [data]="model.mdhPreviousPorts" [sortable]="true" [sort]="[{ field: 'sequenceNumber', dir: 'asc' }]">
                            <kendo-grid-column title="{{'Seq.' | translate}}" field="sequenceNumber" [width]="50"></kendo-grid-column>
                            <kendo-grid-column field="portId" title="{{'Port' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.portId | codelist:'Location' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Departure Date' | translate}}" field="departureDate" format="d"></kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="joined-persons">
                <ngb-panel id="joined-persons">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Joined Persons</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="joinedPersonOptions" [grid]="joinedPersonsGrid"></app-entity-form>
                        <hr class="my-3"/>
                        <h3 translate>Joined Persons</h3>
                        <app-grid #joinedPersonsGrid [data]="model.joinedPersons">
                            <kendo-grid-column title="{{'Name' | translate}}" field="name"></kendo-grid-column>
                            <kendo-grid-column title="{{'Departure Date' | translate}}" field="departureDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'Joined From (1)' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.joinedFrom1Id | codelist:'Location' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Joined From (2)' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.joinedFrom2Id | codelist:'Location' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Joined From (3)' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.joinedFrom3Id | codelist:'Location' | async}}
                                </ng-template>
                            </kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
            <div class="mb-3"></div>
            <ngb-accordion activeIds="attachment-list">
                <ngb-panel id="attachment-list">
                    <ng-template ngbPanelHeader let-panel>
                        <h5 translate>Maritime Declaration Of Health Attachment</h5>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <app-entity-form [options]="attachmentOptions" [grid]="attachmentGrid"></app-entity-form>
                        <hr class="my-3"/>
                        <h3 translate>Attachment List</h3>
                        <app-grid #attachmentGrid [data]="model.mdhAttachments">
                            <kendo-grid-column title="{{'Given Name' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{getPerson(row).givenName}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Family Name' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{getPerson(row).familyName}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Passenger or Crew' | translate}}">
                                <ng-template kendoGridCellTemplate let-row>
                                    {{row.personTypeId | codelist:'PersonType' | async}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Age' | translate}}" field="age"></kendo-grid-column>
                            <kendo-grid-column title="{{'Date joined' | translate}}" field="joinedDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'Illness' | translate}}" field="illness"></kendo-grid-column>
                            <kendo-grid-column title="{{'Date of Symptoms' | translate}}" field="symptomsDate" format="d"></kendo-grid-column>
                            <kendo-grid-column title="{{'Reported to PM?' | translate}}">
                                <ng-template let-row kendoGridCellTemplate>
                                    <app-checkbox [disabled]="true" [(ngModel)]="row.reportedToMedicalOfficer"></app-checkbox>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="{{'Disposal' | translate}}" field="disposal"></kendo-grid-column>
                            <kendo-grid-column title="{{'Location' | translate}}" field="location"></kendo-grid-column>
                            <kendo-grid-column title="{{'Treatment' | translate}}" field="treatment"></kendo-grid-column>
                            <kendo-grid-column title="{{'Remarks' | translate}}" field="remarks"></kendo-grid-column>
                        </app-grid>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
<div class="mb-3"></div>
<app-vessel-notification-attachment [attachments]="model.attachments" [canEdit]="canEdit && !this.user?.isHealth()"
    [editMode]="editMode" title='Supporting Documents' [user]="user"></app-vessel-notification-attachment>
