import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-rej-icon',
    template: `<fa-icon [icon]="['fas', appRejIconStyling(item, organizationName, moduleType, true)]" [ngClass]="appRejIconStyling(item, organizationName, moduleType)"></fa-icon>`,
    styles: []
})
export class AppRejIconComponent {
    @Input() item: any;
    @Input() organizationName: string;
    @Input() moduleType: string;

    appRejIconStyling(item: any, organizationName: string, moduleType: string, isIconType = false): string {
        let statusCodes: string[];
        switch (moduleType) {
            case 'VesselNotification':
                statusCodes = ['X', 'Z', 'D', 'U'];
                break;
            case 'Conveyance':
                statusCodes = ['CN', 'X', 'D', 'U'];
                break;
            case 'VesselPermit':
                statusCodes = ['CAN', 'DIS', 'DRA', 'AMN'];
                break;
            case 'VesselShift':
                statusCodes = ['C', 'X', 'D', 'U'];
                break;
        }

        if (!statusCodes.includes(item?.statusId) &&
            ((organizationName !== 'customs' && item.notificationTypeId !== 'D') ||  // VN bubbles #23856
            (!['health', 'iSPS'].includes(organizationName) && item.notificationTypeId !== 'A')) &&
            (item?.portOfCallId === 'MTWTW' && organizationName !== 'immigration' || item?.portOfCallId !== 'MTWTW')) {
            if (item?.[organizationName ? `${organizationName}ApprovalDate` : 'approvalDate']) {
                return isIconType ? 'check-circle' : 'greenIcon';
            } else if (item?.[organizationName ? `${organizationName}RejectionDate` : 'rejectionDate']) {
                return isIconType ? 'times-circle' : 'redIcon';
            } else if (['W', 'WAP', 'WEX', 'WA'].includes(item?.statusId)) {
                return isIconType ? 'clock' : 'orangeIcon';
            }
        }

        return isIconType ? 'circle' : 'grayIcon';
    }
}
