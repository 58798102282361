import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { AppGridComponent } from '@common/components/app-grid/app-grid.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { DialogService } from '@common/services/dialog.service';
import { VesselNotificationDefectsModalComponent } from '../../../vessel-notification/components/vessel-notification-defects-modal/vessel-notification-defects-modal.component';
import { User } from '@common/models/User';

@Component({
    selector: 'app-vessel-short-notification-grid',
    templateUrl: './vessel-short-notification-grid.component.html',
    styleUrls: ['./vessel-short-notification-grid.component.scss']
})
export class VesselShortNotificationGridComponent {
    @Input() query;
    @Input() selection = [];
    @Input() user: User;
    @Input() disableGridClickFilter: boolean;

    @Output() dataChange = new EventEmitter();
    @Output() rowParameterClicked = new EventEmitter();

    @ViewChild(AppGridComponent) public appGrid: AppGridComponent;

    constructor(
        private dialogService: DialogService,
        private breezeViewService: BreezeViewService) { }

    canSeeRemarks(vesselNotification) {
        const hasPermission = this.user?.hasPermission(VesselNotificationPermissions.View.remarks);
        if (this.user?.isAgent()) return hasPermission && !!vesselNotification.portRemarks;
        return hasPermission;
    }

    async openDefectsModal(vnId) {
        this.dialogService.open(VesselNotificationDefectsModalComponent, { size: 'md' },
            dialogRef => dialogRef.componentInstance.vesselNotificationId = vnId);
    }

    async openRemarksModal(vesselNotification) {
        const data = await this.dialogService.form(
            {
                title: 'Vessel Notification Remarks',
                properties: [
                    {
                        name: 'portRemarks',
                        type: AppControlType.TextArea,
                        label: 'Remarks',
                        initialValue: vesselNotification.portRemarks,
                        isDisabled: () => !this.user?.hasPermission(VesselNotificationPermissions.Edit.remarks)
                    }
                ],
                confirmText: 'Save',
            }, { size: 'md', keyboard: false });
        if (!data) return;
        await this.breezeViewService.handleCommand('SetVesselNotificationPortRemarks', { id: vesselNotification.id, portRemarks: data.portRemarks });
        this.dataChange.emit();
    }

    isRunningLate(row): number {
        return (row.notificationTypeId === 'A' && row.statusId !== 'AR') || (row.notificationTypeId === 'D' && row.statusId !== 'DP') ? (Date.parse(row[`portOfCallEt${row.notificationTypeId.toLowerCase()}`]) - Date.now()) / 3_600_000 : 9001;
    }

    emitFilterData(value, propertyName) {
        if (this.disableGridClickFilter) return;
        this.rowParameterClicked.emit({ value, propertyName });
    }

    isRestricted(statusId: string, showToolTip = false) {
        return showToolTip ? statusId === 'DT' ? 'Detained Vessel' : 'Alert Vessel' : ['DT', 'BN'].includes(statusId);
    }
}
