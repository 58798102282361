import { Component, ViewChild } from '@angular/core';
import { EntityQuery } from '@cime/breeze-client';
import { ConveyancePermissions, VesselNotificationPermissions, VesselPermitPermissions, VesselShiftPermissions, VesselShortNotificationPermissions } from '@common/classes/permissions';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { ConveyanceGridComponent } from 'app/conveyance/components/conveyance-grid/conveyance-grid.component';
import { VesselNotificationGridComponent } from 'app/vessel-notification/components/vessel-notification-grid/vessel-notification-grid.component';
import { VesselPermitGridComponent } from 'app/vessel-permit/components/vessel-permit-grid/vessel-permit-grid.component';
import { VesselShiftGridComponent } from 'app/vessel-shift/components/vessel-shift-grid/vessel-shift-grid.component';
import { VesselShortNotificationGridComponent } from 'app/vessel-short-notification/components/vessel-short-notification-grid/vessel-short-notification-grid.component';

@Component({
    selector: 'app-task-manager',
    templateUrl: './task-manager.component.html',
    styleUrls: ['./task-manager.component.scss'],
    providers: [BreezeViewService]
})
export class TaskManagerComponent {
    userSubscription;
    vesselNotificationQuery;
    vesselPermitQuery;
    conveyanceQuery;
    vesselShiftQuery;
    vesselShortNotificationQuery;
    activeTab = 0;
    user: User;

    @ViewChild(VesselNotificationGridComponent)
    public vesselNotificationGrid: VesselNotificationGridComponent;

    @ViewChild(VesselPermitGridComponent)
    public vesselPermitGrid: VesselPermitGridComponent;

    @ViewChild(ConveyanceGridComponent)
    public conveyanceGrid: ConveyanceGridComponent;

    @ViewChild(VesselShiftGridComponent)
    public vesselShiftGrid: VesselShiftGridComponent;

    @ViewChild(VesselShortNotificationGridComponent)
    public vesselShortNotificationGrid: VesselShortNotificationGridComponent;

    constructor(breezeViewService: BreezeViewService) {
        const user = breezeViewService.userService.currentUserSubject.getValue();
        this.user = user;

        if (!user) return;

        if (user.hasPermission(VesselNotificationPermissions.View.own, VesselNotificationPermissions.View.all)) {
            const statusParameters = this.user?.isAgent() ? ['W', 'D'] : this.user?.isVTSAgent() ? ['W', 'A'] : ['W'];
            this.vesselNotificationQuery = EntityQuery.from('VesselNotifications')
                .withParameters({
                    $method: 'POST',
                    $data: { statusIds: statusParameters, filterByOrganization: this.user?.isVTSAgent() ? false : true, isShort: false }
                });
        }

        if (user.hasPermission(VesselPermitPermissions.View.own, VesselPermitPermissions.View.all)) {
            const statusParameters = this.user?.isAgent() ? ['WAP', 'DRA', 'WEX'] : ['WAP', 'WEX'];
            this.vesselPermitQuery = EntityQuery.from('VesselPermits')
                .withParameters({
                    $method: 'POST',
                    $data: { statusIds: statusParameters, filterByOrganization: true }
                });
        }

        if (user.hasPermission(ConveyancePermissions.View.own, ConveyancePermissions.View.all)) {
            const statusParameters = this.user?.isAgent() ? ['W', 'D'] : ['W'];
            this.conveyanceQuery = EntityQuery.from('Conveyances')
                .withParameters({
                    $method: 'POST',
                    $data: { statusIds: statusParameters, filterByOrganization: true }
                });
        }

        if (user.hasPermission(VesselShiftPermissions.View.own, VesselShiftPermissions.View.all)) {
            const statusParameters = this.user?.isAgent() ? ['W', 'D'] : ['W'];
            this.vesselShiftQuery = EntityQuery.from('VesselShifts')
                .withParameters({
                    $method: 'POST',
                    $data: { statusIds: statusParameters, filterByOrganization: true }
                });
        }

        if (user.hasPermission(VesselShortNotificationPermissions.View.own, VesselShortNotificationPermissions.View.all)) {
            const statusParameters = this.user?.isAgent() ? ['W', 'D'] : ['W'];
            this.vesselShortNotificationQuery = EntityQuery.from('VesselNotifications')
                .withParameters({
                    $method: 'POST',
                    $data: { statusIds: statusParameters, filterByOrganization: this.user?.isPort() ? false : true, isShort: true }
                });
        }
    }

    activateTab(tab) {
        this.activeTab = this.activeTab === tab ? 0 : tab;
    }

    hasAnyPermissions(): boolean {
        return this.user?.hasPermission(VesselNotificationPermissions, VesselPermitPermissions, ConveyancePermissions, VesselShiftPermissions, VesselShortNotificationPermissions);
    }

    isTabVisible(tabs: any[]) {
        let res = false;
        tabs.forEach(tab => {
            switch (tab) {
                case 1:
                    res ||= !!this.vesselNotificationQuery;
                    break;
                case 2:
                    res ||= !!this.vesselPermitQuery;
                    break;
                case 3:
                    res ||= !!this.vesselShortNotificationQuery;
                    break;
                case 4:
                    res ||= !!this.conveyanceQuery;
                    break;
                case 5:
                    res ||= !!this.vesselShiftQuery;
                    break;
            }
        });

        return res;
    }
}
