import { Component, Input, OnInit } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { EntityFormOptions } from '@common/classes/entity-form';
import { ExportType } from '@common/classes/export-type';
import { VesselNotificationPermissions } from '@common/classes/permissions';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { User } from '@common/models/User';
import { BreezeViewService } from '@common/services/breeze-view.service';

@Component({
    selector: 'app-vessel-notification-passenger',
    templateUrl: './vessel-notification-passenger.component.html',
    styleUrls: ['./vessel-notification-passenger.component.scss']
})
export class VesselNotificationPassengerComponent extends AbstractBreezeListComponent implements OnInit {
    queryName = 'VesselNotificationPassengers';
    exportCommand = 'ExportVesselNotificationPaxList';
    persistFilter = false;
    passengerOptions = this.getPassengerOptions();
    paxTotal = 0;
    paxTransit = 0;

    @Input() model: any;
    @Input() canEdit: boolean;
    @Input() editMode: any;
    @Input() user: User;

    constructor(breezeViewService: BreezeViewService) { super(breezeViewService); }

    override ngOnInit(): void {
        super.ngOnInit();
        this.getPaxTotal();
    }

    getPassengerOptions(): EntityFormOptions {
        return {
            entityName: 'VesselNotificationPassenger',
            canEdit: () => this.canEdit,
            beforeAdd: (data) => data.sequenceNumber = this.model.passengers.length + 1,
            onAdd: (entity) => {
                // Entity form adds the item to a filtered array, we have to manually add the entity to the real array
                this.model.passengers.push(entity);
                this.calculatePassengers();
                this.model.entityAspect.validateEntity();
            },
            onRemove: () => {
                this.calculatePassengers();
                this.model.entityAspect.validateEntity();
            },
            propertyGroups: [
                [
                    { name: 'familyName', label: 'Family Name', maxlength: 100, pattern: '[^0-9]' },
                    { name: 'givenName', label: 'Given Name', maxlength: 100, pattern: '[^0-9]' },
                    { name: 'nationalityId', label: 'Nationality', type: AppControlType.CodeList, codelist: 'CountryNationality' },
                    { name: 'birthDate', label: 'Date of Birth', type: AppControlType.DateTime },
                ],
                [
                    { name: 'birthPlace', label: 'Place of Birth', maxlength: 100 },
                    { name: 'genderId', label: 'Gender', type: AppControlType.CodeList, codelist: 'Gender' },
                    { name: 'documentTypeId', label: 'Type of ID', type: AppControlType.CodeList, codelist: 'DocumentType' },
                    { name: 'documentNumber', label: 'ID Number', maxlength: 20 },
                ],
                [
                    { name: 'documentIssuingCountryId', label: 'ID Issuing Country', type: AppControlType.CodeList, codelist: 'Country' },
                    { name: 'documentExpirationDate', label: 'ID Expiration Date', type: AppControlType.DateTime },
                    { name: 'embarkationPortId', label: 'Port of Embarkation', type: AppControlType.CodeList, codelist: 'Location' },
                    { name: 'disembarkationPortId', label: 'Port of Disembarkation', type: AppControlType.CodeList, codelist: 'Location' },
                ],
                [
                    { name: 'specialCareOrAssistanceInformation', label: 'Special care or assistance information', maxlength: 250, colSize: 3 },
                    { name: 'contactNumber', label: 'Contact Number', pattern: '[\\d]', prepend: '+', maxlength: 20, colSize: 3 },
                    { name: 'permitNumber', label: 'Visa Number', maxlength: 20, colSize: 3 }
                ]
            ]
        };
    }

    override getDefaultFilter() {
        return {
            id: this.model?.id,
        };
    }

    calculatePassengers() {
        this.model.numberOfPax = this.model.passengers?.length;
        this.model.personsOnBoard = this.model.numberOfPax + this.model.workers?.length + (this.model.numberOfStowaways || 0);
        this.getPaxTotal();
    }

    getPaxTotal() {
        this.paxTotal = this.model.passengers.filter(x => this.model.notificationTypeId === 'A' ? x.disembarkationPortId === 'MTMLA' : x.embarkationPortId === 'MTMLA')?.length;
        this.paxTransit = this.model.passengers.filter(x => x.transit)?.length;
    }

    override export() {
        return super.export(ExportType.Excel, this.appGrid);
    }

    canExport() {
        return this.user?.hasPermission(VesselNotificationPermissions.Action.exportPaxList);
    }

    get canSeeFilterAndExport() {
        return !this.editMode && ['D', 'A', 'W', 'R', 'AR', 'DP'].includes(this.model.statusId);
    }
}
