import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@common/common.module';
import { CanDeactivateView } from '@common/guards/view.deactivate';
import { ViewMode } from '@common/models/view-mode';
import { EditorModule } from '@progress/kendo-angular-editor';
import { VesselPermitGridComponent } from './components/vessel-permit-grid/vessel-permit-grid.component';
import { VesselPermitListComponent } from './components/vessel-permit-list/vessel-permit-list.component';
import { VesselPermitPrintComponent } from './components/vessel-permit-print/vessel-permit-print.component';
import { VesselPermitViewComponent } from './components/vessel-permit-view/vessel-permit-view.component';

export const VESSEL_PERMIT_ROUTES: Routes = [
    {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full'
    },
    {
        path: 'list',
        component: VesselPermitListComponent
    },
    {
        path: 'create',
        component: VesselPermitViewComponent,
        data: {
            mode: ViewMode.create
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'edit/:id',
        component: VesselPermitViewComponent,
        data: {
            mode: ViewMode.edit
        },
        canDeactivate: [CanDeactivateView]
    },
    {
        path: 'view/:id',
        component: VesselPermitViewComponent,
        data: {
            mode: ViewMode.view
        }
    },
    {
        path: '**',
        redirectTo: 'list'
    },
    {
        path: 'print/:ids',
        outlet: 'print',
        component: VesselPermitPrintComponent,
    }
];

@NgModule({
    declarations: [VesselPermitListComponent, VesselPermitViewComponent, VesselPermitGridComponent, VesselPermitPrintComponent],
    exports: [VesselPermitGridComponent],
    imports: [CommonModule, EditorModule],
    providers: [CanDeactivateView]
})
export class VesselPermitComponentModule { }

@NgModule({
    imports: [CommonModule, VesselPermitComponentModule, RouterModule.forChild(VESSEL_PERMIT_ROUTES)]
})
export class VesselPermitModule { }
