<section class="content-header" tabindex="-1" id="content-header">
    <h1 class="mt-3" *ngIf="title">
        <i style="margin-left: -10px; font-size: 20px; padding-right: 10px;"></i>
        <span [ngStyle]="{'color': titleColor}" translate>{{title}}</span>
    </h1>
    <ol class="breadcrumb">
        <li class="breadcrumb-item">
            <a class="active" aria-current="page" [routerLink]="['/']">
                <span translate>Home</span>
                <fa-icon icon="chevron-right"></fa-icon>
            </a>
        </li>
        <li class="breadcrumb-item" *ngFor="let item of breadcrumb">
            <a class="active" aria-current="page" [routerLink]="[item.route]" *ngIf="item.route">
                <span translate>{{item.title}}</span>
                <fa-icon icon="chevron-right"></fa-icon>
            </a>
            <span class="active" aria-current="page" *ngIf="!item.route">
                <span translate>{{item.title}}</span>
            </span>
        </li>
    </ol>
</section>