<ngb-accordion activeIds="manifest-list">
    <ngb-panel id="manifest-list">
        <ng-template ngbPanelHeader let-panel>
            <h5 style="width: auto;" translate>Manifests</h5>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="row">
                <div class="col">
                    <app-editable-grid [data]="editMode ? model.manifests : query"
                        entityName="Manifest" [editMode]="editMode"
                        [canEdit]="canEditManifest() && canEdit"
                        [canExport]="canExcelExport()"
                        (export)="onExcelExport($event)"
                        (rowAction)="updateSequenceNumber(model.manifests)"
                        [initialData]="{ vesselName: model.vessel.name, iMONumber: model.vessel.iMONumber,
                                        deadW: model.vessel.deadWeight, portOfCall: model.portOfCallId,
                                        flag: model.vessel.countryId, previousPortCall: model.lastPortId,
                                        dateCreated: model.createdDate, dateCompleted: model.createdDate,
                                        eDDate: model.portOfCallEtd, shippingAgent: model.agent.name,
                                        grossTonage: model.vessel.grossTonnage, uploadOrganization: user.organization?.name || user.fullName,
                                        direction: model.notificationTypeId !== 'A'
                                    }">
                        <kendo-grid-column title="{{'Manifest Agent' | translate}}" field="uploadOrganization" [width]="140"></kendo-grid-column>
                        <kendo-grid-column title="{{'Vessel Name' | translate}}" field="vesselName" [width]="110"></kendo-grid-column>
                        <kendo-grid-column title="{{'Vessel Flag' | translate}}" field="flag" [width]="55"></kendo-grid-column>
                        <kendo-grid-column title="{{'Vessel DW' | translate}}" field="deadW" [width]="90"></kendo-grid-column>
                        <kendo-grid-column title="{{'Gross Tonnage' | translate}}" field="grossTonage" [width]="90"></kendo-grid-column>
                        <kendo-grid-column title="{{'IMO Number' | translate}}" field="iMONumber" [width]="100"></kendo-grid-column>
                        <kendo-grid-column title="{{'Shipping Agent' | translate}}" field="shippingAgent" [width]="150"></kendo-grid-column>
                        <kendo-grid-column title="{{'Direction' | translate}}" field="direction" [width]="120">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.direction ? 'Export' : 'Import'}}
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="{{'Port of Call' | translate}}" field="portOfCall" [width]="100"></kendo-grid-column>
                        <kendo-grid-column title="{{'Previous Port of Call' | translate}}" field="previousPortCall" [width]="115"></kendo-grid-column>
                        <kendo-grid-column title="{{'Manifest No.' | translate}}" field="manifestNumber" [width]="115">
                            <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                <app-control [(ngModel)]="dataItem.manifestNumber" [entity]="dataItem" property="manifestNumber"></app-control>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="{{'B/L Count' | translate}}" field="bLCount" [width]="50"></kendo-grid-column>
                        <kendo-grid-column title="{{'Created Date' | translate}}" field="createdDate" format="g" [width]="100"></kendo-grid-column>
                        <kendo-grid-column title="{{'Created By' | translate}}" field="createdById" [width]="150">
                            <ng-template kendoGridCellTemplate let-row>
                                {{row.createdById | codelist:'User' | async}}
                            </ng-template>
                        </kendo-grid-column>
                        <ng-template kendoGridDetailTemplate let-manifest>
                            <app-editable-grid [data]="manifest.billOfLadings" entityName="BillOfLading"
                                [editMode]="editMode" [canEdit]="canEditManifest() && canEdit"
                                (rowAction)="updateSequenceNumber(manifest.billOfLadings)">
                                <kendo-grid-column title="{{'Sq.' | translate}}" field="sequenceNumber" [width]="30"></kendo-grid-column>
                                <kendo-grid-column title="{{'Serial Number' | translate}}" field="serialNumber" [width]="100">
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                        <app-control [(ngModel)]="dataItem.serialNumber" [entity]="dataItem" property="serialNumber"></app-control>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Consignee' | translate}}" field="consignee" [width]="200">
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                        <app-control [(ngModel)]="dataItem.consignee" [entity]="dataItem" property="consignee"></app-control>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Notify Party' | translate}}" field="notifyParty" [width]="200">
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                        <app-control [(ngModel)]="dataItem.notifyParty" [entity]="dataItem" property="notifyParty"></app-control>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Load Discharge Port' | translate}}" [width]="120">
                                    <ng-template kendoGridCellTemplate let-row>
                                        {{row.loadDischargePortId | codelist: 'Location' | async}}
                                    </ng-template>
                                    <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                        <app-control type="codelist" codelist="Location"
                                            [(ngModel)]="dataItem.loadDischargePortId" [entity]="dataItem" property="loadDischargePortId"></app-control>
                                    </ng-template>
                                </kendo-grid-column>
                                <kendo-grid-column title="{{'Cargo Item Count' | translate}}" field="cargoItemCount" [width]="50"></kendo-grid-column>
                                <ng-template kendoGridDetailTemplate let-billOfLading>
                                    <app-editable-grid [data]="billOfLading.cargoItems"
                                        entityName="CargoItem" [editMode]="editMode"
                                        [canEdit]="canEditManifest() && canEdit"
                                        (rowAction)="updateSequenceNumber(billOfLading.cargoItems)">
                                    <kendo-grid-column title="{{'Sq.' | translate}}" field="sequenceNumber" [width]="30"></kendo-grid-column>
                                    <kendo-grid-column title="{{'Cargo Type' | translate}}" [width]="150">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{!!row.manifestCargoTypeId ? (row.manifestCargoTypeId | codelist:'ManifestCargoType' | async) : '99'}}
                                        </ng-template>
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control type="codelist" codelist="ManifestCargoType"
                                                [(ngModel)]="dataItem.manifestCargoTypeId" [entity]="dataItem" property="manifestCargoTypeId"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Empty' | translate}}" [width]="80">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{row.empty === null ? '-' : row.empty ? 'Yes' : 'No'}}
                                        </ng-template>
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control type="yesno" [(ngModel)]="dataItem.empty" [entity]="dataItem" property="empty"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Marks' | translate}}" field="cargoMarks" [width]="100">
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control [(ngModel)]="dataItem.cargoMarks" [entity]="dataItem" property="cargoMarks"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Commodity Classification' | translate}}" [width]="220">
                                        <ng-template kendoGridCellTemplate let-row>
                                            {{row.commodityId | codelist:'Commodity' | async}}
                                        </ng-template>
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control type="codelist" codelist="Commodity" [(ngModel)]="dataItem.commodityId" [entity]="dataItem" property="commodityId"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Description' | translate}}" field="description" [width]="220">
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control [(ngModel)]="dataItem.description" [entity]="dataItem" property="description"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Quantity' | translate}}" field="quantity" [width]="50">
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control type="number" format="'#'" [(ngModel)]="dataItem.quantity" [entity]="dataItem" property="quantity">
                                            </app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    <kendo-grid-column title="{{'Gross Tonnage' | translate}}" field="grossTonnage" format="n3" [width]="100">
                                        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
                                            <app-control type="number" format="n3" [decimals]="3"
                                                [(ngModel)]="dataItem.grossTonnage" [entity]="dataItem" property="grossTonnage"></app-control>
                                        </ng-template>
                                    </kendo-grid-column>
                                    </app-editable-grid>
                                </ng-template>
                            </app-editable-grid>
                        </ng-template>
                    </app-editable-grid>
                </div>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
