import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IdleWarningDialogComponent } from '@common/components/idle-warning-dialog.component';
import { User } from '@common/models/User';
import { environment } from '@environments/environment';
import { serverUrl } from '@environments/environment.base';
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { CqrsService } from './cqrs.service';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class WebsocketsService {
    private modal: NgbModalRef;
    private hubConnection: HubConnection;
    private connectionId;
    private logData: any[] = [];

    constructor(
        private userIdleService: UserIdleService,
        private router: Router,
        private modalService: NgbModal,
        private userService: UserService,
        private cqrs: CqrsService) {
        new BroadcastChannel('IDLE_POPUP_CLOSED').onmessage = () => this.dismissUserIdle();
        this.initialize();
    }

    initialize() {
        this.configureUserIdle();
        this.configureSignalR();
        this.userService.currentUserSubject.subscribe((user) => {
            if (user) {
                if (this.hubConnection.state !== HubConnectionState.Connected
                    && this.hubConnection.state !== HubConnectionState.Connecting) {
                    return this.hubConnection.start()
                        .then(() => {
                            this.connectionId = this.hubConnection.connectionId;
                            return this.flush();
                        })
                        .catch((err) => console.error(`Error while establishing SignalR connection: ${err}`));
                }
            } else if (this.hubConnection.state !== HubConnectionState.Disconnected
                && this.hubConnection.state !== HubConnectionState.Disconnecting) {
                return this.hubConnection.stop();
            }
        });
    }

    configureUserIdle() {
        this.userIdleService.startWatching();
        // Event when user is idle for <'idle' config value> in seconds
        this.userIdleService.onTimerStart().subscribe(async count => {
            if (!count) return;

            if (this.modal || this.router.url.includes('login')) this.userIdleService.resetTimer();
            else this.openIdleModal();
        });
    }

    configureSignalR() {
        const wsPath = !environment.production ? ':5000' : '/be';
        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${serverUrl}${wsPath}/hubs/ws`, {
                accessTokenFactory: () => this.userService.getAccessToken(),
                transport: HttpTransportType.ServerSentEvents
            })
            .configureLogging(environment.production ? LogLevel.Warning : LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        // Reset idle timer
        this.hubConnection.on('UserActive', () => this.userIdleService.startWatching());

        this.hubConnection.on('TotalClients', (totalClients) => {
            if (!environment.production) console.info('TotalClients', totalClients);
        });
    }

    // Mark a user as active every minute if there's an action (e.g. mouse movevement)
    // Used to prevent showing the idle warning popup in other instances of the app (another tab/window)
    setUserActive(currentUser: User) {
        if (!currentUser) return;
        this.cqrs.command('SetUserActive', {});
        new BroadcastChannel('IDLE_POPUP_CLOSED').postMessage(true);
        this.dismissUserIdle();
    }

    async openIdleModal() {
        this.modal = this.modalService.open(IdleWarningDialogComponent, { centered: true, backdrop: 'static', size: 'lg' });
        const data = await this.modal.result
            .catch(reason => {
                if ([ModalDismissReasons.ESC, ModalDismissReasons.BACKDROP_CLICK].includes(reason)) { return; }

                throw reason;
            });
        if (!data) return this.dismissUserIdle();
    }

    dismissUserIdle() {
        this.modal?.close();
        this.userIdleService.resetTimer();
        this.modal = null;
    }

    private flush() {
        this.logData.forEach(data => {
            this.hubConnection.invoke(data.level, data.message)
                .then(data.resolve)
                .catch(data.reject);
        });
        this.logData = [];
    }


    log(message: string, level: string) {
        if (this.hubConnection.state === HubConnectionState.Connected) {
            return this.hubConnection.invoke(level, message)
                .catch((error) => console.error(error));
        }
        return new Promise((resolve, reject) => {
            this.logData.push({
                level,
                message,
                resolve,
                reject
            });
        });
    }

    invoke(method: string, ...args: any[]) {
        if (this.hubConnection.state !== HubConnectionState.Connected) return;
        return this.hubConnection.invoke(method, ...args);
    }

    on(methodName: string, callback: (...args: any[]) => void) {
        this.hubConnection.on(methodName, callback);
    }

    info(message: string) {
        return this.log(message, 'info');
    }

    warn(message: string) {
        return this.log(message, 'warn');
    }

    error(message: string) {
        return this.log(message, 'error');
    }

    getConnectionState() {
        return this.hubConnection.state;
    }
}
