<div class="row" *ngIf="!notification?.isShort || notification?.isShort && notification?.typeId !== 'D'">
    <div class="col mt-5 font-weight-bold">
        <h5>If the vessel's flag is black listed, please add the certificates below</h5>
        <h6 *ngIf="notification?.isShort">* Include Certificates and Documents to be carried in line with the Port Notice
        </h6>
    </div>
</div>
<app-entity-form [options]="certificateFormOptions" [grid]="certificatesGrid"></app-entity-form>
<app-grid #certificatesGrid [data]="model.certificates">
    <kendo-grid-column title="{{'Type' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.typeId | codelist:'CertificateType' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Country' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            {{row.countryId | codelist:'Country' | async}}
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Issue Date' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{row.issueDate | date:'short'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Permanent' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <app-checkbox [disabled]="true" [(ngModel)]="row.permanent"></app-checkbox>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column title="{{'Expiration Date' | translate}}">
        <ng-template kendoGridCellTemplate let-row>
            <span>{{row.expirationDate | date:'short'}}</span>
        </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="description" title="{{'Description' | translate}}"></kendo-grid-column>
    <kendo-grid-column class="overflow-unset" [hidden]="editMode" [width]="100">
        <ng-template kendoGridCellTemplate let-row>
            <app-upload-action-buttons [file]="row.attachment" [disabled]="true" [multiple]="false"></app-upload-action-buttons>
        </ng-template>
    </kendo-grid-column>
</app-grid>