import { Component, Input } from '@angular/core';
import { AbstractBreezeListComponent } from '@common/classes/breeze-list';
import { User } from '@common/models/User';
import { CodelistPipe } from '@common/pipes/codelist.pipe';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { GridEditService } from '@common/services/grid-edit.service';
import { Workbook } from '@progress/kendo-angular-excel-export';
import { saveAs } from '@progress/kendo-file-saver';
import _ from 'lodash';
@Component({
    selector: 'app-vessel-notification-manifest',
    templateUrl: './vessel-notification-manifest.component.html',
    styleUrls: ['./vessel-notification-manifest.component.scss'],
    providers: [GridEditService, CodelistPipe]
})
export class VesselNotificationManifestComponent extends AbstractBreezeListComponent {
    queryName = 'VesselNotificationManifest';

    @Input() model: any;
    @Input() editMode: any;
    @Input() canEdit: boolean;
    @Input() user: User;

    exportImportValues = [
        { value: true, text: 'Export' },
        { value: false, text: 'Import' }
    ];

    constructor(breezeViewService: BreezeViewService,
        public gridEdit: GridEditService,
        private codelistPipe: CodelistPipe) {
        super(breezeViewService);
    }

    updateSequenceNumber(collection: any[]): void {
        _.each(collection, (p: any, i) => {
            p.sequenceNumber = i + 1;
            p.bLCount = p.billOfLadings?.length;
            p.cargoItemCount = p.cargoItems?.length;
        });
    }

    override getDefaultFilter() {
        return {
            id: this.model?.id
        };
    }

    canEditManifest() {
        return this.model.manifestStatusId !== 'COM';
    }

    canEditBillOfLading(manifest) {
        return manifest.createdBy?.organizationId === this.user?.organizationId || this.user?.isSystemUser;
    }

    canEditCargoItem(billOfLading) {
        return billOfLading.createdBy?.organizationId === this.user?.organizationId || this.user?.isSystemUser;
    }

    canExcelExport(): boolean {
        return !this.editMode && this.model.manifests.some(x => !x.isFromCargoData);
    }

    async onExcelExport(args: any): Promise<void> {
        const workbook = args.workbook;
        const rows = workbook.sheets[0].rows;
        const columns = workbook.sheets[0].columns;
        const headerOptions = rows[0].cells[0];

        const manifests = [];
        this.model.manifests.filter(x => !x.isFromCargoData).forEach(manifest => manifests.push(this.breezeService.convertToDto(manifest)));

        for (let i = rows.length - 1; i >= 1; i--) {
            const billOfLadings = manifests[i - 1].billOfLadings;
            for (let j = billOfLadings.length - 1; j >= 0; j--) {
                const cargoItems = billOfLadings[j].cargoItems;
                for (let k = cargoItems.length - 1; k >= 0; k--) {
                    const cargoitemCells = [];
                    cargoitemCells.push(
                        {},
                        {},
                        { value: cargoItems[k].sequenceNumber },
                        { value: await this.codelistPipe.transform(cargoItems[k].manifestCargoTypeId, 'ManifestCargoType') },
                        { value: cargoItems[k].empty === null ? '-' : cargoItems[k].empty ? 'Yes' : 'No' },
                        { value: cargoItems[k].cargoMarks },
                        { value: await this.codelistPipe.transform(cargoItems[k].commodityId, 'Commodity') },
                        { value: cargoItems[k].description },
                        { value: cargoItems[k].quantity },
                        { value: cargoItems[k].grossTonnage }
                    );

                    rows.splice(i + 1, 0, {
                        type: 'data',
                        cells: cargoitemCells
                    });
                }

                const cargoItemHeaderCells = [];
                cargoItemHeaderCells.push(
                    {},
                    {},
                    Object.assign({}, headerOptions, { value: 'Sq.' }),
                    Object.assign({}, headerOptions, { value: 'Cargo Type' }),
                    Object.assign({}, headerOptions, { value: 'Empty' }),
                    Object.assign({}, headerOptions, { value: 'Marks' }),
                    Object.assign({}, headerOptions, { value: 'Commodity Classification' }),
                    Object.assign({}, headerOptions, { value: 'Description' }),
                    Object.assign({}, headerOptions, { value: 'Quantity' }),
                    Object.assign({}, headerOptions, { value: 'Gross Tonnage' })
                );

                rows.splice(i + 1, 0, {
                    type: 'header',
                    cells: cargoItemHeaderCells
                });

                const billOfLadingCells = [];
                billOfLadingCells.push(
                    {},
                    { value: billOfLadings[j].sequenceNumber },
                    { value: billOfLadings[j].serialNumber },
                    { value: billOfLadings[j].consignee },
                    { value: billOfLadings[j].notifyParty },
                    { value: billOfLadings[j].loadDischargePortId },
                    { value: billOfLadings[j].cargoItemCount }
                );

                rows.splice(i + 1, 0, {
                    type: 'data',
                    cells: billOfLadingCells
                });

                const billOfLadingHeaderCells = [];
                billOfLadingHeaderCells.push(
                    {},
                    Object.assign({}, headerOptions, { value: 'Sq.' }),
                    Object.assign({}, headerOptions, { value: 'Serial Number' }),
                    Object.assign({}, headerOptions, { value: 'Consignee' }),
                    Object.assign({}, headerOptions, { value: 'Notify Party' }),
                    Object.assign({}, headerOptions, { value: 'Load Discharge Port' }),
                    Object.assign({}, headerOptions, { value: 'Cargo Item Count' })
                );

                rows.splice(i + 1, 0, {
                    type: 'header',
                    cells: billOfLadingHeaderCells
                });
            }
            //Created By override to use codelist
            ((rows[i].cells as []).at(7) as { value: any }).value = ((rows[i].cells as []).at(7) as { value: any }).value ? 'Export' : 'Import';
            ((rows[i].cells as []).at(-1) as { value: any }).value = await this.codelistPipe.transform(((rows[i].cells as []).at(-1) as { value: any }).value, 'User');

            if (i > 1) { //Add empty row and headers for each manifest
                rows.splice(i, 0, {});
                rows.splice(i + 1, 0, {
                    type: 'header',
                    cells: rows[0].cells
                });
            }
        }

        columns.forEach(column => {
            column.autoWidth = false;
            column.width = 150;
        });

        new Workbook(workbook).toDataURL().then((dataUrl: string) => {
            saveAs(dataUrl, `VesselNotification-${this.model?.yearNumber}_Manifests_Export.xlsx`);
        });
    }
}
