import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { DataService, DataType, Entity, EntityError, EntityManager, EntityQuery, QueryResult, SaveOptions } from '@cime/breeze-client';
import { HasEntityGraph } from '@cime/breeze-client/mixin-get-entity-graph';
import { ValidationOptionsConfig } from '@cime/breeze-client/src/validation-options';
import { BreezeEntity } from '@common/classes/breeze-entity';
import { IActionBarGroup } from '@common/components/action-bar/action-bar.component';
import { AppControlType } from '@common/components/app-control/app-control.component';
import { EntitiesErrorsComponent } from '@common/components/entities-errors/entities-errors.component';
import { IDeactivableComponent } from '@common/guards/view.deactivate';
import { User } from '@common/models/User';
import { ViewMode } from '@common/models/view-mode';
import { BreezeViewService } from '@common/services/breeze-view.service';
import { BreezeService } from '@common/services/breeze.service';
import { ConsoleWindowService } from '@common/services/console-window.service';
import { DialogService } from '@common/services/dialog.service';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EntitiesErrors } from './entities-errors';
import { VesselPermissions } from './permissions';

@Component({ template: '' })
export abstract class AbstractBreezeViewComponent implements OnInit, IDeactivableComponent, OnDestroy {
    abstract entityName: string;
    parentRoute: string;
    editPermission: string;
    createPermission: string;
    public mode: ViewMode;
    protected id: number | string;
    readonly viewMode: boolean;
    readonly editMode: boolean;

    protected breezeService: BreezeService;
    protected consoleWindowService: ConsoleWindowService;
    protected dialogService: DialogService;
    protected editActionBarGroup: IActionBarGroup;
    protected router: Router;
    protected translateService: TranslateService;
    protected toastrService: ToastrService;
    private userSubscription: Subscription;

    actionBar: IActionBarGroup[];
    entityManager: EntityManager;
    isBusy = false;
    user: User;

    protected _model: BreezeEntity;
    public get model(): BreezeEntity {
        return this._model;
    }
    public set model(value: BreezeEntity) {
        this._model = value;
    }

    get title() {
        return this.mode === ViewMode.create ?
            `${this.translateService.instant('New')} ${this.translateService.instant(this.getFormattedTitle(this.entityName))}`
            : `${this.translateService.instant(this.getFormattedTitle(this.entityName))} - ${this.getIdentifier()}`;
    }

    get isNew() {
        return this.model && this.model.entityAspect.entityState.isAdded();
    }

    protected constructor(public breezeViewService: BreezeViewService) {
        this.entityManager = breezeViewService.entityManager;
        this.translateService = breezeViewService.translateService;
        this.toastrService = breezeViewService.toastrService;
        this.breezeService = breezeViewService.breezeService;
        this.consoleWindowService = breezeViewService.consoleWindowService;
        this.dialogService = breezeViewService.dialogService;
        this.router = breezeViewService.router;
        this.mode = breezeViewService.activatedRoute.snapshot.data.mode;
        this.id = breezeViewService.activatedRoute.snapshot.params.id;
        this.viewMode = this.mode === ViewMode.view;
        this.editMode = !this.viewMode;

        this.parentRoute = window.location.pathname.split('/').slice(0, this.mode === ViewMode.create ? -1 : -2).join('/');

        this.entityManager.setProperties({ validationOptions: this.entityManager.validationOptions.using(this.getValidationOptions()) });
        this.userSubscription = breezeViewService.userService.currentUserSubject.subscribe((user) => this.user = user);

        this.editActionBarGroup = {
            label: this.translateService.instant('Actions'),
            items: [
                {
                    label: this.translateService.instant('Save'),
                    icon: 'save',
                    isDisabled: () => !this.hasChanges(),
                    isVisible: () => !this.viewMode,
                    onClick: () => this.saveChanges()
                },
                {
                    label: this.translateService.instant('Undo changes'),
                    icon: 'undo',
                    isDisabled: () => !this.hasChanges(),
                    isVisible: () => !this.viewMode && this.mode !== ViewMode.create,
                    onClick: () => this.rejectChanges()
                },
                {
                    label: this.translateService.instant('Edit'),
                    icon: 'edit',
                    isDisabled: () => this.isEditDisabled(), // TODO: permissions
                    isVisible: () => this.canEdit() && this.viewMode,
                    onClick: () => this.router.navigate([`${this.parentRoute}/edit/${this.model.id}`], this.getNavigationExtras(ViewMode.edit))
                },
                {
                    label: this.translateService.instant('Cancel changes'),
                    icon: 'ban',
                    isVisible: () => this.canCancel(),
                    onClick: () => this.cancel()
                }
            ],
        };
        this.actionBar = [this.editActionBarGroup];
    }

    ngOnInit() {
        this.initialize();
    }

    async initialize() {
        if (this.mode === ViewMode.create) {
            if (!this.canCreateNew()) {
                this.router.navigate([`${this.parentRoute}/list`]);
                this.toastrService.error('User does not have create permissions');
                return;
            }
            this.model = this.createEntity();
        } else {
            await this.fetch();
        }
        this.modelLoaded();
    }

    createEntity(data = {}, entityManager = this.entityManager, entityName = this.entityName): BreezeEntity {
        const entityType = entityManager.metadataStore.getEntityType(entityName);
        _.chain(entityType.keyProperties)
            .filter(o => o.dataType === DataType.String)
            .each(o => data[o.name] = null)
            .value();

        return entityManager.createEntity(entityName, data);
    }

    fetch(): Promise<BreezeEntity> {
        const query = this.getQuery();
        this.isBusy = true;

        return this.entityManager.executeQuery(query)
            .then((response) => {
                this.isBusy = false;
                setTimeout(() => document.getElementById('content-header')?.focus(), 1500);

                if (!response.results || response.results.length === 0) {
                    this.toastrService.error(this.translateService.instant('Error fetching data from server'));

                    return undefined;
                }

                this.model = response.results[0];

                return this.model;
            })
            .catch((error) => {
                this.toastrService.error(this.translateService.instant('Error fetching data from server'));
                this.isBusy = false;

                throw error;
            });
    }

    getQuery(): EntityQuery {
        return EntityQuery.from(this.entityName)
            .withParameters({ id: this.id });
    }

    modelLoaded() {
        if (this.mode === ViewMode.edit && !this.canEdit()) {
            this.router.navigate([`${this.parentRoute}/view/${this.id}`], this.getNavigationExtras(ViewMode.view));
            this.toastrService.error('User does not have edit permissions');
            return;
        }
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
        this.consoleWindowService.close();
    }

    executeCommand({ commandName, data, tracking = true, errorPopup = false, refreshData = false, silent = true }:
        { commandName: string; data: any; tracking?: boolean; errorPopup?: boolean; refreshData?: boolean, silent?: boolean }) {
        this.isBusy = true;

        if (this.model) {
            const entities = this.breezeService.getEntites(this.model);
            _.forEach(entities, entity => this.breezeService.clearServerValidationErrors(entity.entityAspect));
            this.hideEntityErrors();
        }

        return this.breezeViewService.executeCommand(commandName, data, tracking, errorPopup, silent)
            .then(async (result: QueryResult) => {
                if (refreshData) {
                    this.entityManager.clear();
                    await this.fetch();
                }
                return result;
            })
            .finally(() => this.isBusy = false);
    }

    saveChanges(options?: any & keyof SaveOptions, entityManager = this.entityManager, entityName = this.entityName, model = this.model) {
        this.isBusy = true;
        const saveOptions = new SaveOptions({
            resourceName: `Save${entityName}`,
            tag: options?.tag || {},
            dataService: new DataService({ serviceName: `${environment.apiUrl}/command/` })
        });

        // Make sure that the root entity is always saved in order to update its version
        if (this.mode !== ViewMode.create) model.lastModifiedDate = new Date();

        const entities = this.breezeService.getEntites(model);
        _.forEach(entities, entity => this.breezeService.clearServerValidationErrors(entity.entityAspect));
        this.hideEntityErrors();

        this.beforeSave(entities);

        return entityManager.saveChanges(undefined, saveOptions).then((result) => {
            if (options?.silent !== true) this.toastrService.success(this.translateService.instant('Save successful'));
            this.afterSave(options);
            return result;
        }).catch((error) => {
            const entityErrors = _.chain(entities)
                .map((entity) => this.breezeService.getEntityErrors(entity))
                .flatten()
                .concat(error?.entityErrors || [])
                .uniqBy(e => e.errorMessage)
                .value();

            if (entityErrors.length) this.showEntityErrors(entityErrors);

            throw error;
        }).finally(() => this.isBusy = false);
    }

    beforeSave(entities: BreezeEntity[]) { }

    afterSave(options?: any) {
        if (options?.redirectToList) return this.router.navigate([`${this.parentRoute}/list`]);

        if (options?.redirectToViewMode !== false && this.mode !== environment.settings.view.save.redirectToViewMode)
            return this.router.navigate([`${this.parentRoute}/${environment.settings.view.save.redirectToViewMode}/${this.model.id}`],
                this.getNavigationExtras(environment.settings.view.save.redirectToViewMode));
    }

    canCreateNew() {
        if (this.createPermission) return this.user?.hasPermission(this.createPermission);
        return true;
    }

    hasChanges() {
        return this.entityManager.hasChanges();
    }

    rejectChanges() {
        this.entityManager.rejectChanges();
    }

    canCancel() {
        return !this.viewMode;
    }

    cancel() {
        this.cancelEdit();
    }

    cancelEdit() {
        if (this.mode === ViewMode.create) this.router.navigate([`${this.parentRoute}/list/`]);
        else this.router.navigate([`${this.parentRoute}/view/${this.model.id}`], this.getNavigationExtras(ViewMode.view));
    }

    canEdit() {
        if (this.editPermission) return this.user?.hasPermission(this.editPermission);
        return true;
    }

    isEditDisabled() {
        return false;
    }

    getIdentifier() {
        return this.model.id;
    }

    getFormattedTitle(title) {
        return title.replace(/([A-Z])/g, ' $1').trim();
    }

    getNavigationExtras(viewMode: ViewMode): NavigationExtras {
        return undefined;
    }

    getValidationOptions(): ValidationOptionsConfig {
        return { validateOnAttach: false };
    }

    protected showEntityErrors(entityErrors?: EntityError[]) {
        this.consoleWindowService.open(EntitiesErrorsComponent, [
            { provide: EntitiesErrors, useValue: new EntitiesErrors(entityErrors) },
            { provide: BreezeViewService, useValue: this.breezeViewService }
        ]);
    }

    protected hideEntityErrors() {
        this.consoleWindowService.close();
    }

    protected getEntityGraph(entity: Entity) {
        return (this.entityManager as HasEntityGraph).getEntityGraph(entity, this.getQuery().expandClause);
    }

    protected getValidationErrors(entity: Entity) {
        const graph = this.getEntityGraph(this.model);

        return _.flatMap(graph, (x: Entity) => x.entityAspect.getValidationErrors());
    }

    isValid() {
        return this.getValidationErrors(this.model).length === 0;
    }

    isActive() {
        return (this.entityName === 'Vessel' ? this.model.statusId : this.model?.vessel?.statusId) === 'AC';
    }

    isDetained() {
        return (this.entityName === 'Vessel' ? this.model.statusId : this.model?.vessel?.statusId) === 'DT';
    }

    isAlerted() {
        return (this.entityName === 'Vessel' ? this.model.statusId : this.model?.vessel?.statusId) === 'BN';
    }

    fetchResultHandler = (results: any[], labelHandler: (item: any) => string = (item) => item.name) => results.map(item => ({
        value: item.id,
        label: labelHandler(item)
    })).sort((a, b) => (a.label < b.label ? -1 : 1));

    isDarkMode() {
        return JSON.parse(localStorage.getItem('dark'));
    }

    canDetainVessel() {
        return this.viewMode && (this.isActive() || this.isDetained()) &&
            this.user?.hasPermission(VesselPermissions.Action.detainVessel);
    }

    canRealeaseVessel() {
        return this.viewMode && (this.isDetained() || this.isAlerted()) &&
            this.user?.hasPermission(VesselPermissions.Action.releaseVessel);
    }

    canAlertVessel() {
        return this.viewMode && (this.isActive() || this.isAlerted()) &&
            this.user?.hasPermission(VesselPermissions.Action.banVessel);
    }

    isOpsConnected(vesselVisit: any) {
        return vesselVisit?.energyConsumptions.some(x => !x.disconnectionTimestamp);
    }

    async executeAction(name: string, data = null) {
        this.executeCommand({
            commandName: `${name}${this.entityName}`, data: {
                id: this.model.id,
                ...data
            }, refreshData: true
        });
    }

    async executeActionWithRemarks(name: string) {
        const data = await this.dialogService.form(
            {
                title: name,
                properties: [
                    {
                        name: 'remarks',
                        type: AppControlType.TextArea,
                        label: 'Remarks',
                    }
                ]
            }, { size: 'md' });
        if (!data) return;

        await this.executeAction(name, { remarks: data.remarks });
    }
}
