import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { NavigationProperty, ValidationOptions, Validator } from '@cime/breeze-client';
import _ from 'lodash';

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

marker('Is not a valid email address.');
marker('Must be greater than or equal to \'{{comparisonValue}}\'.');
marker('Must be greater than \'{{comparisonValue}}\'.');
marker('The length must be at least {{minLength}} characters. You entered {{totalLength}} characters.');
marker('The length must be {{maxLength}} characters or fewer. You entered {{totalLength}} characters.');
marker('Must be {{maxLength}} characters in length. You entered {{totalLength}} characters.');
marker('Must be between {{minLength}} and {{maxLength}} characters. You entered {{totalLength}} characters.');
marker('Must be less than or equal to \'{{comparisonValue}}\'.');
marker('Must be less than \'{{comparisonValue}}\'.');
marker('Must not be empty.');
marker('Must not be equal to \'{{comparisonValue}}\'.');
marker('The specified condition was not met.');
marker('Is not in the correct format.');
marker('Must be equal to \'{{comparisonValue}}\'.');
marker('Must be between {{from}} and {{to}}. You entered {{value}}.');
marker('Must be between {{from}} and {{to}} (exclusive). You entered {{value}}.');
marker('Is not a valid credit card number.');
marker('Must not be more than {{expectedPrecision}} digits in total, with allowance for {{expectedScale}} decimals. {{digits}} digits and {{actualScale}} decimals were found.');
marker('Must be empty.');
marker('\'Has a range of values which does not include \'{{propertyValue}}\'.');

const requiredValidatorFunction = Validator.required(null).valFn;

export class FluentValidators {
    static registerAsBreezeValidators(translateFunction: (string) => string) {
        Validator.registerFactory(this.notNullValidator(translateFunction), 'fvNotNull');
        Validator.registerFactory(this.notEmptyValidator(translateFunction), 'fvNotEmpty');
        Validator.registerFactory(this.emailValidator(translateFunction), 'fvEmail');
        Validator.registerFactory(this.regularExpressionValidator(translateFunction), 'fvRegularExpression');
        Validator.registerFactory(this.creditCardValidator(translateFunction), 'fvCreditCard');
        Validator.registerFactory(this.lengthValidator(translateFunction), 'fvLength');
        Validator.registerFactory(this.exactLengthValidator(translateFunction), 'fvExactLength');
        Validator.registerFactory(this.exclusiveBetweenValidator(translateFunction), 'fvExclusiveBetween');
        Validator.registerFactory(this.inclusiveBetweenValidator(translateFunction), 'fvInclusiveBetween');
        Validator.registerFactory(this.equalValidator(translateFunction), 'fvEqual');
        Validator.registerFactory(this.notEqualValidator(translateFunction), 'fvNotEqual');
        Validator.registerFactory(this.lessThanValidator(translateFunction), 'fvLessThan');
        Validator.registerFactory(this.lessThanOrEqualValidator(translateFunction), 'fvLessThanOrEqual');
        Validator.registerFactory(this.greaterThanValidator(translateFunction), 'fvGreaterThan');
        Validator.registerFactory(this.greaterThanOrEqualValidator(translateFunction), 'fvGreaterThanOrEqual');
        Validator.registerFactory((options?: ValidationOptions) => this.scalePrecisionValidator(translateFunction, options), 'fvScalePrecision');
    }

    static scalePrecisionValidator(translateFunction: (string) => string, ctx?: any) {
        // 5,3  == #####,###
        const scale = parseInt(ctx.scale, 10);
        const precision = parseInt(ctx.precision, 10);
        const format = `${_.repeat('#', precision)}.${_.repeat('#', scale)}`;

        return new Validator(
            'decimal', // Validator name.
            this.decimalValidationFn,
            {
                messageTemplate: translateFunction('This value is not a valid number: {{format}}'),
                precision: ctx.precision,
                scale: ctx.scale,
                format
            });
    }

    private static decimalValidationFn(value: any, context: any): boolean {
        if (value == null) return true;

        const precision = context.precision;
        const scale = context.scale;

        const pattern = `^\\d{0,${precision}}(\\.\\d{0,${scale}})?$`;
        const regExp = new RegExp(pattern);

        return regExp.test(value);
    }

    static notNullValidatorFunction(valueOf, ctx) {
        ctx.allowEmptyStrings = true;
        return requiredValidatorFunction(valueOf, ctx);
    }

    static notNullValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => this.decorateValidator(new Validator('fvNotNull', FluentValidators.notNullValidatorFunction, context), translateFunction);
    }

    static notEmptyValidatorFunction(valueOf, ctx) {
        if (ctx.property instanceof NavigationProperty) {
            if (!ctx.entity ||
                !ctx.entity.entityAspect ||
                !ctx.entity.entityAspect.isNavigationPropertyLoaded(ctx.property)) {
                return true;
            }

            return ctx.property.isScalar ? valueOf != null : valueOf != null && valueOf.length > 0;
        }

        if (typeof valueOf === 'string') {
            return valueOf.length > 0;
        } else if (typeof valueOf === 'number') {
            return valueOf !== 0;
        } else if (Array.isArray(valueOf)) {
            // if array check only if the navigation property is loaded
            if (ctx.entity &&
                ctx.entity.entityAspect &&
                ctx.entity.entityAspect.isNavigationPropertyLoaded(ctx.property)) {
                return valueOf.length > 0;
            }
            return true;
        } else {
            return valueOf != null;
        }
    }

    static notEmptyValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => this.decorateValidator(new Validator('fvNotEmpty', FluentValidators.notEmptyValidatorFunction, context), translateFunction);
    }

    static emailValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = Validator.emailAddress(context);
            return this.decorateValidator(new Validator('fvEmail', val.valFn, context), translateFunction);
        };
    }

    static regularExpressionValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = Validator.regularExpression(context);
            return this.decorateValidator(new Validator('fvRegularExpression', val.valFn, context), translateFunction);
        };
    }

    static creditCardValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => {
            const val: any = Validator.creditCard(context);
            return this.decorateValidator(new Validator('fvCreditCard', val.valFn, context), translateFunction);
        };
    }

    static exactLengthValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.lengthValidator(translateFunction, 'fvExactLength');
    }

    static lengthValidator(translateFunction: (string) => string, valName?: string): (ctx?: any) => Validator {
        valName ||= 'fvLength';
        const stringLengthVal = (Validator as any).stringLength();
        const validator = (context?) => {
            const valFn = (valueOf, ctx) => {
                ctx.minLength = ctx.min;
                ctx.maxLength = ctx.max;
                ctx.totalLength = valueOf?.length ?? 0;
                return stringLengthVal.valFn(valueOf, ctx);
            };
            return this.decorateValidator(new Validator(valName, valFn, context), translateFunction);
        };
        return validator;
    }

    static exclusiveBetweenValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => {
            const valFn = (valueOf, ctx) => valueOf > ctx.from && valueOf < ctx.to;
            return this.decorateValidator(new Validator('fvExclusiveBetween', valFn, context), translateFunction);
        };
    }

    static inclusiveBetweenValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return (context?) => {
            const valFn = (valueOf, ctx) => valueOf >= ctx.from && valueOf <= ctx.to;
            return this.decorateValidator(new Validator('fvInclusiveBetween', valFn, context), translateFunction);
        };
    }

    static equalValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('Equal', 'fvEqual', translateFunction);
    }

    static notEqualValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('NotEqual', 'fvNotEqual', translateFunction);
    }

    static lessThanValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('LessThan', 'fvLessThan', translateFunction);
    }

    static lessThanOrEqualValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('LessThanOrEqual', 'fvLessThanOrEqual', translateFunction);
    }

    static greaterThanValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('GreaterThan', 'fvGreaterThan', translateFunction);
    }

    static greaterThanOrEqualValidator(translateFunction: (string) => string): (ctx?: any) => Validator {
        return this.comparisonValidator('GreaterThanOrEqual', 'fvGreaterThanOrEqual', translateFunction);
    }

    static decorateValidator(validator: Validator, translateFunction: (string) => string): Validator {
        validator.context = validator.context || {};
        (validator.context as any).message = (ctx) => {
            const expr = _.template(translateFunction(ctx.errorMessageId));

            return expr(ctx);
        };
        return validator;
    }

    private static comparisonValidator(comparison: string, valName: string, translateFunction: (string) => string) {
        return (context?) => {
            const valFn = (valueOf, ctx) => {
                const camelCaseConv = ctx.entity.entityAspect.entityManager.metadataStore.namingConvention.name === 'camelCase';
                const valToCompare = !!ctx.memberToCompare
                    ? ctx.entity.getProperty((camelCaseConv ? _.camelCase(ctx.memberToCompare) : ctx.memberToCompare)) // TODO: better way
                    : ctx.valueToCompare;

                ctx['comparisonValue'] = valToCompare;

                // var comparison = ctx.comparison;
                switch (comparison) {
                    case 'Equal':
                        return valueOf === valToCompare;
                    case 'NotEqual':
                        return valueOf !== valToCompare;
                    case 'LessThan':
                        return valueOf < valToCompare;
                    case 'LessThanOrEqual':
                        return valueOf <= valToCompare;
                    case 'GreaterThan':
                        return valueOf > valToCompare;
                    case 'GreaterThanOrEqual':
                        return valueOf >= valToCompare;
                    default:
                        throw new Error(`Unknown comparison: '${comparison}'`);
                }
            };
            return this.decorateValidator(new Validator(valName, valFn, context), translateFunction);
        };
    }
}
