import { Routes } from '@angular/router';
import { UnauthorizedComponent } from '@common/components/unauthorized/unauthorized.component';
import { LoginComponent } from './common/components/login/login.component';
import { AuthGuard } from './common/guards/auth.guard';
import { DocumentsComponent } from './home/documents/documents.component';
import { HomeComponent } from './home/home.component';
import { TaskManagerComponent } from './home/task-manager/task-manager.component';

export const ROUTES: Routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: { title: 'Login' }
    },
    {
        path: 'login/:token',
        component: LoginComponent,
        data: { title: 'Login' }
    },
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: '/task-manager', pathMatch: 'full' },
            { path: '401', component: UnauthorizedComponent },
            {
                path: 'administration',
                loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
                data: { title: 'Administration' }
            },
            {
                path: 'vessels',
                loadChildren: () => import('./vessel/vessel.module').then(m => m.VesselModule),
                data: { title: 'Vessel' }
            },
            {
                path: 'paris-mou',
                loadChildren: () => import('./vessel/paris-mou/paris-mou.module').then(m => m.ParisMOUModule),
                data: { title: 'Paris MOU' }
            },
            {
                path: 'detained-vessels',
                loadChildren: () => import('./vessel/detained-vessel/detained-vessel.module').then(m => m.DetainedVesselModule),
                data: { title: 'Detained Vessels' }
            },
            {
                path: 'vessel-notification',
                loadChildren: () => import('./vessel-notification/vessel-notification.module').then(m => m.VesselNotificationModule),
                data: { title: 'Vessel Notifications' }
            },
            {
                path: 'short-vessel-notification',
                loadChildren: () => import('./vessel-short-notification/vessel-short-notification.module').then(m => m.VesselShortNotificationModule),
                data: { title: 'Short Notifications' }
            },
            {
                path: 'vessel-visit',
                loadChildren: () => import('./vessel-visit/vessel-visit.module').then(m => m.VesselVisitModule),
                data: { title: 'Vessel Visits' }
            },
            {
                path: 'vessel-permit',
                loadChildren: () => import('./vessel-permit/vessel-permit.module').then(m => m.VesselPermitModule),
                data: { title: 'Vessel Permits' }
            },
            {
                path: 'vessel-schedule',
                loadChildren: () => import('./vessel-schedule/vessel-schedule.module').then(m => m.VesselScheduleModule),
                data: { title: 'Vessel Schedule' }
            },
            {
                path: 'notification',
                loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
                data: { title: 'Notifications' }
            },
            {
                path: 'chat-note',
                loadChildren: () => import('./chat-notes/chat-notes.module').then(m => m.ChatNotesModule),
                data: { title: 'Chat Notes' }
            },
            {
                path: 'conveyance',
                loadChildren: () => import('./conveyance/conveyance.module').then(m => m.ConveyanceModule),
                data: { title: 'Conveyances' }
            },
            {
                path: 'vessel-shift',
                loadChildren: () => import('./vessel-shift/vessel-shift.module').then(m => m.VesselShiftModule),
                data: { title: 'Vessel Shift' }
            },
            {
                path: 'vts',
                loadChildren: () => import('./vts/vts.module').then(m => m.VtsModule),
                data: { title: 'VTS' }
            },
            {
                path: 'messages',
                loadChildren: () => import('./messages/messages.module').then(m => m.MessagesModule),
                data: { title: 'Messages' }
            },
            {
                path: 'code-list',
                loadChildren: () => import('./code-list/code-list.module').then(m => m.CodeListModule),
                data: { title: 'Code List' }
            },
            {
                path: 'audit-logs',
                loadChildren: () => import('./audit-logs/audit-logs.module').then(m => m.AuditLogsModule),
                data: { title: 'Audit Logs' }
            },
            {
                path: 'shipping-manifest',
                loadChildren: () => import('./shipping-manifest/shipping-manifest.module').then(m => m.ShippingManifestModule),
                data: { title: 'Shipping Manifest' }
            },
            {
                path: 'help',
                loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
                data: { title: 'Help' }
            },
            { path: 'home', component: HomeComponent },
            { path: 'document', component: DocumentsComponent },
            { path: 'task-manager', component: TaskManagerComponent },
            {
                path: 'user-account',
                loadChildren: () => import('./user-account/user-account.module').then(m => m.UserAccountModule),
                data: { title: 'Account' }
            },
            { path: '**', redirectTo: '/task-manager' }
        ]
    }
];
