import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-user-notification-modal',
    templateUrl: './user-notification-modal.component.html',
    styleUrls: ['./user-notification-modal.component.scss']
})
export class UserNotificationModalComponent {
    constructor(public activeModal: NgbActiveModal ) { }

    close() {
        this.activeModal.close();
    }
}
