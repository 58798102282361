<nav class="bg-dark sidebar" [ngStyle]="{ 'width': isCollapsed ? '60px' : '300px' }">
    <ng-template ngFor let-item [ngForOf]="navigation">
        <ul class="nav nav-pills flex-column" *ngIf="item.hasPermission"
            [ngClass]="{'active': selectedItem === item || isActive(item.route), 'empty': !item.children}">
            <li>
                <a class="nav-link" [ngClass]="item.class" style="display: block;" (click)="selectItem(item)">
                    <fa-icon *ngIf="item.icon" [icon]="item.icon"></fa-icon> &nbsp;
                    <span *ngIf="!isCollapsed">{{item.title | translate}}</span>
                    <span class="float-right arrow-pointer" [hidden]="isCollapsed">
                        <fa-icon [icon]="item == selectedItem || isActive(item.route) ? 'chevron-left' : 'chevron-down'"></fa-icon>
                    </span>
                    <span *ngIf="!isCollapsed && getNumber(item)" class="float-right mr-1 mt-1 badge badge-pill badge-secondary">{{getNumber(item)}}</span>
                </a>
                <ul class="nav nav-pills flex-column"
                    [hidden]="isCollapsed || (selectedItem !== item && !isActive(item.route))">
                    <ng-template ngFor let-child [ngForOf]="item.children">
                        <li class="nav-item" *ngIf="child.hasPermission" [ngClass]="{'active': isActive(child.route)}">
                            <a class="nav-link" (click)="selectChild(child)">
                                <fa-icon *ngIf="child.icon" [icon]="child.icon"></fa-icon> {{child.title | translate}}
                                <span *ngIf="!isCollapsed && getNumber(child)" class="float-right badge child-badge-pill badge-secondary">{{getNumber(child)}}</span>
                            </a>
                        </li>
                    </ng-template>
                </ul>
            </li>
        </ul>
    </ng-template>
</nav>