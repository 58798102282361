import { Component, Input } from '@angular/core';
import { DeclarationType } from '@common/classes/declaration-types';
import { UploadActionButtonsComponent } from '@common/components/upload-action-buttons.component';
import { BreezeViewService } from '@common/services/breeze-view.service';
import _ from 'lodash';

@Component({
    selector: 'app-vessel-notification-print',
    templateUrl: './vessel-notification-print.component.html',
    styleUrls: ['./vessel-notification-print.component.scss']
})
export class VesselNotificationPrintComponent {
    @Input() model: any;
    @Input() editMode: boolean;
    @Input() isTabVisible: (index: number) => boolean;
    @Input() shortNotification = false;

    printGeneral: boolean;
    printCargo: boolean;
    printShipsStore: boolean;
    printCrewEffects: boolean;
    printCrewList: boolean;
    printPassengerList: boolean;
    printDangerousGoods: boolean;
    printHealth: boolean;
    printWaste: boolean;
    printSecurity: boolean;

    declarationNames: string[] = [];

    constructor(private breezeViewService: BreezeViewService) { }

    print() {
        this.declarationNames = this.collectDeclarations();
        _.forEach(this.declarationNames, declarationName => {
            this.breezeViewService.handleCommand('generateVesselNotificationPdf', {
                id: this.model.id,
                declarationName
            }).then(content => UploadActionButtonsComponent.preview(`${declarationName}.pdf`, content));
        });
    }

    collectDeclarations(): string[] {
        const declarations = [];

        if (this.printGeneral) { declarations.push(DeclarationType.General); }
        if (this.printCargo) { declarations.push(DeclarationType.Cargo); }
        if (this.printShipsStore) { declarations.push(DeclarationType.ShipsStore); }
        if (this.printCrewEffects) { declarations.push(DeclarationType.CrewEffects); }
        if (this.printCrewList) { declarations.push(DeclarationType.CrewList); }
        if (this.printPassengerList) { declarations.push(DeclarationType.PassengerList); }
        if (this.printDangerousGoods) { declarations.push(DeclarationType.DangerousGoods); }
        if (this.printHealth) { declarations.push(DeclarationType.Health); }
        if (this.printWaste) { declarations.push(DeclarationType.Waste); }
        if (this.printSecurity) { declarations.push(DeclarationType.Security); }

        return declarations;
    }
}
