<div class="form-group" [ngClass]="{'has-value' : hasValue && AppControlType.Boolean !== type, 'mb-0': !margin}">

    <label class="col-form-label" *ngIf="label && type!== AppControlType.Boolean && !(type=== AppControlType.CodeList && multi)" translate
        [ngClass]="{ 'label-star': !isDisabled && nullable === false, 'label-bg' : type === AppControlType.TextArea, 'disabled-bg' : isDisabled }" [for]="property">
        {{label}}
    </label>

    <ng-container *ngTemplateOutlet="prependTemplate">
    </ng-container>
    <ng-content select=".input-group-prepend"></ng-content>

    <input *ngIf="(type === AppControlType.String || type === AppControlType.Number) && isDisabled"
        (keyup.enter)="onKeyUp($event)" (focus)="focusInHandler($event)"
        (blur)="focusOutHandler($event)" kendoTextBox type="text"
        class="k-textbox form-control form-control-sm" [ngClass]="{ 'is-invalid': errors.length > 0, 'prependPadding': !!prepend }"
        [attr.readonly]="''" [(ngModel)]="value" #tooltip="ngbTooltip" placement="top" ngbTooltip=""
        tooltipClass="error-tooltip arrow-bottom" autocomplete="off" />

    <input *ngIf="type === AppControlType.String && !isDisabled" (keyup.enter)="onKeyUp($event)"
        (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)" kendoTextBox type="text"
        class="k-textbox form-control form-control-sm" [ngClass]="{
            'is-invalid': errors.length > 0,
            'is-active' : !!value,
            'prependPadding': !!prepend
        }" [(ngModel)]="value" #tooltip="ngbTooltip" placement="top" ngbTooltip="" [id]="property" [maxlength]="maxlength"
        (keypress)="onKeyPress($event)" (paste)="onKeyPress($event)" tooltipClass="error-tooltip arrow-bottom" autocomplete="off" />

    <input *ngIf="type === AppControlType.Password" (keyup.enter)="onKeyUp($event)" kendoTextBox type="password"
        class="k-textbox form-control form-control-sm" [ngClass]="{ 'is-invalid': errors.length > 0 }"
        [attr.readonly]="isDisabled ? '' : null" [(ngModel)]="value" #tooltip="ngbTooltip" placement="top" ngbTooltip=""
        tooltipClass="error-tooltip arrow-bottom" autocomplete="off" />

    <textarea #textarea (keyup.enter)="onKeyUp($event)" (focus)="focusInHandler($event)"
        *ngIf="type === AppControlType.TextArea" (blur)="focusOutHandler($event)" kendoTextArea
        class="k-textarea form-control form-control-sm" [(ngModel)]="value" [attr.readonly]="isDisabled ? '' : null"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }" #tooltip="ngbTooltip" placement="top"
        [rows]="rows" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom">
    </textarea>

    <kendo-numerictextbox *ngIf="type === AppControlType.Number && !isDisabled" (keyup.enter)="onKeyUp($event)"
        class="form-control form-control-sm" [(ngModel)]="value" (focus)="focusInHandler($event)" [maxlength]="maxlength"
        (blur)="focusOutHandler($event)" [format]="format" [min]="min || 0" [max]="max" [autoCorrect]="true"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled, 'is-read-only': isDisabled }" [decimals]="decimals || 0"
        [readonly]="isDisabled" #tooltip="ngbTooltip" placement="top" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom">
    </kendo-numerictextbox>

    <kendo-combobox *ngIf="[AppControlType.Select, AppControlType.CodeList].includes(type) && !multi" (keyup.enter)="onKeyUp($event)"
        (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)"
        class="form-control form-control-sm" (open)="onDropdownOpen()" [textField]="textField" [valueField]="valueField"
        [valuePrimitive]="true" [data]="filteredOptions" [(ngModel)]="value" [filterable]="true"
        (filterChange)="applyFilterDebounced($event)" [clearButton]="nullable !== false" [readonly]="isDisabled"
        [loading]="isBusy" [popupSettings]="popupSettings" title="{{getTooltip(value)}}" #tooltip="ngbTooltip"
        placement="top" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled, 'is-read-only': isDisabled }">
    </kendo-combobox>

    <app-checkbox *ngIf="type === AppControlType.Boolean" (keyup.enter)="onKeyUp($event)" label="{{label | translate}}"
        [property]="property" [size]="size" [(ngModel)]="value" [disabled]="isDisabled" [click]="click"></app-checkbox>

    <div *ngIf="type === AppControlType.DateTime">
        <i ngbTooltip=" {{'Clear'}}" style="position: absolute; top: 5px" [ngStyle]="{ 'right' : errors.length > 0 ? '82px' : '42px' }" placement="bottom" *ngIf="nullable !== false && dateValue && !isDisabled"
            (click)="dateValue=null" class="k-icon k-clear-value k-i-close date-time-close"></i>
        <kendo-datepicker *ngIf="!time" (keyup.enter)="onKeyUp($event)" class="form-control form-control-sm" #datePicker
            [navigation]="false" (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)"
            [readonly]="isDisabled" [ngClass]="{ 'is-invalid': errors.length > 0, 'is-read-only': isDisabled }"
            [(ngModel)]="dateValue" placeholder="" #tooltip="ngbTooltip" placement="top" ngbTooltip=""
            tooltipClass="error-tooltip arrow-bottom">
        </kendo-datepicker>
        <kendo-datetimepicker *ngIf="time" (keyup.enter)="onKeyUp($event)" #dateTimePicker
            class="form-control form-control-sm" [readonly]="isDisabled" (focus)="focusInHandler($event)"
            (blur)="focusOutHandler($event)"
            [ngClass]="{ 'is-invalid': errors.length > 0, 'is-read-only': isDisabled }" [(ngModel)]="dateValue"
            placeholder="" #tooltip="ngbTooltip" placement="top" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom">
        </kendo-datetimepicker>
    </div>

    <kendo-combobox *ngIf="type === AppControlType.YesNo" (keyup.enter)="onKeyUp($event)"
        class="form-control form-control-sm" textField="text" valueField="value" [valuePrimitive]="true"
        [data]="filteredOptions" [(ngModel)]="value" [filterable]="false" [disabled]="isDisabled"
        [clearButton]="nullable !== false" title="{{getTooltip(value)}}" #tooltip="ngbTooltip" placement="top"
        ngbTooltip="" tooltipClass="error-tooltip arrow-bottom"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }">
    </kendo-combobox>

    <kendo-fileselect *ngIf="type === AppControlType.File" #tooltip="ngbTooltip" placement="top" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom"
        [ngClass]="{ 'is-invalid': errors.length > 0, 'disabled': isDisabled }" [multiple]="multi"
        [restrictions]="fileRestrictions" [(ngModel)]="options" (clear)="clearFiles($event)" (select)="addFiles($event)">
        <kendo-upload-messages [select]="multi ? ('Select files...' | translate) : ('Select file...' | translate)">
        </kendo-upload-messages>
        <ng-template kendoUploadFileTemplate let-state="state">
            <app-upload-single-file *ngIf="!multi" (removeFile)="removeFile($event)" [file]="file" [disabled]="isDisabled">
            </app-upload-single-file>
            <app-upload-multiple-files class="k-file-multiple" *ngIf="multi" (removeFile)="removeFile($event)"
                [disabled]="isDisabled" [files]="value">
            </app-upload-multiple-files>
        </ng-template>
    </kendo-fileselect>

    <div class="position-relative" *ngIf="[AppControlType.Select, AppControlType.CodeList].includes(type) && multi">
        <label class="col-form-label" *ngIf="label" [ngClass]="{ 'label-star': !isDisabled && nullable === false }"
            [for]="property">
            {{label}}
        </label>
        <kendo-multiselect (keyup.enter)="onKeyUp($event)" (mouseenter)="isMouseenter=true"
            (mouseleave)="isMouseenter=false" (focus)="focusInHandler($event)" (blur)="focusOutHandler($event)"
            class="form-control form-control-sm" (open)="onDropdownOpen()" [textField]="textField" [valueField]="valueField"
            [valuePrimitive]="true" [data]="filteredOptions" [(ngModel)]="multiselectValue" [filterable]="true"
            (filterChange)="applyFilterDebounced($event)" [loading]="isBusy" [clearButton]="nullable !== false"
            [readonly]="isDisabled" [popupSettings]="popupSettings" #tooltip="ngbTooltip" placement="top"
            title="{{getTooltip(value)}}" ngbTooltip="" tooltipClass="error-tooltip arrow-bottom"
            [ngClass]="{ 'is-invalid': errors.length > 0, 'is-read-only': isDisabled }">
            <ng-template kendoMultiSelectItemTemplate let-dataItem>
                <div style="padding: 4px;">
                    <input type="checkbox" class="k-checkbox" [checked]="isItemSelected(dataItem.value)">
                    <label class="k-checkbox-label">{{ dataItem.label }}</label>
                </div>
            </ng-template>
        </kendo-multiselect>
        <span class="multiselect-count"
            *ngIf="!(isBusy || isMouseenter) && multiselectValue?.length > 0">({{multiselectValue.length}})</span>
    </div>

    <ng-content select=".input-group-append"></ng-content>


</div>

<ng-template #prependTemplate>
    <div class="input-group-prepend" *ngIf="prepend && hasValue">
        <span class="input-group-text">
            {{prepend}}
        </span>
    </div>
</ng-template>

